import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import NextButton from '../../../ode/component/common/NextButton';
import { handleChange, handleBlur } from '../../../../../common_function/common_function';
import QuotePrograssbar from '../questions/QuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import Autocomplete from 'react-autocomplete';
import { renderEventTitle } from './EventsData';
import HeaderQuestion from '../../../../../common/component/HeaderQuestion';
import Loader from '../../../../../loader/Loader';

export default class EventTypes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            'event_type': '',
            'event_type_name': '',
            'all_venue_types': '',
            'wedding_code': '',
            'birthday_code': '',
            'vendor_at_event_code': '',
            'other_event_code': '',
            'selectedOption': null,
            'isDisabled': true,
            'linkRedirect': false,
            'linkCurrentStep': '',
            'current_event': '',
            'reffral_Str': '',
            'value': '',
            'val': '',
            'redirect_page': '',
            'suggestions': [],
            'selcted_noha_type': '',
            'handleLoader': false,
            'redUrl': 'ode/2',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        // bind require functions.
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        var affinity_plan = JSON.parse(localStorage.getItem('partnerQuoteODE'));
        if (affinity_plan.venue_name == "24Carrots") {
            this.setState({
                all_venue_types : 
                Array(
                    "The 1912",
                    "AV Irvine ",
                    "The Colony House ",
                    "Franciscan Gardens ",
                    "Giracci Vineyards",
                    "Ole Hanson Beach Club ",
                    "Rancho Las Lomas  ",
                    "The Yost Theater",
                    "The Barn at Aliso Viejo",
                    "Casa Romantica",
                    "Catalina View Gardens",
                    "City Market Social House",
                    "City of Newport Beach",
                    "Fivepoint Ampitheater",
                    "The Loft on Pine",
                    "Los Angeles River Center",
                    "Lyon Air Museum",
                    "The Modern",
                    "Nixon Library",
                    "The Observatory",
                    "Petersen Automotive Museum",
                    "Segerstrom Shelby Event Center",
                    "South Park Center",
                )
            })
        }
        else if (affinity_plan.venue_name == "Casa-de-Lago-Events"){
            this.setState({
                all_venue_types:
                    Array(
                        "Casa Bella",
                        "Casa Bonita",
                        "Casa de Lago",
                        "Los Caballeros",
                    )
            });
        }
        else if (affinity_plan.venue_name == "Beets-Catering"){
            this.setState({
                all_venue_types:
                    Array(
                        "Casa Real",
                        "Palm Event Center",
                    )
            });
        }
        else if (affinity_plan.venue_name == "Save-our-Heritage-Organization"){
            this.setState({
                all_venue_types:
                    Array(
                        "Adobe Chapel",
                        "Hoover Barn",
                        "Marston House Museum and Gardens", 
                        "Santa Ysabel Store", 
                        "The Feed Store", 
                        "Warner-Carillo Ranch House", 
                    )
            });
        }
       
     
    }
  

    handleSubmit = (event) => {
        event.preventDefault();
        var pData = {
            "venue_details": {
                "state": "California",

                "venue_name": this.state.val,
                // "venue_address": "venue address",
                // "venue_city": "Malibu",
                "venue_state": "California",
                // "venue_zip": "00000",
                // "venue_contact_person": "Hamza",
                // "venue_contact_email": "hamza@gmail.com",
            }
        };
        pData = JSON.stringify(pData);

        localStorage.setItem('partnerDetailODE', pData);

        window.location.href = process.env.REACT_APP_BASE_URL+"onedayevent/1";

    }
    handleSelectChange = (event, val) => {
        this.setState({ val: val });
        console.log("Select Change" + val);
        this.setState({isDisabled : false});
    }
    handleSelect = (val, event) => {
    //    Update input field
    // save data into the localstorage for further pages
    this.setState({val : val});
        console.log("Select" + val);
        this.setState({ isDisabled: false });
    }

    render() {
        var fromHost = '';
        if (localStorage.getItem('isHostSet')) {
            fromHost = 1;
        }
        else {
            fromHost = 0;
        }
        var options2 = [];
        
        let index_number = 0;
        var length = this.state.all_venue_types && this.state.all_venue_types.length > 0 ? this.state.all_venue_types.length : 0;
        for (var i = 1; i <= length; i++) {
            options2.push(

                { "title": this.state.all_venue_types[index_number], "rank": i, "id": i }
            );
            

            index_number++;
        }
        const isDisabled = this.state.isDisabled;
        //   For email link redirection
        if (this.state.linkRedirect) {
            return (<Redirect to={"/quote/" + this.state.page + "/" + this.state.linkCurrentStep} />)
            // return (<Redirect to={"/quote/ode/" +2} />)
        }

        if (this.state.redirectToReferrer) {
            const next = parseInt(this.props.questionNum) + parseInt(1);
            return (<Redirect to={"/quote/" + this.state.redUrl} />)
        }

        const autoCompleteComp = [
            <Fragment key="1">
                <Autocomplete
                    value={this.state.val}
                    items={options2}
                    getItemValue={item => item.title}
                    shouldItemRender={renderEventTitle}
                    renderMenu={item => (
                        <div className="dropdown">
                            {item}
                        </div>
                    )}
                    renderItem={(item, isHighlighted) =>
                        <div className={`item ${isHighlighted ? 'selected-item' : ''}`} key={item.id} >
                            {item.title}
                        </div>
                    }
                    onChange={this.handleSelectChange.bind(this)}
                    onSelect={this.handleSelect.bind(this)}
                    inputProps={{ placeholder: 'Type your venue in here' }}

                    autoHighlight={true}
                    ref={(input) => { this.nameInput = input; }} />
            </Fragment>
        ]

        return (
            <Fragment>
                <Loader isLoading={this.state.handleLoader}></Loader>
                <div className="wrapper quote-pg">
                    <HeaderQuestion prevQuestion={1} url="/quote/vendor/" lsKey="" ></HeaderQuestion>

                    <section className="quote">
                        <QuotePrograssbar questionNum={1} ></QuotePrograssbar>
                        <div className="content">
                            {/* <h2>Hi, what type of event do you need covered ?</h2> */}
                            <h2>Hi, let’s get your quote! What venue is your event being held at? </h2>
                            <div className="inner">
                                {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-type.png" alt="" /> */}
                                <div className="main-img-container" style={{ backgroundImage: 'url(/assets/images/quote-type.png)' }}></div>
                                <form onSubmit={this.handleSubmit} >
                                    {/* For mobile view autoComplete */}
                                    <div className="autocomplete-wrapper  visible-xs" style={{ marginBottom: '25px' }}>
                                        {autoCompleteComp}
                                    </div>
                                   
                                    {/* For Desktop view autoComplete */}
                                    <div className="autocomplete-wrapper  hidden-xs">
                                        {autoCompleteComp}
                                        <span id="errorCheck" style={{ color: 'red', display: 'none' }}>
                                            The event may only contain letters and only numbers are not allowed.
                                        </span>
                                        {/* <p style={{ marginTop: '10px' }}>Please type in your venue if you do not see it in the dropdown</p> */}
                                    </div>

                                    <button className="btn-maroon">Next</button>

                                    {/* <NextButton btntext="Next" disabled={isDisabled} prevQuestion={"/gerneral/vendors/" + this.state.prevQuestion} /> */}
                                </form>
                            </div>
                            {/* <!--inner--> */}
                        </div>
                        {/* <!--content--> */}
                    </section>
                    <footer className="quote-footer"></footer>
                </div>
            </Fragment>
        );
    }
}