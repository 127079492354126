
import React, { Component, Fragment } from 'react'
export default class InvoiceQuotePrograssbar extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
          'prgrass_status': ''
        }
        
      }
    render() {

        var activeLenght = 0;
        var inActiveLenght= 0;
        var totalNumber = 1;
        var currentNumber = 1;
        var page ='';
        if(this.props.page)
         page = this.props.page;

        const localStorageData = JSON.parse(localStorage.getItem('onedayeventV2'));
        if(this.props.questionNum < 10){
            
            if(page=='ode')          
                totalNumber = 8;
            else if(page=='vendor')
                totalNumber = 7;
            else
                totalNumber = 8;

            if (localStorageData && localStorageData['eventTypeBakit'].event_type !== '91' && localStorageData['eventTypeBakit'].event_type !== '65'){
                totalNumber = parseInt(totalNumber) + parseInt(1);
            }
            currentNumber = this.props.questionNum;

        }else{
            totalNumber = 3;
            if (localStorageData && localStorageData['eventAddonBakit']) {
                if(localStorageData['eventAddonBakit'].additional_insured != '' && localStorageData['eventAddonBakit'].additional_insured != '0'){
                    totalNumber = 4;
                }
             currentNumber = this.props.questionNum -10;
            }
        }
        

       
    activeLenght = currentNumber;
    inActiveLenght = totalNumber - currentNumber;
var activeBar = [];
  for (var i = 1; i <= activeLenght; i++) {
         activeBar.push(
               <Fragment key={i}>
                    <li className="active"></li>
                </Fragment>
           );
       }

       var inActiveBar = [];
       for (var j = 1; j <= inActiveLenght; j++) {
        inActiveBar.push(
               <Fragment key={j}>
                    <li className=""></li>
                </Fragment>
           );
       }
        return (
            <Fragment>
         <div className={"progress"}>
                        <ul>
                        {activeBar}
                         {inActiveBar}
                        </ul>
                    </div>
            </Fragment>
        );
    }
} 