import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import Inputmask from "inputmask";
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

export default class ODE6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'special_activities': '',
            'bouncehouses': '',
            'image': '',
            'event_special_act_detail': '',
            'redirectToReferrer': false,
            'readOnly':false,
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum - 1;
        this.setState({ prevQuestion: prev });
        this.props.functionPrev.handleUpdatePrevious(prev);
        Inputmask().mask(document.querySelectorAll("input"));
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        if (localStorageData && localStorageData['eventSpclActBakit']) {
            this.setState({ special_activities: localStorageData['eventSpclActBakit'].special_activities });
            this.setState({ bouncehouses: localStorageData['eventSpclActBakit'].bouncehouses });
            this.setState({ event_special_act_detail: localStorageData['eventSpclActBakit'].event_special_act_detail });
            if (localStorageData && localStorageData['eventSpclActBakit'].readOnly)
            {
                this.setState({readOnly : true});
            }
        }

    }
    handleSpecialEvent = (event) => {
        this.setState({ special_activities: event });

        if(event == 'No'){
            this.setState({event_special_act_detail: ''}); 
           setTimeout(function () {
               this.handleSubmit();
           }.bind(this), 5);
      }
    }

    handleSubmit = (event) => {
        //  event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
            const forms_zip = {
                'special_activities': this.state.special_activities,
                'event_special_act_detail': this.state.event_special_act_detail,
            };
            localStorageData['eventSpclActBakit'] = forms_zip;
            localStorageData.current_step = parseInt(this.props.questionNum) + 1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const page = this.props.page;
        if (this.state.redirectToReferrer) {
            const next = this.props.questionNum + 1;
            return (<Redirect to={"/quote/"+page+"/" + next} />)
        }
        this.validator.purgeFields();
        return (
            <Fragment>
                <section className="quote event">
                <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
                    <div className="content">
                        {/* <h2>Will there be any special activities</h2> */}
                        <h2>Will your event include</h2>
                        <div className="inner">
                            {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-include.png" alt="" /> */}
                            <div className="main-img-container large" style={{backgroundImage:'url(/assets/images/quote-include.png)'}}></div>
              
                            <form onSubmit={this.handleSubmit} method="post">
                                <div className="small-width-container">
                                    <div className="form-group">
                                        {this.validator.message('special_activities', this.state.special_activities, 'required')}
                                    </div>
                                </div>
                                <p>Stunts, Pyrotechnics, Aircrafts, Animals, Camping/Overnight Stays, Car Races, Precision Driving, Mechanical Devices, Rap/Hip-Hop, Rock/Metal, Rides, Water Activities, Bounce Houses or Inflatables</p>
                                <div className="h-button">
                                    <a disabled={this.state.readOnly} className={this.state.special_activities === 'Yes' ? 'btn btn-maroon2' : 'btn btn-primary btn-border'} onClick={this.state.readOnly ? '': this.handleSpecialEvent.bind(this, 'Yes')}>Yes</a>
                                    <a disabled={this.state.readOnly} className={this.state.special_activities === 'No' ? 'btn btn-maroon2' : 'btn btn-primary btn-border'} onClick={this.state.readOnly ? '': this.handleSpecialEvent.bind(this, 'No')} >No</a>
                                    
                                </div>
                                {this.state.special_activities == 'Yes' ?
                                <div class="hidden-qa">
                                <h4>What special activities will be at the event?</h4>
                                    <div className=" ">
                                        <input type="text" className="form-control" name="event_special_act_detail" value={this.state.event_special_act_detail} onChange={this.handleChange}  placeholder="" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('event_special_activity_detail', this.state.event_special_act_detail, 'required')}
                                       </span>
                                    </div>
                                      </div>
                                  : ''}
                                {this.state.special_activities || this.state.special_activities == 'Yes' ?
                                    <NextButton btntext="Next"  prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
                                       : '' }
                            </form>

                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
} 