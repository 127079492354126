import React, { Fragment, useState } from 'react';
import HeaderQuestion from '../../../../../common/component/HeaderQuestion';
import { Redirect } from 'react-router-dom';
import Layout from '../../component/vendors/Layout';
import QuestionLayout from '../../component/questions/QuestionLayout';

import Loader from '../../../../../loader/Loader';

function QuestionContaineeGeneral(props) {

	let page = props.match.params.page;
	let number = props.match.params.num;
	let pendingQuoteId = props.match.params.genreId;
	
	const [prev, setPrev] = useState(0);

	const handleUpdatePrevious = (number) => {
		setPrev(number);
	}

	const [loader, setLoader] = useState(false);

	const handleUpdateLoader = (ajaxState) => {
		setLoader(ajaxState);
	}
	var Tstatus='';
	
	if(localStorage.getItem('thankx')){
		Tstatus = 'Yes';
	}
	component = <QuestionLayout page={page} questionNum={number} pendingQuote={pendingQuoteId} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

	let component = <Layout page={page}  questionNum={number} pendingQuote={pendingQuoteId} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	
	if (page === 'vendor') {
		component = <Layout page={page} questionNum={number} pendingQuote={pendingQuoteId} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	} 
	if (page === 'ode') {
		component = <QuestionLayout page={page} questionNum={number} pendingQuote={pendingQuoteId} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	} 

	return (
		<Fragment>
			<Loader isLoading={loader}></Loader>
			<div className="wrapper quote-pg">
				<HeaderQuestion prevQuestion={number} url={"/quote/"+page+"/"} lsKey="" functionLoader={{ handleUpdateLoader: handleUpdateLoader }} ></HeaderQuestion>
				{component}
				<footer className="quote-footer"></footer>
			</div>
		</Fragment>
	);
}
export default QuestionContaineeGeneral;
