import React, { Component} from 'react';
export default class MainContent extends Component {
    render()
    {
      var handImage = {
        backgroundImage: 'url(assets/images/article-main-image.webp)',
    };
        return(
          <div className="blog article w-1030">
          <div className="flex-box">
              <div className="left">
                  <div className="tag">Tips & tricks</div>
                  <h1>Tips about wedding event insurance</h1>
                  <div className="title">
                      <div className="writer">
                          <img src="assets/images/blog-writer.png" alt="" />
                      </div>
                      <h4>Alexander Parkison</h4>
                      <span><i></i>June 10, 2020</span>
                  </div> 
                  {/* <!--title--> */}
                  <div className="share">
                      Share this post
                      <ul>
                          <li><a href="javascript:void(0)"><img src="assets/images/blog-share-icon1.svg" alt="" /></a></li>
                          <li><a href="javascript:void(0)"><img src="assets/images/blog-share-icon2.svg" alt="" /></a></li>
                          <li><a href="javascript:void(0)"><img src="assets/images/blog-share-icon3.svg" alt="" /></a></li>
                      </ul>
                  </div>
                  <div className="clearfix"></div>

                  <div className="content">
                      <div className="main-image" style={handImage}></div>
                      <p>Weddings can run into a number of different issues. This event liability insurance covers you in case you damage the venue, or for any lawsuits you receive as a result of injuries or accidents related to alcohol usage. Weddings can run into a number of different issues. This event liability insurance covers you in case you damage the venue, or for any lawsuits you receive as a result of injuries or accidents related to alcohol usage.</p>

                      <h3>Always purchase earlier rather than later</h3>
                      <p>Weddings can run into a number of different issues. This event liability insurance covers you in case you damage the venue, or for any lawsuits you receive as a result of injuries or accidents related to alcohol usage.</p>
                      <div className="img-block icon">
                          <img src="assets/images/article-icon-big.svg" alt="" />
                          <h2>Always purchase earlier rather than later</h2>
                      </div>

                      <h3>Information about that event type</h3>
                      <p>Weddings can run into a number of different issues. This event liability insurance covers you in case you damage the venue, or for any lawsuits you receive as a result of injuries or accidents related to alcohol usage. Weddings can run into a number of different issues. This event liability insurance covers you in case you damage the venue, or for any lawsuits you receive as a result of injuries or accidents related to alcohol usage.</p>
                  </div>
              </div>
               {/* <!--left--> */}
              <div className="right">
                  <div className="block newsletter">
                      <h2>Newsletter</h2>
                      <form>
                          <div className="form-group">
                              <input type="text" className="form-control" placeholder="Email" />
                          </div>
                          <button type="button" className="btn btn-brand">Subscribe</button>
                      </form>
                  </div> 
                  {/* <!--newsletter--> */}

                  <div className="block about">
                      <h2>About</h2>
                      <p>Weddings can run into a number of different issues. This event liability insurance covers you in case you damage the venue, or for any lawsuits you receive as a result of injuries or accidents related to alcohol usage</p>
                      <a href="javascript:void(0)" className="read-more">Read More</a>
                  </div> 
                  {/* <!--about--> */}

                  <div className="block about">
                      <h2>Our Guarantee</h2>
                      <p>Weddings can run into a number of different issues. This event liability insurance covers you in case you damage the venue, or for any lawsuits you receive as a result of injuries or accidents related to alcohol usage</p>
                      <a href="javascript:void(0)" className="read-more">Read More</a>
                  </div> 
                  {/* <!--about--> */}
              </div> 
              {/* <!--right--> */}
          </div>
      </div> 
       
          );
    }
}