import React, { Component, Fragment } from "react";
import { handleChange } from '../../common_function/common_function';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.handleChange = handleChange.bind(this);
    }
    render() {
     const storaData =  JSON.parse(localStorage.getItem('allLandingNavPages'));
     
      var eventPages = [];
      let index_number = 0;
      let base = 'event/';
      let paths = window.location.pathname.split('/');
      if (paths && paths[1] && paths[1] === 'event') {
        base = '';
      }
      if(storaData){
      var length = storaData && storaData.length > 0 ? storaData.length : 0;
      for (var i = 1; i <= length; i++) {
        var page = storaData[index_number]['title'];
        page = page.replace(/\s/g, '-').toLowerCase();
        page = base + page;
        eventPages.push(
          <Fragment key={i}>
            <li><a href={page}>{storaData[index_number]['navbar_text']}</a></li>
          </Fragment>
        );
        index_number++;
      }
     }
        return (
            <Fragment>
                <footer>
            <div className="w-1030">
              <div className="left">
                <ul>
                    <li className="dropdown">
                    <a  className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Partners <span className="caret"></span></a>
                    <ul className="dropdown-menu">
                      <li><a href="/partner-signup">Insurance Agents</a></li>
                      <li><a href="/event-planner-signup/1">Event Planners</a></li>
                      <li><a href="/affliate-signup">Other</a></li>
                    </ul>
                  </li>
                  <li className="dropdown">
                        <a  className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Events <span className="caret"></span></a>
                        <ul className="events-dropdown dropdown-menu">
                         {eventPages}
                    </ul>
                  </li>
                  <li><a>About Us</a></li>
                  <li><a href="/faq">FAQ's</a></li>
                  <li><a href="/blogs">Blogs</a></li>
                  <li><a href="/privacy">Privacy</a></li>
                </ul>
                <div className="clearfix"></div>
              </div>
              <div className="right">
                <ul className="social-links">
                    <li><a href="https://www.instagram.com/onedayeventinsurance/" target="_blank"><img src="/assets/images/icon-instagram.svg" alt=""/></a></li>
                    <li><a href="https://www.facebook.com/onedayeventinsurance" target="_blank"><img src="/assets/images/icon-facebook.svg" alt=""/></a></li>
                    <li><a href="https://twitter.com/onedayeventins" target="_blank"><img src="/assets/images/icon-twitter.svg" alt=""/></a></li>
                    <li><a href="https://www.linkedin.com/company/one-day-event-insurance/" target="_blank"><img src="/assets/images/icon-linkedin.svg" alt=""/></a></li>
                    <li><a href="https://www.pinterest.com/onedayeventinsurance/" target="_blank"><img src="assets/images/icon-pinterest.svg" alt=""/></a></li>
                </ul>
                <div className="clearfix"></div>
              </div>
              <strong>© 2020 ONEDAYEVENT</strong>
              <div className="clearfix"></div>
            </div>
          </footer>
            </Fragment>
        );
    }
}
export default Footer;