import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange } from '../../../../../common_function/common_function';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import Inputmask from "inputmask";
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

function validate(no_of_people) {
  return {
    no_of_people: no_of_people.length === 0,
  };
}
export default class ODE5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'no_of_people': '',
      'focusOut': {
        'no_of_people': false
      },
      'redirectToReferrer': false,
      'displayOnly': false,
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    // this.handleBlur = handleBlur.bind(this);
  }
  componentDidMount() {
    // if coming form API then show only data (readOnly)
    if (localStorage.getItem('oneLoveVenueDetailODE')) {
      this.setState({ displayOnly: true });
    }
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    if (localStorageData && localStorageData['eventPeopleBakit']) {
      this.setState({ no_of_people: localStorageData['eventPeopleBakit'].no_of_people });
      if (localStorageData['eventPeopleBakit'].readOnly) {
        this.setState({ displayOnly: true });
      }
    }
  }
  handleValidateBlur = field => evt => {
    document.getElementById('guest-msg').innerHTML="";
    this.setState({
      focusOut: { ...this.state.focusOut, [field]: true }
    });
    if(this.state.no_of_people <= 0){
      document.getElementById('guest-msg').innerHTML="Number must be greater than 0";
    }

    this.validator.showMessageFor(field);
   
    
  };
  canBeSubmitted() {
    const errors = validate(this.state.no_of_people);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.canBeSubmitted()) {
      return;
    }
    if(this.state.no_of_people <= 0){
      document.getElementById('guest-msg').innerHTML="Number must be greater than 0";
       return;
    }
    // else if (this.state.no_of_people > 5000) {
    //   document.getElementById('guest-msg').innerHTML = "Number must be less than or equal to 5000";
    //   return;
    // }

    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
      const forms_zip = { 'no_of_people': this.state.no_of_people };
      localStorageData['eventPeopleBakit'] = forms_zip;
      localStorageData.current_step = parseInt(this.props.questionNum) + 1;
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem('onedayeventv2', localStorageData);
      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    const page = this.props.page;
    if (this.state.redirectToReferrer) {
      const next = this.props.questionNum + 1;
      return (<Redirect to={"/quote/"+page+"/" + next} />)
    }
    const errors = validate(this.state.no_of_people);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.focusOut[field];
      if(this.state.no_of_people==0){
        return shouldShow;
      }
      return hasError ? shouldShow : false;
    };
    var title = '';
    if (localStorageData && localStorageData['eventTypeBakit'].event_type_name === 'Vendor at Event') {
      title = 'This is the estimated number of people you will provide vending services for at the event';
    }
    else {
      title = 'Put the greatest number of attendees you think will attend if you are unsure so there are no holdups if a claim arises';
    }

    return (
      <Fragment>
        <section className="quote event">
        <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
          <div className="content">
            <h2>How many guests will be attending?</h2>
            <div className="inner">
              {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-guests.png" alt="" /> */}
              <div className="main-img-container large" style={{backgroundImage:'url(/assets/images/quote-guests.png)'}}></div>
                       
              <form onSubmit={this.handleSubmit} method="post">
                <div className="form-group guests">
                  <input type="text" placeholder="Guests" className={shouldMarkError("no_of_people") ? "form-control error" : " form-control"} value={this.state.no_of_people} onBlur={this.handleValidateBlur("no_of_people")} onChange={this.handleChange} autoFocus={true} name="no_of_people" inputProps={{ inputMode: 'numeric' }} disabled={this.state.displayOnly}  />
                  <span id="guest-msg" style={{ color: 'red' }}> 
                    {this.validator.message('number_of_guests', this.state.no_of_people, 'numeric|min:1|not_in:0')}
                        </span>
                </div>
                <p>{title}</p>
                {/* <p>Put the greatest number of attendees you think will attend if you are unsure so there are no holdups if a claim arises.</p> */}
                {/* <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} /> */}
                <NextButton btntext="Next" disabled={isDisabled} prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
                           
              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
} 