import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../common/component/Header';
import Footer from '../common/component/Footer';
import MainContent from './MainContent';
import { Helmet } from "react-helmet";
import axios from 'axios';
export default class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'title': '',
            'header_content': '',
            'banner_image': '',
            'content_image': '',
            'content_title': '',
            'redirect_page': '',
            'editor': '',
            'tips_heading': '',
            'tip1_text': '',
            'tip2_text': '',
            'tip3_text': '',
            'tip1_image': '',
            'tip2_image': '',
            'tip3_image': '',
            'keywords': '',
            'event_type_id': '',
            'description': '',
            'redirectReffer': false

        }
    }

    componentDidMount() {
        var base_url = process.env.REACT_APP_HOME_API_URL;
        var productName = this.props.match.params.name;
         productName = productName.replace(/-/g, '_');;
        
        var api_url = base_url + 'pages/get_page_by_title_ajax?productName=' + productName;
        axios({
            method: "post",
            url: api_url,
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    if (result.data.product == null) {
                        this.setState({ redirectReffer: true });
                    }
                    this.setState({ title: result.data.product.title });
                    this.setState({ header_content: result.data.product.header_content });
                    this.setState({ banner_image: result.data.product.banner_image });
                    this.setState({ content_image: result.data.product.content_image });
                    this.setState({ content_title: result.data.product.content_title });
                    if (result.data.product.redirect_page)
                        this.setState({ redirect_page: result.data.product.redirect_page });

                    this.setState({ editor: result.data.product.editor });
                    this.setState({ event_type_id: result.data.product.event_type });

                    this.setState({ tips_heading: result.data.product.tips_heading });
                    this.setState({ tip1_text: result.data.product.tip1_text });
                    this.setState({ tip2_text: result.data.product.tip2_text });
                    this.setState({ tip3_text: result.data.product.tip3_text });
                    this.setState({ tip1_image: result.data.product.tip1_image });
                    this.setState({ tip2_image: result.data.product.tip2_image });
                    this.setState({ tip3_image: result.data.product.tip3_image });
                    this.setState({ keywords: result.data.product.keywords });
                    this.setState({ description: result.data.product.description });

                }
            })
            .catch(error => this.setState({ error: error.message }));

    }
    render() {


        if (this.state.redirectReffer) {
            //return (<Redirect to={"/404/"} />);
        }
        var data = [];
        data['title'] = this.state.title;
        data['header_content'] = this.state.header_content;
        data['banner_image'] = this.state.banner_image;
        data['content_image'] = this.state.content_image;
        data['content_title'] = this.state.content_title;
        data['redirect_page'] = this.state.redirect_page;
        data['event_type_id'] = this.state.event_type_id;
        data['editor'] = this.state.editor;
        data['tips_heading'] = this.state.tips_heading;
        data['tip1_text'] = this.state.tip1_text;
        data['tip2_text'] = this.state.tip2_text;
        data['tip3_text'] = this.state.tip3_text;
        data['tip1_image'] = this.state.tip1_image;
        data['tip2_image'] = this.state.tip2_image;
        data['tip3_image'] = this.state.tip3_image;

        return (
            <Fragment>
                {/* for SEO */}
                <Helmet>
                    <meta name="keywords" content={this.state.keywords} />
                    <meta name="description" content={this.state.description} />
                </Helmet>
                <div className="wrapper">
                    <Header></Header>
                    <MainContent data={data}></MainContent>
                    <Footer></Footer>
                </div>
            </Fragment>
        );
    }
}