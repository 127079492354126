import React from 'react'

const InvoiceVenueSuggestions = (props) => {
  const options = props.results.map(r => (
    <li key={r.id} data-id = {r.venue_name}  onClick={props.handleSuggestion.bind(this)} >
      {r.venue_name}
    </li>
  ))
  return <ul className= "hidden" id="venu_ul">{options}</ul>
}

export default InvoiceVenueSuggestions