import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class NextButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'previoues_question_number': this.props.prevQuestion,
			'redirectToReferrer': false
		}
		this.handleBackClick = this.handleBackClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (props.prevQuestion !== state.previoues_question_number)
			return { previoues_question_number: props.prevQuestion };
		else
			return null;
	}

	handleBackClick(event) {
		event.preventDefault();
		this.setState({ redirectToReferrer: true });
	}

	handleSubmit(event) {
		event.preventDefault();
	}
	render() {
		if (this.state.redirectToReferrer && this.state.previoues_question_number) {
			return (<Redirect to={this.state.previoues_question_number} />)
		}
		return (
			<div className={this.props.otherButtons ? 'three_button_row button_center submit-wrap' : "mc4wp-form-fields button_center submit-wrap"}>
				{this.props.otherButtons ? <button className="backButton">Email Quote</button> : ''}
				{this.props.otherButtons ? <button className="bl-next-2 margin-left-6" type="button">Speak to a Representative </button> : ''}
				{/*{this.props.prevQuestion ? <button className="backButton" type="button" onClick={this.handleBackClick}>Back</button> : ''}*/}

				<button id="submit_button" className={this.props.disabled ? "btn btn-maroon disabled btn_next" : "btn btn-maroon btn_next" } disabled={this.props.disabled} type="submit" onSubmit={this.handleSubmit}>{this.props.btntext}</button>
			
			</div>
		);
	}
}
export default NextButton;