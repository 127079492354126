// Written by Usman Ghani
// Component description
// This component will decide which component should be render according to step number

import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ODE9 from '../commonComponent/Invoice9';
import ODE10 from '../commonComponent/Invoice10';
import ODE10A from '../commonComponent/Invoice10A';
import ODEPayment from '../commonComponent/InvoicePayment';
import ThankQuote from '../commonComponent/InvoiceThankQuote';
import Donate from '../commonComponent/InvoiceDonate';
import ODECertificate from '../commonComponent/InvoiceCertificate';
import Loader from '../../../../../loader/Loader';


function InvoiceQuestionLayout(props) {

	let number = '';
	let params = "";

	if(props.questionNum == "certificate")
	{
		number = props.questionNum;
		params = props.page +'/'+number;
	}
	else{
		number = parseInt(props.questionNum);
		params = props.page +'/'+number;
	}
	
	let page = props.page
	let component;

	let pendingQuoteId = props.genreId;
	const [prev, setPrev] = useState(0);
	const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2_invoice'));

	const handleUpdatePrevious = (number) => {
		setPrev(number);
	}
	const [loader, setLoader] = useState(false);
	const handleUpdateLoader = (ajaxState) => {
		setLoader(ajaxState);
	}
	var Tstatus='';
	if(localStorage.getItem('thankx')){
		Tstatus = 'Yes';
	}
	if (params == 'quote/certificate') 
	{
		component = <ODECertificate questionNum={10}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	}

	// Get payment
	if(params === 'quote/10')
	{
		component = <ODEPayment questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	}

	// get insured details
	else if (params === 'quote/11') 
	{
		component = <ODE9 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;	
	}
	// get additional insured details or venue details 
	else if (params === 'quote/12') 
	{
		if (localStorageData && localStorageData['lead_details'] && localStorageData['lead_details'].additional_insured !== '0' && localStorageData['lead_details'].additional_insured !== '' && localStorageData['lead_details'].additional_insured !== null) 
		{
			// get additional insured details
			component = <ODE10A questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
		} 
		else 
		{
			// get Venue details
			component = <ODE10 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
		}
	
	}
	// get Venue details or donate page
	else if (params === 'quote/13') 
	{
		if (localStorageData && localStorageData['lead_details'] && localStorageData['lead_details'].additional_insured !== '0' && localStorageData['lead_details'].additional_insured !== '' && localStorageData['lead_details'].additional_insured !== null )
			{
				// get Venue details
				component = <ODE10 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			} 
		else 
			{
				// get Donation
				component = <Donate questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			}
	}
	// get Donation 
	else if (params === 'quote/14') 
	{
		if (localStorageData && localStorageData['lead_details'] && localStorageData['lead_details'].additional_insured !== '0' && localStorageData['lead_details'].additional_insured !== '' && localStorageData['lead_details'].additional_insured !== null )
			{
				// get Donation
				component = <Donate questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			} 
	}
	
	return (
		<Fragment>
            <Loader isLoading={loader}></Loader>
				{component}
		</Fragment>
	);
}
export default InvoiceQuestionLayout;
