import React, { Component} from 'react';
export default class MainContent extends Component {
    render()
    {
        return(
          
            <div className="landing">        
            <section className="hero">
                <div className="container">
                    <div className="text">
                        <h1>{this.props.data['title']}</h1>
                        
                        <div dangerouslySetInnerHTML={ { __html: this.props.data['header_content']}} />
                        <a href={"/onedayevent/1?redirect_page="+this.props.data['redirect_page']+"&&eventType="+this.props.data['event_type_id']} className="btn btn-maroon">Get Quote</a>
                    </div>
                    <img src={this.props.data['banner_image']} className="animate__animated animate__fadeInRight" alt="" />
                </div>
                <div className="hero-bottom animate__animated animate__fadeInUp animate__faster"></div>
            </section> 
            {/* <!--hero--> */}

            <section className="landing-section w-1030">
                <img className="animate__animated" data-animate-attr="animate__fadeInRight" src={this.props.data['content_image']} alt="" />
                <div className="left">
                    <h2>{this.props.data['content_title']}</h2>
                    <div dangerouslySetInnerHTML={ { __html: this.props.data['editor']}} />
                </div>
                <div className="clearfix"></div>
            </section>

            <section className="why w-1030">
            <h2>{this.props.data['tips_heading']}</h2>
            <div className="flex-box">
                <div className="col">
                <div className="img-container"><img src={this.props.data['tip1_image']} alt="" /></div>
                <div className="text">
                    <h3>{this.props.data['tip1_text']}</h3>
                </div>
                </div>
                <div className="col">
                <div className="img-container"><img src={this.props.data['tip2_image']}  alt="" /></div>
                <div className="text">
                    <h3>{this.props.data['tip2_text']}</h3>
                </div>
                </div>
                <div className="col">
                    <div className="img-container"><img src={this.props.data['tip3_image']} alt="" /></div>
                <div className="text">
                    <h3>{this.props.data['tip3_text']}</h3>
                </div>
                </div>
            </div>
            <a href={"/onedayevent/1?redirect_page="+this.props.data['redirect_page']} className="btn btn-maroon">Get Quote</a>
            </section> 
            {/* <!--why--> */}
        </div> 
          );
    }
}