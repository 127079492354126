import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

export default class ODECertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_name: "",
      event_date: "",
      email: "",
      event_singleDate: "",
      event_coverage_limit: "",
      redirectToReferrer: false,
    };
  }
  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    const localStorageData = JSON.parse(localStorage.getItem("odeCertificate"));
    // <!-- Global site tag (gtag.js) - Google Ads: 1071797610 -->
    //  .........................................................
    window.scrollTo(0, 0);
    if (localStorageData) {
      this.setState({ event_name: localStorageData.event_name });
      this.setState({ email: localStorageData.email });

      if (localStorageData.EventSingleDate) {
        var event_singleDate = new Date(localStorageData.EventSingleDate);
        this.setState({ event_singleDate: this.dateFormat(event_singleDate) });
      }

      if (localStorageData.event_date[0]) {
        var mydate = new Date(localStorageData.event_date[0]);

        var month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][mydate.getMonth()];
        var date = month + " " + mydate.getDate() + ", " + mydate.getFullYear();

        this.setState({ event_date: date });
        var data = localStorageData.event_date;
      }
      if (localStorageData.dates && localStorageData.dates != "")
        data = localStorageData.dates;

      if (data && data.length > 0) {
        var twoWeeks = [];
        for (var j = 0; j < data.length; j++) {
          var mydate = new Date(data[j]);
          var month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ][mydate.getMonth()];
          var date =
            month +
            " " +
            mydate.getDate() +
            ", " +
            mydate.getFullYear() +
            ", \n";
          twoWeeks.push(date);
        }
        this.setState({ event_date: twoWeeks });
      }

      let max = localStorageData.selected_plan_full_array.max_limit;
      let min = localStorageData.selected_plan_full_array.min_limit;
      let converage = min + " / " + max;
      this.setState({ event_coverage_limit: converage });
    }
  }

  dateFormat = (mydate) => {
    var month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][mydate.getMonth()];
    var date = month + " " + mydate.getDate() + ", " + mydate.getFullYear();
    return date;
  };

  render() {
    if (this.state.redirectToReferrer) {
      const page = this.props.page;
      return <Redirect to={"/quote/" + page + "/" + 1} />;
    }
    var email = new Buffer(this.state.email).toString("base64");
    var base_url = process.env.REACT_APP_API_URL;
    var loginLink = base_url + "users/setup_new_password/" + email;
    return (
      <Fragment>
        <section className="quote certificate">
          <div className="content">
            <div className="inner">
              <img
                className="animate__animated animate__fadeInUp"
                src="/assets/images/certificate.png"
                alt=""
              />
              <h2>Congrats! </h2>
              <p>
                Your Certificate will be emailed to you & uploaded to your
                portal in the next 24 hours!
              </p>
              <div className="h-button three">
                <dl>
                  <dt>Event Name</dt>
                  <dd>{this.state.event_name}</dd>
                </dl>
                <dl>
                  <dt>Event Date</dt>
                  <dd>
                    {this.state.event_singleDate
                      ? this.state.event_singleDate + ","
                      : ""}
                    {this.state.event_date ? this.state.event_date : ""}
                  </dd>
                </dl>
                <dl>
                  <dt>Limits of Coverage</dt>
                  <dd>$ {this.state.event_coverage_limit} million</dd>
                </dl>
              </div>

              <div className="h-button">
                <a href="/blogs" className="btn btn-maroon">
                  Blog
                </a>
                <a href={`${loginLink}`} className="btn btn-maroon btn-brand">
                  Your Portal
                </a>
              </div>
            </div>
            {/* <!--inner--> */}
          </div>
          {/* <!--content--> */}
        </section>
      </Fragment>
    );
  }
}
