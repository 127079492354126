import React, { Component, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom';
import SignupHeader from '../common/component/SignupHeader';
import Loader from '../loader/Loader';
import Affliate1 from './affliate1';
import Affliate2 from './affliate2';
function Signup(props) {

    let number = props.match.params.step;
    const [prev, setPrev] = useState(0);
    const localStorageData = JSON.parse(localStorage.getItem('affliate_signup'));
    const handleUpdatePrevious = (number) => {
		setPrev(number);
	}
    const [loader, setLoader] = useState(false);
	const handleUpdateLoader = (ajaxState) =>{
		setLoader(ajaxState);
    }

    let component = <Affliate1 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }}  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
	
    if(number == 2){
        component = <Affliate2 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }}  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;

    }
    if (localStorageData && localStorageData.current_step && number > localStorageData.current_step) {
		return (<Redirect to={"/affliate-signup/" + localStorageData.current_step} />)
	     }
        return(
            <Fragment>
            <Loader isLoading={loader}></Loader>
            <div className="wrapper quote-pg">
            <SignupHeader prevQuestion={number} url="/affliate-signup/" lsKey="onedayevent" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} ></SignupHeader>
			
                {component}
                {/* <Footer></Footer> */}
                <footer className="quote-footer"></footer>
            </div>
            </Fragment>
        );
  
}
export default Signup;