import React, {Fragment, useState} from 'react';
import Header from '../common/component/Header';
import Footer from '../common/component/Footer';
import FormContent from './FormContent';
import Loader from '../loader/Loader';
import {Helmet} from "react-helmet";
export default  function Contact(props) {

    const [loader, setLoader] = useState(false);
        const handleUpdateLoader = (ajaxState) =>{
         setLoader(ajaxState);
       } 
       var content = "one day event contact";
        return(
            <Fragment>
                {/* for SEO */}
                 <Helmet>
                 <meta name="description" content={content} />
              </Helmet>
              {/* END */}
            <Loader isLoading={loader}></Loader>
            <div className="wrapper">
                <Header></Header>
                <FormContent functionLoader={{ handleUpdateLoader:handleUpdateLoader  }}></FormContent>
                <Footer></Footer>
            </div>
            </Fragment>
        );
    
}