import React, { Component} from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { handleChange, handleBlur } from '../common_function/common_function';


export default class affliate1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'first_name' : '',
            'last_name' : '',
            'company' : '',
            'company_website' : '',
            'why_do_want_partner' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum - 1;
        this.setState({ prevQuestion: prev });
        this.props.functionPrev.handleUpdatePrevious(prev);
        
        const localStorageData = JSON.parse(localStorage.getItem('affliate_signup'));
        if (localStorageData && localStorageData['1']) {
            this.setState({ first_name: localStorageData['1'].first_name });
            this.setState({ last_name: localStorageData['1'].last_name });
            this.setState({ company: localStorageData['1'].company });
            this.setState({ company_website: localStorageData['1'].company_website });
            this.setState({ why_do_want_partner: localStorageData['1'].why_do_want_partner });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('affliate_signup'));
            const forms_info = { 
                'first_name' : this.state.first_name,
                'last_name' : this.state.last_name,
                'company' : this.state.company,
                'company_website' : this.state.company_website,
                'why_do_want_partner' : this.state.why_do_want_partner
            };
          
            var url_forms_info = { 'current_step': 2, '1': forms_info };
                url_forms_info = JSON.stringify(url_forms_info);
                localStorage.setItem('affliate_signup', url_forms_info);
                this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render()
    {

       if (this.state.redirectToReferrer){
            //const next = this.props.questionNum+1;
            return (<Redirect to={"/affliate-signup/"+2} />)
        }
        return(
            <section className="quote signup planners">
            <div className="progress">
              <ul>
                <li className="active"></li>
                <li></li>
              </ul>
            </div> 
           
            <div className="content">
              <h2>Sign Up for Affiliates</h2>
              <h4>Personal</h4>
              <div className="inner">
                {/* <!-- <img src="assets/images/quote-guests.png" alt="" /> --> */}
                <form onSubmit={this.handleSubmit}>
                    <div className="small-width-container">
                      <div className="form-group">
                          <input type="text" className="form-control" name="first_name" onChange={this.handleChange} value={this.state.first_name} autoFocus={true} placeholder="First Name" />
                          <div  style={{color:'red'}}>  {this.validator.message('first_name', this.state.first_name, 'required|alpha_space')}
                     </div>
                      </div>
                      <div className="form-group">
                          <input type="text" className="form-control" name="last_name" onChange={this.handleChange} value={this.state.last_name} placeholder="Last Name" />
                          <div  style={{color:'red'}}>  {this.validator.message('last_name', this.state.last_name, 'required|alpha_space')}
                     </div>
                      </div>
                    </div> 
                    <div className="small-width-container">
                    <div className="form-group">
                        <input type="text" className="form-control" name="company" onChange={this.handleChange} value={this.state.company} placeholder="Company (if applicable)" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name="company_website" onChange={this.handleChange} value={this.state.company_website} placeholder="Company Website (if applicable)" />
                        <div  style={{color:'red'}}>  {this.validator.message('company_website', this.state.company_website, 'url')}
                     </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" name ="why_do_want_partner" onChange={this.handleChange} value={this.state.why_do_want_partner} placeholder="Why do you want to partner with ODE? " />
                    <div  style={{color:'red'}}>  {this.validator.message('why_do_want_partner', this.state.why_do_want_partner, 'required|alpha_space')}
                     </div>
                </div>
                    <button type="submit" className="btn btn-maroon">Next</button>
                </form>
                {/* <a href="signup-partners2.html" className="btn btn-maroon">Next</a> */}
              </div>
             </div> 
            {/* <!--content--> */}
          </section>
         
          );
    }
}