import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur, handleChangeRadio } from '../../../../../common_function/common_function';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import { Redirect } from 'react-router-dom';
import Inputmask from "inputmask";
import SimpleReactValidator from 'simple-react-validator';
import 'react-google-places-autocomplete/dist/assets/index.css';

export default class ODE4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'alcohol_served': '',
            'chargeTicket': '',
            'isDisabled': true,
            'is_sold_profitable': '',
            'redirectToReferrer': false,
            'isSetVenue':false
        };
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);

    }
    componentDidMount() {
        const prev = this.props.questionNum - 1;
        this.setState({ prevQuestion: prev });
        this.props.functionPrev.handleUpdatePrevious(prev);
        Inputmask().mask(document.querySelectorAll("input"));
        window.$('.pop').popover();
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        const localStorageData2 = JSON.parse(localStorage.getItem('venueDetailODE'));
        if (localStorageData && localStorageData['alchoalAteventBakit'] || localStorageData2 && localStorageData2['venue_details']) {
            if (localStorageData2 && localStorageData2['venue_details'] ) {

                this.setState({ alcohol_served: localStorageData2['venue_details'].alcohol_served });
                this.setState({ isSetVenue: true });
                this.setState({ isDisabled: false });

            } else {
                this.setState({ alcohol_served: localStorageData['alchoalAteventBakit'].alcohol_served });
                this.setState({ is_sold_profitable: localStorageData['alchoalAteventBakit'].is_sold_profitable });
                this.setState({ chargeTicket: localStorageData['alchoalAteventBakit'].is_charge_ticket });
                this.setState({ isDisabled: '' });
                
            }
            if (localStorageData['alchoalAteventBakit'].readOnly) {
                {
                    this.setState({ isSetVenue: true });
                }
            }
            
        }
    }
    handleEvent = (type ,event) => {
        this.setState({ alcohol_served: type });
        this.setState({ isDisabled: '' });

     if(type !== 'Sold'){
         this.setState({is_sold_profitable: ''});
         this.setState({ chargeTicket: '' });

        // setTimeout(function () {
        //     this.handleSubmit(event);
        // }.bind(this), 5);
     }

    }
    handleProfitEvent (type, event){
        event.preventDefault();
        this.setState({is_sold_profitable: type});
        // setTimeout(function () {
        //     this.handleSubmit(event);
        // }.bind(this), 5);
    }

    handleTicketChangeEvent(type, event) {
        event.preventDefault();
        this.setState({ chargeTicket: type });
    }
    handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
          }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
            const forms_zip = {
                'alcohol_served': this.state.alcohol_served,
                'is_sold_profitable': this.state.is_sold_profitable,
                'is_charge_ticket': this.state.chargeTicket
            };
            localStorageData['alchoalAteventBakit'] = forms_zip;
            localStorageData.current_step = parseInt(this.props.questionNum) + 1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const isDisabled = this.state.isDisabled;
        const page = this.props.page;
        let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        if (localStorageData['current_type'] == 'vendor') {
            localStorageData.current_step = parseInt(this.props.questionNum) + 1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            // removing the alcohol question for vendor
            this.setState({ redirectToReferrer: true });
        }
        if (this.state.redirectToReferrer) {
            const next = this.props.questionNum + 1;
            return (<Redirect to={"/quote/"+page+"/" + next} />)
        }
        this.validator.purgeFields();
        return (
            <Fragment>
                <section className="quote alcohol">
                <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
                    <div className="content">
                        <h2>Alcohol at the event will be</h2>
                        <div className="inner">
                            {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-alchohal.png" alt="" /> */}
                            <div className="main-img-container" style={{backgroundImage:'url(/assets/images/quote-alchohal.png)'}}></div>
                       
                            <form onSubmit={this.handleSubmit} method="post">
                                <div className="small-width-container">
                                    <div className="form-group">
                                        {this.validator.message('alcohol_served', this.state.alcohol_served, 'required')}
                                    </div>
                                </div>
                                <ul className="type" >
                                    <li className={this.state.alcohol_served === 'Sold' ? 'input-group active' : 'input-group'}>
                                        <span className="input-group-addon" id="basic-addon1"><i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="When alcohol is sold to guests at the event."></i></span>
                                        <a disabled={this.state.isSetVenue} className="btn btn-primary  btn-w-info" onClick={!this.state.isSetVenue ? this.handleEvent.bind(this, 'Sold') : this.handleEvent.bind(this, 'Served')}>Sold</a></li>
                                    <li className={this.state.alcohol_served === 'Served' ? 'input-group active' : 'input-group'}>
                                        <span className="input-group-addon" id="basic-addon1"><i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="When alcohol is provided to guests at no charge."></i></span>
                                        <a disabled={this.state.isSetVenue} className="btn btn-primary  btn-w-info" onClick={!this.state.isSetVenue ? this.handleEvent.bind(this, 'Served') : this.handleEvent.bind(this, 'Served')} >Served</a></li>
                                    <li className={this.state.alcohol_served === 'BYOB' ? 'input-group active' : 'input-group'}>
                                        <span className="input-group-addon" id="basic-addon1"><i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="When guests bring their own liquor and/or beer."></i></span>
                                        <a disabled={this.state.isSetVenue} className="btn btn-primary  btn-w-info" onClick={!this.state.isSetVenue ? this.handleEvent.bind(this, 'BYOB') : this.handleEvent.bind(this, 'Served')} >B.Y.O.B</a></li>
                                    <li className={this.state.alcohol_served === 'no_alcohol' ? 'input-group active' : 'input-group'}>
                                        <span className="input-group-addon" id="basic-addon1"><i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="When there is absolutely no alcohol at the event."></i></span>
                                        <a disabled={this.state.isSetVenue} className="btn btn-primary  btn-w-info" onClick={!this.state.isSetVenue ? this.handleEvent.bind(this, 'no_alcohol') : this.handleEvent.bind(this, 'Served')}>No Alcohol</a></li>
                                </ul>
                                {this.state.alcohol_served === 'Sold' ?
                                <Fragment>
                                <div class="hidden-qa">
                                    <h4>Will you make a profit from these sales?</h4>
                                    <div class="small-width-container two-buttons">
                                        <div class="form-group">
                                            <button type="button" className={this.state.is_sold_profitable === 'Yes' ? 'btn btn-primary active' : 'btn btn-primary '} onClick={this.handleProfitEvent.bind(this, 'Yes')}>Yes</button>
                                        </div>
                                        <div class="form-group">
                                            <button type="button" className={this.state.is_sold_profitable === 'No' ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.handleProfitEvent.bind(this, 'No')}>No</button>
                                        </div>
                                        </div>
                                        <div className="form-group" style={{ color: 'red' }}>
                                {this.validator.message('profit from these sales', this.state.is_sold_profitable, 'required')}
                              </div>
                                </div> 
                              </Fragment>
                                 : '' }
                                {this.state.alcohol_served === 'Served' || this.state.is_sold_profitable === 'No' ?
                                    <Fragment>
                                        <div style={{ marginTop: '40px' }} class="hidden-qa">
                                            <h4>Is there a ticket charge for this event?</h4>
                                            <div class="small-width-container two-buttons">
                                                <div class="form-group">
                                                    <button type="button" className={this.state.chargeTicket === 'Yes' ? 'btn btn-primary active' : 'btn btn-primary '} onClick={this.handleTicketChangeEvent.bind(this, 'Yes')} disabled={this.state.isSetVenue}>Yes</button>
                                                </div>
                                                <div class="form-group">
                                                    <button type="button" className={this.state.chargeTicket === 'No' ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.handleTicketChangeEvent.bind(this, 'No')} disabled={this.state.isSetVenue}>No</button>
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ color: 'red' }}>
                                                {this.validator.message('Ticket Charge', this.state.chargeTicket, 'required')}
                                            </div>
                                        </div>
                                    </Fragment> : ''}
                                {/* <!--hidden-qa--> */}
                                <p>If you think there might be liquor, click "served" just to be safe!</p>
                                <NextButton btntext="Next" disabled={isDisabled} prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
                            </form>
                        </div>
                    </div>
                </section>

            </Fragment>
        );
    }
} 