// written by Usman Ghani
// Component Description
// This component is replicate of ODE1O. 
// In this component we take venue details information from user who is buying using invoice.

import React, { Component, Fragment } from "react";
import NextButton from "../../../../component/NextButton";
import {
  handleChange,
  handleBlur,
  validator,
} from "../../../../../common_function/common_function";
import { Redirect } from "react-router-dom";
import QuotePrograssbar from "../progressBar/InvoiceQuotePrograssbar";
import SimpleReactValidator from "simple-react-validator";
import Inputmask from "inputmask";
import ODEVenueSuggestions from "./InvoiceVenueSuggestions";
import axios from "axios";
import $ from "jquery";
function validate(
  event_name,
  venue_name,
  venue_address,
  venue_city,
  venue_zip
) {
  return {
    event_name: event_name.length === 0,
    venue_name: venue_name.length === 0,
    venue_address: venue_address.length === 0,
    venue_city: venue_city.length === 0,
    venue_zip: venue_zip.length === 0,
  };
}
export default class ODE11 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_name: "",
      venue_name: "",
      venue_address: "",
      venue_street: "",
      venue_city: "",
      venue_state: "",
      venue_zip: "",
      venue_contact_person: "",
      venue_contact_number: "",
      venue_contact_email: "",
      event_type_name: "",
      first_name: "",
      isSetWedding : false,
      focusOut: {
        event_name: false,
        venue_name: false,
        venue_address: false,
        venue_contact_person: false,
        venue_city: false,
        venue_zip: false,
        zip: false,
      },
      image: "",
      query: "",
      results: [],
      redirectToReferrer: false,
    };
    this.validator = new SimpleReactValidator(validator());
    this.handleChange = handleChange.bind(this);
    this.handleBlur = handleBlur.bind(this);
  }
  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    const localStorageData = JSON.parse(localStorage.getItem("onedayeventv2_invoice"));
    this.props.functionLoader.handleUpdateLoader(true);
    var base_url = process.env.REACT_APP_HOME_API_URL;
    axios({
      method: "post",
      url: base_url + "get_quote/get_states_ajax",
      headers: { "content-type": "application/json" },
    })
      .then((result) => {
        if (result.data.success === true) {
          this.setState({ countries: result.data.countries });
        }
        this.props.functionLoader.handleUpdateLoader(false);
      })
      .catch((error) => this.setState({ error: error.message }));
    
      // if user come again
    if (localStorageData) {
      if(localStorageData["lead_details"].event_type == 72)
      {
        this.setState({isSetWedding:true});
      }
      if (localStorageData["eventVenueInvoice"]) {
        if(localStorageData["eventVenueInvoice"].event_name == null)
        {
          this.setState({
            event_name: '',
          });
        }
        else{
            this.setState({
              event_name: localStorageData["eventVenueInvoice"].event_name,
            });
        }
       
        this.setState({
          venue_name: localStorageData["eventVenueInvoice"].venue_name,
        });
        this.setState({
          venue_state: localStorageData["eventVenueInvoice"].venue_state,
        });
        this.setState({
          venue_address: localStorageData["eventVenueInvoice"].venue_address,
        });
        this.setState({
          venue_street: localStorageData["eventVenueInvoice"].venue_street,
        });
        this.setState({
          venue_city: localStorageData["eventVenueInvoice"].venue_city,
        });
        this.setState({
          venue_zip: localStorageData["eventVenueInvoice"].venue_zip,
        });
        this.setState({
          venue_contact_number:
            localStorageData["eventVenueInvoice"].venue_contact_number,
        });
        this.setState({
          venue_contact_person:
            localStorageData["eventVenueInvoice"].venue_contact_person,
        });
        this.setState({
          venue_contact_email:
            localStorageData["eventVenueInvoice"].venue_contact_email,
        });
      }

      if ( localStorageData && localStorageData["lead_details"] && localStorageData["lead_details"].event_name ) {

        if ( localStorageData["lead_details"].event_name != "Wedding Reception" && localStorageData["lead_details"].event_name != "Baby shower" )
            this.setState({event_name: localStorageData["lead_details"].event_name});
        }

      if ( localStorageData["eventTypeBakit"] && localStorageData["eventTypeBakit"].event_type_name )
          this.setState({ event_type_name: localStorageData["eventTypeBakit"].event_type_name,});

      if ( localStorageData["lead_details"] && localStorageData["lead_details"] && localStorageData["lead_details"].event_state )
        this.setState({ venue_state: localStorageData["lead_details"].event_state, });

      // when user come again  
      if ( localStorageData["eventVenueBakit"] && localStorageData["eventVenueBakit"].venue_state )
        this.setState({
          first_name: localStorageData["eventVenueBakit"].first_name,
        });
    }
  }

  handleValidateBlur = (field) => (evt) => {
    this.setState({
      focusOut: { ...this.state.focusOut, [field]: true },
    });
    this.validator.showMessageFor(field);
  };
  canBeSubmitted() {
    const errors = validate(
      // this.state.event_name,
      this.state.venue_name,
      this.state.venue_address,
      this.state.venue_city,
      this.state.venue_state,
      this.state.venue_zip
    );
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return !isDisabled;
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.canBeSubmitted()) {
      return;
    }

    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem("onedayeventv2_invoice"));
      var pending_quote_id = "";
      if (localStorageData["invoice_detail"].lead_id) {
        pending_quote_id = localStorageData["invoice_detail"].lead_id;
      }
      const forms_zip = {
        event_name: this.state.event_name,
        venue_name: this.state.venue_name,
        venue_contact_person: this.state.venue_contact_person,
        venue_address: this.state.venue_address,
        venue_street: this.state.venue_street,
        venue_city: this.state.venue_city,
        venue_state: this.state.venue_state,
        venue_zip: this.state.venue_zip,
        venue_contact_number: this.state.venue_contact_number,
        venue_contact_email: this.state.venue_contact_email,
        pending_quote_id: pending_quote_id,
        pending_quote_status: "completed",
      };

      localStorageData["eventVenueInvoice"] = forms_zip;
      localStorageData.current_step =
        parseInt(this.props.questionNum) + parseInt(1);
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem("onedayeventv2_invoice", localStorageData);
      this.setState({ redirectToReferrer: true });
      // optional
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  findArrayElementById = (array, id) => {
    return array.find((element) => {
      return element.id === id;
    });
  };

  handleDomToggle = (e) => {
    if (document.getElementById("venu_ul")) {
      if (
        !$(this).is(e.target) &&
        $(this).has(e.target).length === 0 &&
        $("#venu_ul").has(e.target).length === 0
      ) {
        document.getElementById("venu_ul").classList.add("hidden");
      }
    }
    // document.getElementById("venu_ul").classList.add("hidden");
  };
  handleKeyupVenue = () => {
    // var name = event.target.value;
    var name = this.state.venue_name;
    var base_url = process.env.REACT_APP_HOME_API_URL;
    axios({
      method: "post",
      url: base_url + "/events/get_venue_ajax?venue_name=" + name,
      headers: { "content-type": "application/json" },
    })
      .then((result) => {
        // if (result.statusText === "OK") {
          var Data = result.data[0];
          this.setState({ venue_address: Data.venue_address });
          this.setState({ venue_city: Data.venue_city });
          this.setState({ venue_contact_person: Data.venue_contact_name });
          this.setState({ venue_zip: Data.venue_zip });
          this.setState({ venue_contact_email: Data.venue_email });
          this.setState({ venue_contact_number: Data.venue_phone });
          setTimeout(
            function () {

              this.setState({ results: result.data });
            }.bind(this),
            5
          );
        // }
        this.props.functionLoader.handleUpdateLoader(false);
      })
      .catch((error) => this.setState({ error: error.message }));
  };

  getVenueInfo = () => {
    var name = this.state.venue_name;
    var base_url = process.env.REACT_APP_HOME_API_URL;
    axios({
      method: "post",
      url: base_url + "/events/get_venue_ajax?venue_name=" + name,
      headers: { "content-type": "application/json" },
    })
      .then((result) => {
        // if (result.statusText === "OK") {
          setTimeout(
            function () {
              this.setState({ results: result.data });
            }.bind(this),
            5
          );
        // }
      })
      .catch((error) => this.setState({ error: error.message }));
  };

  handleInputChange = () => {
    document.getElementById("venu_ul").classList.remove("hidden");
    this.setState(
      {
        venue_name: this.search.value,
      },
      () => {
        if (this.state.venue_name && this.state.venue_name.length > 1) {
          if (this.state.venue_name.length % 2 === 0) {
            this.getVenueInfo();
          }
        }
      }
    );
  };

  handleSuggestion = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ venue_name: event.target.dataset.id });
    document.getElementById("venu_ul").classList.add("hidden");
    this.handleKeyupVenue();
  };
  render() {
    var countries = [];
    let index_number = 0;
    var venue_results =
      this.state.results && this.state.results.length > 0
        ? this.state.results
        : [];
    var length =
      this.state.countries && this.state.countries.length > 0
        ? this.state.countries.length
        : 0;
    for (var i = 1; i <= length; i++) {
      countries.push(
        <Fragment key={i}>
          <option value={this.state.countries[index_number]["name"]}>
            {this.state.countries[index_number]["name"]}
          </option>
        </Fragment>
      );
      index_number++;
    }
    const page = this.props.page;
    if (this.state.redirectToReferrer) {
      const next = this.props.questionNum + 1;
      return <Redirect to={"/invoice/" + page + "/" + next} />;
    }
    document.addEventListener("click", this.handleDomToggle);
    const errors = validate(
      // this.state.event_name,
      this.state.venue_name,
      this.state.venue_address,
      this.state.venue_city,
      this.state.venue_state,
      this.state.venue_zip
    );
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    const shouldMarkError = (field) => {
      const hasError = errors[field];
      const shouldShow = this.state.focusOut[field];
      return hasError ? shouldShow : false;
    };
    return (
      <Fragment>
        <section className="quote">
          <QuotePrograssbar
            page={page}
            questionNum={this.props.questionNum}
          ></QuotePrograssbar>
          <div className="content">
            <h2>What is the event name & venue details?</h2>
            <div className="inner">
              {/*  style={{ textTransform: 'capitalize' }}  <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-guests.png" alt="" /> */}
              <form onSubmit={this.handleSubmit}>
                <div className="small-width-container">
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        shouldMarkError("event_name")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="event_name"
                      onChange={this.handleChange}
                      // onBlur={this.handleValidateBlur("event_name")}
                      value={this.state.event_name}
                      placeholder="Event Name"
                      required = {!this.state.isSetWedding}
                    />
                    <span style={{ color: "red" }}>
                      {/* {this.validator.message("event_name",this.state.event_name,"alpha_space_apostrophe_dashes:event name")} */}
                    </span>
                  </div>
                  <div className="form-group my-drop-down">
                    <input
                      type="text"
                      ref={(input) => (this.search = input)}
                      onChange={this.handleInputChange}
                      className={
                        shouldMarkError("venue_name")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="venue_name"
                      onBlur={this.handleValidateBlur("venue_name")}
                      value={this.state.venue_name}
                      autoComplete="off"
                      placeholder="Venue Name"
                    />
                    <ODEVenueSuggestions
                      results={venue_results}
                      handleSuggestion={this.handleSuggestion}
                    />
                  </div>
                </div>
                <div className="small-width-container">
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        shouldMarkError("venue_address")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="venue_address"
                      onChange={this.handleChange}
                      onBlur={this.handleValidateBlur("venue_address")}
                      value={this.state.venue_address}
                      placeholder="Street Address"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        shouldMarkError("venue_city")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="venue_city"
                      onChange={this.handleChange}
                      onBlur={this.handleValidateBlur("venue_city")}
                      value={this.state.venue_city}
                      placeholder="Venue City"
                    />
                  </div>
                </div>
                <div className="small-width-container">
                  <div className="form-group">
                    <div className="select-div">
                      <select
                        className="form-control"
                        name="venue_state"
                        onChange={this.handleChange}
                        value={this.state.venue_state}
                      >
                        <option>Select State</option>
                        {countries}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="venue_zip"
                      className={
                        shouldMarkError("venue_zip")
                          ? "form-control error"
                          : " form-control"
                      }
                      onChange={this.handleChange}
                      onBlur={this.handleValidateBlur("venue_zip")}
                      value={this.state.venue_zip}
                      placeholder="Zip"
                    />
                    <span style={{ color: "red" }}>
                      {this.validator.message(
                        "venue_zip",
                        this.state.venue_zip,
                        "numeric"
                      )}
                    </span>
                  </div>
                </div>
                <div className="small-width-container">
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        shouldMarkError("venue_contact_person")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="venue_contact_person"
                      onChange={this.handleChange}
                      onBlur={this.handleValidateBlur("venue_contact_person")}
                      value={this.state.venue_contact_person}
                      placeholder="Name of Venue Contact (optional)"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      data-inputmask="'mask': '(999) 999-9999'"
                      className={
                        shouldMarkError("venue_contact_number")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="venue_contact_number"
                      onChange={this.handleChange}
                      onBlur={this.handleValidateBlur("venue_contact_number")}
                      value={this.state.venue_contact_number}
                      placeholder="Venue’s Number (optional)"
                    />
                    <span style={{ color: "red" }}>
                      {this.validator.message(
                        "venue_contact_number",
                        this.state.venue_contact_number,
                        "phone"
                      )}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className={
                      shouldMarkError("venue_contact_email")
                        ? "form-control error"
                        : " form-control"
                    }
                    name="venue_contact_email"
                    onChange={this.handleChange}
                    onBlur={this.handleValidateBlur("venue_contact_email")}
                    value={this.state.venue_contact_email}
                    placeholder="Venue Contact’s Email (optional)"
                  />
                  <span style={{ color: "red" }}>
                    {this.validator.message(
                      "venue_contact_email",
                      this.state.venue_contact_email,
                      "email"
                    )}
                  </span>
                </div>
                <p>
                  {" "}
                  If you provide us the information of your contact at the
                  venue, we send them your Certificate directly!
                </p>

                <NextButton
                  btntext="Submit"
                  disabled={isDisabled}
                  prevQuestion={"/onedayeventv2/" + this.state.prevQuestion}
                />
              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
