import React, { Component} from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { handleChange, handleBlur } from '../common_function/common_function';
import Inputmask from "inputmask";

export default class MainContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'first_name' : '',
            'last_name' : '',
            'email' : '',
            'phone' : '',
            'company' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
      const prev = this.props.questionNum - 1;
      this.setState({ prevQuestion: prev });
      this.props.functionPrev.handleUpdatePrevious(prev);
      Inputmask().mask(document.querySelectorAll("input"));
        const localStorageData = JSON.parse(localStorage.getItem('partner_signup'));
        if (localStorageData && localStorageData['1']) {
            this.setState({ first_name: localStorageData['1'].first_name });
            this.setState({ last_name: localStorageData['1'].last_name });
            this.setState({ email: localStorageData['1'].email });
            this.setState({ phone: localStorageData['1'].phone });
            this.setState({ company: localStorageData['1'].company });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('partner_signup'));
            const forms_info = { 
                'first_name' : this.state.first_name,
                'last_name' : this.state.last_name,
                'email' : this.state.email,
                'phone' : this.state.phone,
                'company' : this.state.company,
            };

          if (localStorage.getItem('partner_signup')) {
              let localStorageData = JSON.parse(localStorage.getItem('partner_signup'));
              localStorageData[this.props.questionNum] = forms_info;
              localStorageData.current_step = parseInt(this.props.questionNum) + 1;
              localStorageData = JSON.stringify(localStorageData);
              localStorage.setItem('partner_signup', localStorageData);
          }
          else {
          
            var url_forms_info = { 'current_step': 2, '1': forms_info };
                url_forms_info = JSON.stringify(url_forms_info);
                localStorage.setItem('partner_signup', url_forms_info);
          }
          this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render()
    {

       if (this.state.redirectToReferrer){
            //const next = this.props.questionNum+1;
            return (<Redirect to={"/partner-signup/"+2} />)
        }
      
        return(
          
            <section className="quote signup">
            <div className="progress">
              <ul>
                <li className="active"></li>
                <li></li>
                <li></li>
              </ul>
            </div> 
           
            <div className="content">
              <h2>Sign Up for Partners</h2>
              <h4>Personal</h4>
              <div className="inner">
                {/* <!-- <img src="assets/images/quote-guests.png" alt="" /> --> */}
                <form onSubmit={this.handleSubmit}>
                    <div className="small-width-container">
                      <div className="form-group">
                          <input type="text" className="form-control" name="first_name" onChange={this.handleChange} value={this.state.first_name} autoFocus={true} placeholder="First Name" />
                          <div  style={{color:'red'}}>  {this.validator.message('first_name', this.state.first_name, 'required|alpha_space')}
                     </div>
                      </div>
                      <div className="form-group">
                          <input type="text" className="form-control" name="last_name" onChange={this.handleChange} value={this.state.last_name} placeholder="Last Name" />
                          <div  style={{color:'red'}}>  {this.validator.message('last_name', this.state.last_name, 'required|alpha_space')}
                     </div>
                      </div>
                    </div>
  
                    <div className="small-width-container">
                      <div className="form-group">
                          <input type="text" className="form-control" name="email" onChange={this.handleChange} value={this.state.email} placeholder="Email address" /> 
                          <div  style={{color:'red'}}>  {this.validator.message('email', this.state.email, 'required|email')}
                     </div>
                      </div>
                      <div className="form-group">
                          <input type="text" className="form-control" data-inputmask="'mask': '(999) 999-9999'" name="phone" onChange={this.handleChange} value={this.state.phone} placeholder="Phone" />
                          <div  style={{color:'red'}}>  {this.validator.message('phone', this.state.phone, 'required|phone')}
                     </div>
                      </div>
                    </div>
                    <div className="small-width-container">
                      <div className="form-group">
                        <input type="text" className="form-control" name="company" onChange={this.handleChange} value={this.state.company} placeholder="Company (if applicable)" />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-maroon">Next</button>
                </form>
                {/* <a href="signup-partners2.html" className="btn btn-maroon">Next</a> */}
              </div>
             </div> 
            {/* <!--content--> */}
          </section>
         
          );
    }
}