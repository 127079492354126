// written by Usman Ghani
// Component Description
// This component is replicate of ODE1OA. 
// In this component we take additional insured’s details from user who is buying using invoice.

import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import QuotePrograssbar from '../progressBar/InvoiceQuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import Inputmask from "inputmask";
import axios from 'axios';

export default class ODE11 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'no_of_relation' : 0,
            'relation' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        this.props.functionPrev.handleUpdatePrevious(prev);
        Inputmask().mask(document.querySelectorAll("input"));
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2_invoice'));
        this.setState({ no_of_relation : localStorageData['lead_details'].additional_insured});
        
    var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url + 'get_quote/get_states_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({ countries: result.data.countries });
                }
            })
            .catch(error => this.setState({ error: error.message }));
        
        // If user is revisting
        if (localStorageData && localStorageData['AddionalInsureInvoice']) {
            this.setState({ quote_any_else: localStorageData['AddionalInsureInvoice'].quote_any_else });
            // this.setState({no_of_relation : localStorageData['addionalInsureInvoice'].no_of_relation });
            const length = localStorageData['lead_details'].additional_insured;
            for(var i=0; i < length; i++)
            {
                const additional_insure_name = 'Additional_insure_name[' + i + ']';
                const additional_insure_relation = 'Additional_insure_relation['+ i +']';
                const additional_insure_street = 'Additional_insure_street['+ i +']';
                const additional_insure_city = 'Additional_insure_city['+ i +']';
                const additional_insure_state = 'Additional_insure_state['+ i +']';
                const additional_insure_zipcode = 'Additional_insure_zipcode['+ i +']';
                const additional_insure_email= 'Additional_insure_email['+ i +']';
      
                this.setState({ [additional_insure_name] : localStorageData['AddionalInsureInvoice'][i].name});
                this.setState({ [additional_insure_relation] : localStorageData['AddionalInsureInvoice'][i].relation});
                this.setState({ [additional_insure_street] : localStorageData['AddionalInsureInvoice'][i].street});
                this.setState({ [additional_insure_city] : localStorageData['AddionalInsureInvoice'][i].city});
                this.setState({ [additional_insure_state] : localStorageData['AddionalInsureInvoice'][i].state});
                this.setState({ [additional_insure_zipcode] : localStorageData['AddionalInsureInvoice'][i].zipcode});
                this.setState({ [additional_insure_email] : localStorageData['AddionalInsureInvoice'][i].email});

                console.log(this.state.additional_insure_name);
            }
        }
    }
    handleAddRelation = (event) => {
        this.setState({no_of_relation : ++this.state.no_of_relation});
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2_invoice'));
            localStorageData['addionalInsureInvoice'] = this.state;
            if(this.props.questionNum >= localStorageData.current_step)
            localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2_invoice', localStorageData);
            // optional
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {

        var countries = [];
        let index = 0;
        var length = this.state.countries && this.state.countries.length > 0 ? this.state.countries.length : 0;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index]['name']}>{this.state.countries[index]['name']}</option>
                </Fragment>
            );
            index++;
        }
        var indents = [];
        let index_number = 0;
        for (var i = 1; i <= this.state.no_of_relation; i++) {
            const additional_insure_name = 'Additional_insure_name[' + index_number + ']';
            const additional_insure_relation = 'Additional_insure_relation['+ index_number +']';
            const additional_insure_street = 'Additional_insure_street['+ index_number +']';
            const additional_insure_city = 'Additional_insure_city['+ index_number +']';
            const additional_insure_state = 'Additional_insure_state['+ index_number +']';
            const additional_insure_zipcode = 'Additional_insure_zipcode['+ index_number +']';
            const additional_insure_email= 'Additional_insure_email['+ index_number +']';

            indents.push(
                <Fragment key={i}>
                    <div className="additional-block">
                    <h2 className="main">Insured  {i}</h2>
                    <div className="small-width-container">
                    <div className="form-group">
                        <input type="text" className="form-control"   name={additional_insure_name} onChange={this.handleChange} value={this.state[additional_insure_name]} onBlur={this.handleBlur} placeholder="Name of Additional Insured" />
                        <div className="margin-bottom-20" style={{color: 'red'}}>
                                {this.validator.message('insure_name',this.state[additional_insure_name], 'required')}
                            </div>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name={additional_insure_relation} onChange={this.handleChange} value={this.state[additional_insure_relation]} placeholder="Relation" />
                        <div className="margin-bottom-20" style={{color: 'red'}}>
                                {this.validator.message('relation',this.state[additional_insure_relation], 'required')}
                            </div>
                    </div>
                    
                  </div>
                  <div className="small-width-container">
                  <div className="form-group">
                        <input type="text" className="form-control" name={additional_insure_street} onChange={this.handleChange} value={this.state[additional_insure_street]} placeholder="Street" />
                        <div className="margin-bottom-20" style={{color: 'red'}}>
                                {this.validator.message('street',this.state[additional_insure_street], 'required')}
                            </div>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name={additional_insure_city} onChange={this.handleChange} value={this.state[additional_insure_city]} placeholder="City" />
                        <div className="margin-bottom-20" style={{color: 'red'}}>
                                {this.validator.message('city',this.state[additional_insure_city], 'required')}
                            </div>
                    </div>
                  </div>
                  <div className="small-width-container">
                  <div className="form-group">
                  <select className="form-control" name={additional_insure_state} onChange={this.handleChange}  value={this.state[additional_insure_state]} >
                                                <option value="0">Select State</option>
                                                {countries}
                                            </select>
                        {/* <input type="text" className="form-control" name={form-control} onChange={this.handleChange} value={this.state[additional_insure_state]} placeholder="State" /> */}
                        <div className="margin-bottom-20" style={{color: 'red'}}>
                                {this.validator.message('state',this.state[additional_insure_state], 'required')}
                            </div>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name={additional_insure_zipcode} onChange={this.handleChange} value={this.state[additional_insure_zipcode]} placeholder="Zipcode" />
                        <div className="margin-bottom-20" style={{color: 'red'}}>
                                {this.validator.message('zipcode',this.state[additional_insure_zipcode], 'required')}
                            </div>
                    </div>
                  </div>
                  <div className="form-group">
                        <input type="text" className="form-control" name={additional_insure_email} onChange={this.handleChange} value={this.state[additional_insure_email]} placeholder="Additional insured’s email" />
                     </div>
                     </div>
                </Fragment>
            );
            index_number++;
        }
        const page = this.props.page;
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+ parseInt(1);
            return (<Redirect to={"/invoice/"+page+"/" + next} />)
        }
        return (
            <Fragment>
                {/* <BackButton lsKey="onedayeventv2" stepNumber={this.props.questionNum} progressArray={this.props.progressArray} /> */}
                <section className="quote">
                <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
          <div className="content">
            <h2>Please fill out your additional insured’s details </h2>
            <div className="inner">
               {/* <igitmg src="/assets/images/quote-guests.png" alt="" /> */}
               {/* <div className="main-img-container large" style={{backgroundImage:'url(/assets/images/quote-guests.png)'}}></div> */}
              
               <form onSubmit={this.handleSubmit}>
                    <Fragment>
                        {indents}
                    </Fragment>
                  <NextButton btntext="Next" prevQuestion={"/onedayeventv2/"+this.state.prevQuestion}/>
              </form>
             </div> 
          </div> 
        </section> 
            </Fragment>
        );
    }
} 