import React, { Component, Fragment } from "react";
import NextButton from "../../../../component/NextButton";
import {
  handleChange,
  handleBlur,
  validator,
} from "../../../../../common_function/common_function";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
function validate(first_name, last_name, email, phone) {
  return {
    first_name: first_name.length === 0,
    last_name: last_name.length === 0,
    email: email.length === 0,
    phone: phone.length === 0,
  };
}
export default class ThankQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      focusOut: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
      },
      redirectToReferrer: false,
    };
    this.validator = new SimpleReactValidator(validator());
    this.handleChange = handleChange.bind(this);
    this.handleBlur = handleBlur.bind(this);
  }
  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    const localStorageData = JSON.parse(localStorage.getItem("onedayeventv2"));

    // this.props.functionLoader.handleUpdateLoader(true);
    var base_url = process.env.REACT_APP_HOME_API_URL;
    axios({
      method: "post",
      url: base_url + "users/get_active_user_ajax",
      headers: { "content-type": "application/json" },
    })
      .then((result) => {
        if (result.data.success === true) {
          if (Object.keys(result.data.res).length > 0) {
            var data = result.data.res;
            this.setState({ first_name: data.first_name });
            this.setState({ last_name: data.last_name });
            this.setState({ email: data.email });
            this.setState({ phone: data.phone });

            // this.props.functionLoader.handleUpdateLoader(true);
            var url_forms_info = JSON.stringify(
              "We are working on your quote, it is saved and can be reviewed in your Customer Portal at (email  address) anytime. Thank you!"
            );
            localStorage.setItem("mq_msg", url_forms_info);
            //this.handleSubmit(event);
          }
        }
        this.props.functionLoader.handleUpdateLoader(false);
      })
      .catch((error) => this.setState({ error: error.message }));
  }
  handleValidateBlur = (field) => (evt) => {
    this.setState({
      focusOut: { ...this.state.focusOut, [field]: true },
    });
    this.validator.showMessageFor(field);
  };
  canBeSubmitted() {
    const errors = validate(
      this.state.first_name,
      this.state.last_name,
      this.state.email,
      this.state.phone
    );
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return !isDisabled;
  }
  getLocalTimeZone = () => {
    var dd = new Date();
    var ddStr = dd.toString();
    var ddArr = ddStr.split(" ");
    var tmznSTr = ddArr[5];
    tmznSTr = tmznSTr.substring(3, tmznSTr.length);
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    return (
      dateTime +
      tmznSTr +
      "---" +
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.canBeSubmitted()) {
      return;
    }

    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem("onedayeventv2"));
      this.props.functionLoader.handleUpdateLoader(true);
      var pending_quote_id;
      if (localStorage.getItem("pending_quote_id")) {
        pending_quote_id = JSON.parse(localStorage.getItem("pending_quote_id"));
      }
      const forms_zip = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone,
        pending_quote_id: JSON.stringify(pending_quote_id),
        submission_status: "mq",
        pending_quote_status: "pending",
        current_date: this.getLocalTimeZone(),
      };
      localStorageData[this.props.questionNum] = forms_zip;
      var base_url = process.env.REACT_APP_API_URL;

      axios({
        method: "post",
        url: base_url + "leads/ode_quote_ajax",
        data: { dataArray: localStorageData },
        headers: { "content-type": "application/json" },
      })
        .then((result) => {
          if (result.data.success === true) {
            //alert("Data posted successfully");
            localStorage.removeItem("onedayeventv2");
            if (localStorage.getItem("pending_quote_id")) {
              localStorage.removeItem("pending_quote_id");
            }
            var odeCertificate = JSON.stringify({
              email: this.state.email,
            });
            localStorage.setItem("odeCertificate", odeCertificate);
            this.setState({ redirectToReferrer: true });
          }
          this.props.functionLoader.handleUpdateLoader(false);
        })
        .catch((error) => this.setState({ error: error.message }));
      localStorageData[this.props.questionNum] = forms_zip;
      localStorageData.current_step = parseInt(this.props.questionNum) + 1;
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem("onedayeventv2", localStorageData);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    if (this.state.redirectToReferrer) {
      const page = this.props.page;
      return <Redirect to={"/quote/" + page + "/confirmation?MQ=true"} />;
    }

    var email = new Buffer(this.state.email).toString("base64");
    var base_url = process.env.REACT_APP_API_URL;
    var loginLink = base_url + "users/setup_new_password/" + email;

    const errors = validate(
      this.state.first_name,
      this.state.last_name,
      this.state.email,
      this.state.phone
    );
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    const shouldMarkError = (field) => {
      const hasError = errors[field];
      const shouldShow = this.state.focusOut[field];
      return hasError ? shouldShow : false;
    };
    return (
      <Fragment>
        <section className="quote thanks">
          <div className="progress progress-9">
            <ul>
              <li className="active"></li>
              <li className="active"></li>
              <li className="active"></li>
              <li className="active"></li>
              <li className="active"></li>
              <li className="active"></li>
              <li className="active"></li>
              <li className="active"></li>
              <li className="active"></li>
            </ul>
          </div>
          <div className="content">
            <div className="inner">
              <p className="main-fix">
                Thanks for requesting a quote!
                <br />
                what is your best contact information?{" "}
              </p>
              <img
                className="img-responsive animate__animated animate__fadeInUp thanks-img"
                src="/assets/images/thanks-quote-image.png"
                alt=""
              />
              <div className="form-group"></div>
              <form onSubmit={this.handleSubmit}>
                <div className="small-width-container">
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        shouldMarkError("first_name")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="first_name"
                      onChange={this.handleChange}
                      value={this.state.first_name}
                      autoFocus={true}
                      onBlur={this.handleValidateBlur("first_name")}
                      autoFocus={true}
                      placeholder="First Name"
                    />
                    <span style={{ color: "red" }}>
                      {this.validator.message(
                        "first_name",
                        this.state.first_name,
                        "required|alpha_space_apostrophe_dashes:first name"
                      )}
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        shouldMarkError("last_name")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="last_name"
                      onChange={this.handleChange}
                      value={this.state.last_name}
                      onBlur={this.handleValidateBlur("last_name")}
                      placeholder="Last Name"
                    />
                    <span style={{ color: "red" }}>
                      {this.validator.message(
                        "last_name",
                        this.state.last_name,
                        "required|alpha_space_apostrophe_dashes:last name"
                      )}
                    </span>
                  </div>
                </div>
                <div className="small-width-container">
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        shouldMarkError("email")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="email"
                      onChange={this.handleChange}
                      onBlur={this.handleValidateBlur("email")}
                      value={this.state.email}
                      placeholder="Email"
                    />
                    <span style={{ color: "red" }}>
                      {this.validator.message(
                        "email",
                        this.state.email,
                        "email"
                      )}
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      data-inputmask="'mask': '(999) 999-9999'"
                      className={
                        shouldMarkError("phone")
                          ? "form-control error"
                          : " form-control"
                      }
                      name="phone"
                      onChange={this.handleChange}
                      onBlur={this.handleValidateBlur("phone")}
                      value={this.state.phone}
                      placeholder="Phone Number"
                    />
                    <span style={{ color: "red" }}>
                      {this.validator.message(
                        "phone",
                        this.state.phone,
                        "phone"
                      )}
                    </span>
                  </div>
                </div>
                <NextButton
                  btntext="Finish"
                  disabled={isDisabled}
                  prevQuestion={"/onedayeventv2/" + this.state.prevQuestion}
                />
              </form>
              {
                /* -- Bellow Code is commented by usman on 18 Feb, 2021 ---*/
                /* -- In order to remove the blogs and Portal button from ode/8 ---*/
              }

              {/* <div className="h-button">
                <a href="/blogs" className="btn btn-maroon">
                  Blog
                </a>
                <a href={`${loginLink}`} className="btn btn-maroon btn-brand">
                  Your Portal
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
