import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom';

export default class ODECertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'qt_message': 'We will email you the quote in one business day, or call you if we require any more information. Feel free to contact us if you have any questions, or need immediate assistance.',
            'redirectToReferrer': false
        }
    }
    componentDidMount() {
        // <!-- Global site tag (gtag.js) - Google Ads: 1071797610 -->
      var is_manalQuote = new URLSearchParams(window.location.search).get("MQ");
      window.scrollTo(0, 0);
     if(is_manalQuote == 'true'){

     setTimeout(function () {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-1071797610";
        script.async = true;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-1071797610');
        gtag('event', 'conversion', {'send_to': 'AW-1071797610/j1wsCInamOoBEOqqif8D'});
        }.bind(this), 5);
        // const script = document.createElement("script");
        // script.src = "https://www.googletagmanager.com/gtag/js?id=AW-1071797610";
        // script.async = true;
        // document.body.appendChild(script);
        // window.dataLayer = window.dataLayer || [];
        // function gtag() { window.dataLayer.push(arguments); }
        // gtag('js', new Date());
        // gtag('config', 'AW-1071797610');
    }
    const localStorageMsgData = JSON.parse(localStorage.getItem('mq_msg')); 
    if(localStorageMsgData){
        this.setState({ qt_message: localStorageMsgData});
          }
        }

    render() {
        var base_url = process.env.REACT_APP_API_URL;
        var loginLink = base_url + 'users/login';
      
        return (
            <Fragment>
            
      <section className="quote certificate congrats-signup">
          <div className="content">
            <div className="inner">
              <img className="animate__animated animate__fadeInUp" src="/assets/images/congrats-signup.png" alt=""/>
              <h2>Thanks! </h2>
               <p>{this.state.qt_message}</p>
                <div className="h-button">
                    <a href="/blogs" className="btn btn-maroon">Blog</a>
                    <a href={`${loginLink}`} className="btn btn-maroon btn-brand">Your Portal</a>
                </div>
            </div> 
          </div>
           {/* <!--content--> */}
        </section> 
         </Fragment>
        );
    }
} 