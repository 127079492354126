import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange } from '../../../../../common_function/common_function';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import Inputmask from "inputmask";
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

function validate(no_of_people) {
  return {
    //no_of_people: no_of_people.length === 0,
  };
}
export default class ODEClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'any_claims': '',
      'focusOut': {
        'no_of_people': false
      },
      'redirectToReferrer': false
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    // this.handleBlur = handleBlur.bind(this);
  }
  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    if (localStorageData && localStorageData['eventClaimBakit']) {
      this.setState({ any_claims: localStorageData['eventClaimBakit'].any_claims });
    }
  }
  handleValidateBlur = field => evt => {
    document.getElementById('guest-msg').innerHTML="";
    this.setState({
      focusOut: { ...this.state.focusOut, [field]: true }
    });
    if(this.state.no_of_people <= 0){
      document.getElementById('guest-msg').innerHTML="Number must be greater than 0";
    }

    this.validator.showMessageFor(field);
   
    
  };
  canBeSubmitted() {
    const errors = validate(this.state.no_of_people);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  }
  handleSpecialEvent = (event) => {
        this.setState({ any_claims: event });

        setTimeout(function () {
            this.handleSubmit();
        }.bind(this), 5);
    }
  handleSubmit = (event) => {
    if(event)
     event.preventDefault();

    if (!this.canBeSubmitted()) {
      return;
    }
    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
      const forms_zip = { 'any_claims': this.state.any_claims };
      localStorageData['eventClaimBakit'] = forms_zip;
      localStorageData.current_step = parseInt(this.props.questionNum) + 1;
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem('onedayeventv2', localStorageData);
      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    const page = this.props.page;
    if (this.state.redirectToReferrer) {
      const next = this.props.questionNum + 1;
      return (<Redirect to={"/quote/"+page+"/" + next} />)
    }
    const errors = validate(this.state.no_of_people);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.focusOut[field];
      if(this.state.no_of_people==0){
        return shouldShow;
      }
      return hasError ? shouldShow : false;
    };

    return (
      <Fragment>
        <section className="quote event">
        <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
          <div className="content">
            <h2>Have you had any claims related to event insurance in the last 3 years?</h2>
            <div className="inner">
              {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-guests.png" alt="" /> */}
              <div className="main-img-container large" style={{backgroundImage:'url(/assets/images/ICNOS-01.png)'}}></div>
                       
              <form onSubmit={this.handleSubmit} method="post">
                                <div className="small-width-container">
                                    <div className="form-group">
                                        {this.validator.message('any_claims', this.state.any_claims, 'required')}
                                    </div>
                                </div>
                                <div className="h-button">
                                    <a className={this.state.any_claims === 'Yes' ? 'btn btn-maroon2' : 'btn btn-primary btn-border'} onClick={this.handleSpecialEvent.bind(this, 'Yes')}>Yes</a>
                                    <a className={this.state.any_claims === 'No' ? 'btn btn-maroon2' : 'btn btn-primary btn-border'} onClick={this.handleSpecialEvent.bind(this, 'No')} >No</a>
                                    
                                </div>
                                {this.state.any_claims ?
                                    <NextButton btntext="Next"  prevQuestion={"/VendorQuote/" + this.state.prevQuestion} />
                                       : '' }
                            </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
} 