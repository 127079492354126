import React, { Component} from 'react';
import { handleChange,handleBlur } from '../common_function/common_function';
import SimpleReactValidator from 'simple-react-validator';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
export default class FormContent extends Component {

    constructor(props){
        super(props);
         this.state = {
           'subject': '',
           'name' : '',
           'email' : '',
           'phonenumber':'',
           'message' : ''
         }
         this.validator = new SimpleReactValidator();
         this.handleChange = handleChange.bind(this);
         this.handleBlur = handleBlur.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this)
    
      }
      handleSubmit (event){
        event.preventDefault();
        
             if (this.validator.allValid()) {
               this.props.functionLoader.handleUpdateLoader(true);
                 var formData = new FormData(); 
                 formData.append('subject', this.state.subject);
                 formData.append('name', this.state.name);
                 formData.append('email', this.state.email);
                 formData.append('phonenumber', this.state.phonenumber);
                 formData.append('message', this.state.message);
                
                 var base_url = process.env.REACT_APP_HOME_API_URL;
     
                 axios({
                     method: "post",
                     url: base_url+'leads/ode_contactus_query_ajax',
                     data : formData,
                     headers: { "content-type": "application/json" },
                 })
                 .then(result => {
                     // if (result.data.success === true){
                         //alert(result.data);
                         document.getElementById("successTagHeadMsg").innerHTML= "Thanks!";

                        window.$("#message-popup").modal("show");
                        window.$('.save-popover').css("display", "none");
                        setTimeout(function () {
                            window.$("#message-popup").modal("hide");
                            document.getElementById("successTagHeadMsg").innerHTML= "";
                            window.location.reload();
                        }.bind(this), 3000  );
                        
                    // }
                   this.props.functionLoader.handleUpdateLoader(false);
                 })
                 .catch(error => this.setState({ error: error.message }));
       }
     
       
     }
    render()
    {
      
        return(
        <div className="contact w-1030">
        <div className="flex-box">
            <div className="col">
                <h2>Contact Us</h2>
                <form onSubmit={this.handleSubmit} >
                
                <div className="form-group">
                        <input type="text" name="email" className="form-control" value={this.state.email} onChange={this.handleChange} onBlur={this.handleBlur} placeholder="Email Address" />
                        <div className="form-group" style={{color:'red'}}>
                            {this.validator.message('email', this.state.email, 'required|email')}
                           
                        </div>
                    </div>
                    <div className="form-group">
                    <input type="text" name="name" className="form-control" value={this.state.name}   onChange={this.handleChange} onBlur={this.handleBlur} placeholder="Your Name" />
                    <div className="form-group" style={{color:'red'}}>
                            {this.validator.message('name', this.state.name, 'required|alpha_space')}
                           
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text" name="subject" className="form-control" value={this.state.subject}   onChange={this.handleChange} onBlur={this.handleBlur} placeholder="Subject" />
                        <div className="form-group" style={{color:'red'}}>
                            {this.validator.message('subject', this.state.subject, 'required')}
                           
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text" name="phonenumber" className="form-control" value={this.state.phonenumber}   onChange={this.handleChange} onBlur={this.handleBlur} placeholder="Phone Number" />
                        <div className="form-group" style={{color:'red'}}>
                            {this.validator.message('phonenumber', this.state.phonenumber, 'required|phone')}
                           
                        </div>
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="message" value={this.state.message} onChange={this.handleChange} onBlur={this.handleBlur} placeholder="Message"></textarea>
                  
                        <div className="form-group" style={{color:'red'}}>
                           {this.validator.message('message', this.state.message, 'required')}

                        </div>  
                    </div>
                    
                    <div className="form-group">
                        <button  type="submit" className="btn btn-maroon">Submit</button>
                    </div>
                </form>
            </div>
             {/* <!--col--> */}
            <div className="col">
                <div className="map">
                <img src="/assets/images/contact-main.svg" alt=""/>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13064013.691781273!2d-128.31239422769022!3d36.92725801015413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2s!4v1601573335669!5m2!1sen!2s" frameBorder="0" style={{border:'0'}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe> */}
                </div>
                <div className="contact-area">
                    <div className="card">
                        <img src="assets/images/contact-icon1.svg" alt="" />
                        {/* <p><a href="tel:+585105315390">408-217-6787  </a></p> */}
                                <p><a href="tel:+4082176787">408-217-6787  </a><br /><a>support@onedayevent.com</a></p>
                    </div>
                    <div className="card">
                        <img src="assets/images/contact-icon2.svg" alt="" />
                        <p>1494 Hamilton Ave. #103, <br/>
                        San Jose, CA 95125</p>
                    </div>
                    <div className="card">
                        <img src="assets/images/contact-icon3.svg" alt="" />
                        <p>Weekdays: 5 am-7 pm PST<br/>
                        	Weekends: 6 am-5 pm PST
                        </p>
                    </div>
                </div>
            </div>
             {/* <!--col--> */}
        </div>
    </div>
    
          );
    }
}