import React, { Component, Fragment,  useState} from 'react';
    function Loader(props) {
        let isLoading = props.isLoading;
       
        return(
            (isLoading === true ? 
        <Fragment>
             <div className="overlay">
                <div className="overlay-logo">
                    <img  src="/assets/images/logo-img.svg" alt="" />
                </div>
             </div>
            </Fragment>
            : '')
                
        );
    }

export default Loader;