import React, { Component, Fragment, useState } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur, handleChangeRadio } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import Inputmask from "inputmask";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css"


export default class ODE2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
     'redirectToVender' : false,
     'redirectToReferrer' : false
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    this.handleChangeRadio = handleChangeRadio.bind(this);
    this.handleBlur = handleBlur.bind(this);

  }
  componentDidMount() {

    
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    
    if (localStorage.getItem('reffral_Str')) {
      localStorage.removeItem('reffral_Str');
    }
    if (localStorage.getItem('mq_msg')) {
      localStorage.removeItem('mq_msg');
    }
    //For event type merge
    this.mergeEventTypeArray();
  }


  mergeEventTypeArray = () => {
    
    let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    const eventTypeArray = JSON.parse(localStorage.getItem('eventTypeArray'));
    const page = this.props.page;

    if (eventTypeArray) {
      var event_info = {
        'event_type': eventTypeArray.event_type,
        'event_type_name': eventTypeArray.event_type_name,
        'other_event_code': eventTypeArray.other_event_code,
        'selectedOption': eventTypeArray.selectedOption,
        'selcted_noha_type': eventTypeArray.selcted_noha_type,
        'referral_string': eventTypeArray.referral_string
      };
      if (eventTypeArray && !localStorageData) {
        var form_info = { 'current_step': 3, 'current_type': page, 'eventTypeBakit': event_info, };
        form_info = JSON.stringify(form_info);
        localStorage.setItem('onedayeventv2', form_info);

      } else if (eventTypeArray && localStorageData) {

        localStorageData['eventTypeBakit'] = event_info;
        localStorageData['current_type'] = page;

        if (localStorage.getItem('onedayeventv2')) {
          localStorageData = JSON.stringify(localStorageData);
          localStorage.setItem('onedayeventv2', localStorageData);
        }

      }
      localStorage.removeItem('eventTypeArray');

    } else {
      if (!localStorageData)
        this.setState({ redirectToReferrer: true });
    }

  }
  handleSubmit = (event) => {
    if (event) {

      event.preventDefault();

    }

    if (this.validator.allValid()) {
      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handleOptionClicked = (option) => {
    if(option == 'Host')
    {
      localStorage.setItem('isHostSet', true);
      window.location.href =  "/onedayevent/"+1;
    }
    else
    {
      window.location.href =  "/quote/"+'vendor'+"/" + 3;
    }
  } 
  render() {
    // Usman 22-Feb-2021 : 
    if (this.state.redirectToVender) {
      return (<Redirect to={"/quote/"+'vendor'+"/" + 3} />)
    }
    const page = this.props.page;
    if (this.state.redirectToReferrer) {
      const next = parseInt(this.props.questionNum) + parseInt(1);
      return (<Redirect to={"/quote/" + page + "/" + next} />)
    }
    var dateObject = this.state.event_date;
    this.validator.purgeFields();
   
    return (
      <Fragment>
        <section className="quote event">
          <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>

          <div className="content quote-date">
            <h2>Are you the host of the event,or a vendor at the event?</h2>
            <div className="inner">
              
              {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-event.png" alt="" /> */}
              <form onSubmit={this.handleSubmit} type="post">
              <div className="col-md-6">
                <div className="main-img-container " style={{ backgroundImage: 'url(/assets/images/Host-image.png)' }}></div>
               
              </div>
              <div className="col-md-6">
                <div className="main-img-container " style={{ backgroundImage: 'url(/assets/images/Vendor-image.png)' }}></div>
                
              </div>
                <div className="h-button">
                   <a className='btn btn-primary btn-border' onClick={this.handleOptionClicked.bind(this, 'Host')}>Host</a>
                   <a className='btn btn maroon2 btn btn-primary btn-border' onClick={this.handleOptionClicked.bind(this, 'Vendor')}>Vendor</a>
                </div>
                
                

                    
                  

                {/* <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} /> */}
              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
} 