import React,{Suspense ,lazy } from 'react';
import Home from './pages/home/Home';
// import Faq from './pages/faq/Faq';




import Article from './pages/article/Article';
import Contact from './pages/contact/Contact';
import Products from './pages/products/Products';
import PartnerSignup from './pages/partner_signup/Signup';
import VenueSignup from './pages/venue_signup/Signup';
import PlannerSignup from './pages/planner_signup/Signup';
import AffliateSignup from './pages/affliate_signup/Signup';
import TeagueIns from './pages/business/pages/ode/component/common/TeagueIns';

import InvoiceLanding from "./pages/business/pages/Invoice/component/common/InvoiceLandingComponent";

import MultiplePlanInvoice from "./pages/business/pages/MultiplePlanInvoice/component/common/InvoiceLandingComponent";



import './App.css';

import { Route, Switch, Redirect} from 'react-router-dom';
// import QuestionContainerODE from './pages/business/component/QuestionContainerODE';
import QuestionContaineeGeneral from './pages/business/pages/ode/component/common/QuestionContaineeGeneral';
import InvoiceQuestionContaineeGeneral from './pages/business/pages/Invoice/component/common/InvoiceQuestionContaineeGeneral';
import MultiplePlanInvoiceQuestionContaineeGeneral from './pages/business/pages/MultiplePlanInvoice/component/common/InvoiceQuestionContaineeGeneral';
import EventTypes from './pages/business/pages/ode/component/common/EventTypes';


// require('dotenv').config()
const Faq = lazy(()=>import('./pages/faq/Faq'));
const Blog = lazy(()=>import('./pages/blog/Blog'));
const WeddingBlog = lazy(()=>import('./pages/blog/WeddingBlog'));
const TipsBlog = lazy(()=>import('./pages/blog/TipsBlog'));
const PurchaseSpecialEvent = lazy(()=>import('./pages/blog/PurchaseSpecialEvent'));
const ArticleSpecialBlog = lazy(()=>import('./pages/blog/ArticleSpecialBlog'));
const ArticleWhereToBuyBlog = lazy(()=>import('./pages/blog/ArticleWhereToBuyBlog'));
const EventInsureCostBlog = lazy(()=>import('./pages/blog/EventInsureCostBlog'));
const ChooseRightCompanyBlog = lazy(()=>import('./pages/blog/ChooseRightCompanyBlog'));

const Affliate  = lazy(()=>import('./pages/affliate/Affliate'));
const Planner  = lazy(()=>import('./pages/planner/Planner'));
const Partner  = lazy(()=>import('./pages/partner/Partner'));
const Venue  = lazy(()=>import('./pages/venue/venue'));
const Privacy  = lazy(()=>import('./pages/privacy/Privacy'));
const CongratsSignup  = lazy(()=>import('./pages/congrats_signup/Congrats'));
const NotFound  = lazy(()=>import('./pages/Error/NotFound'));



function App() {
  return (
    <div className="App">
    <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/faq/"><Suspense fallback={<div>Loading...</div>}><Faq></Faq></Suspense></Route> 
    {/* <Route exact path="/faq/" component={Faq} />  */}
    <Route exact path="/article/" component={Article} /> 
    <Route exact path="/contact/" component={Contact} />
 
    <Route exact path="/blogs/" ><Suspense fallback={<div>Loading...</div>}><Blog></Blog></Suspense></Route>
    <Route exact path="/wedding-blog/"><Suspense fallback={<div>Loading...</div>}><WeddingBlog></WeddingBlog></Suspense></Route>
    <Route exact path="/Tips-blog/"><Suspense fallback={<div>Loading...</div>}><TipsBlog></TipsBlog></Suspense></Route>
    <Route exact path="/article-special-event-insurance/"><Suspense fallback={<div>Loading...</div>}><ArticleSpecialBlog></ArticleSpecialBlog></Suspense></Route>
    <Route exact path="/article-where-to-buy-special-insurance/" ><Suspense fallback={<div>Loading...</div>}><ArticleWhereToBuyBlog></ArticleWhereToBuyBlog></Suspense></Route>
    <Route exact path="/purchase-special-event/" ><Suspense fallback={<div>Loading...</div>}><PurchaseSpecialEvent></PurchaseSpecialEvent></Suspense></Route>
    <Route exact path="/event-insure-cost/"><Suspense fallback={<div>Loading...</div>}><EventInsureCostBlog></EventInsureCostBlog></Suspense></Route>
    <Route exact path="/choose-right-company/"><Suspense fallback={<div>Loading...</div>}><ChooseRightCompanyBlog></ChooseRightCompanyBlog></Suspense></Route>
   
    <Route exact path="/affliate/"><Suspense fallback={<div>Loading...</div>}><Affliate></Affliate></Suspense></Route>
    <Route exact path="/planner/" ><Suspense fallback={<div>Loading...</div>}><Planner></Planner></Suspense></Route>
    <Route exact path="/partner/"><Suspense fallback={<div>Loading...</div>}><Partner></Partner></Suspense></Route>
    <Route exact path="/venue/"><Suspense fallback={<div>Loading...</div>}><Venue></Venue></Suspense></Route>
    <Route exact path="/privacy/" ><Suspense fallback={<div>Loading...</div>}><Privacy></Privacy></Suspense></Route>
    <Route exact path="/congrats-signup/"><Suspense fallback={<div>Loading...</div>}><CongratsSignup></CongratsSignup></Suspense></Route>
   
    <Route exact path="/partner-signup/:step?" component={(props) => <PartnerSignup {...props} /> } />
    <Route exact path="/venue-signup/:step?" component={(props) => <VenueSignup {...props} /> } />
    <Route exact path="/event-planner-signup/:step?" component={(props) => <PlannerSignup {...props} /> } />
    <Route exact path="/affliate-signup/:step?" component={(props) => <AffliateSignup {...props} /> } />
    
    {/* //......Quotes Routes... */}
      <Route exact path="/TeagueIns" component={(props) => <TeagueIns {...props} />} />
    <Route exact path="/onedayevent/:num?/:genreId?" component={(props) => <EventTypes {...props} />} />
    <Route exact path="/quote/:page?/:num?/:genreId?" component={(props) => <QuestionContaineeGeneral {...props} />} />

    {/* 
        ......Invoice Routes...... 
        Written buy Usman Ghani 16 March 2021
    */}
    {/* This is the route where user will land first when user clicks on buy button on its email */}
    <Route exact path="/ode/invoice/:invoiceId" component={(props) => <InvoiceLanding {...props} />} />
    {/* This is the route used for moving from one page to another while using invoice. */}
    <Route exact path="/invoice/:page?/:num?/:genreId?" component={(props) => <InvoiceQuestionContaineeGeneral {...props} />} />

        {/* Handling Multiple Plans Invoice */}
        {/* THis is the route to handle multiple plan Invoice  */}
        <Route exact path="/ode/multiplePlanInvoice/:invoiceId/:planId" component={(props) => <MultiplePlanInvoice {...props} />} />
        {/* This is the route used for moving from one page to another while using invoice. */}
        <Route exact path="/multiplePlanInvoice/:page?/:num?/:genreId?" component={(props) => <MultiplePlanInvoiceQuestionContaineeGeneral {...props} />} />
    
    {/* landing pages */}
     <Route exact path="/:name?" component={(props) => <Products {...props} /> } />

     <Route exact path="/404/" component={NotFound} />
      <Redirect from='*' to='/404' />
     
    </Switch>
    
    </div>
  );
}

export default App;








// import React,{Suspense ,lazy } from 'react';
// import Home from './pages/home/Home';
// import Faq from './pages/faq/Faq';
// import Blog from './pages/blog/Blog'; 
// import WeddingBlog from './pages/blog/WeddingBlog';
// import TipsBlog from './pages/blog/TipsBlog'; 
// import PurchaseSpecialEvent from './pages/blog/PurchaseSpecialEvent'; 
// import ArticleSpecialBlog from './pages/blog/ArticleSpecialBlog';
// import ArticleWhereToBuyBlog from './pages/blog/ArticleWhereToBuyBlog';
// import EventInsureCostBlog from './pages/blog/EventInsureCostBlog';
// import ChooseRightCompanyBlog from './pages/blog/ChooseRightCompanyBlog';

// import Article from './pages/article/Article';
// import Contact from './pages/contact/Contact';
// import Products from './pages/products/Products';
// import PartnerSignup from './pages/partner_signup/Signup';
// import PlannerSignup from './pages/planner_signup/Signup';
// import AffliateSignup from './pages/affliate_signup/Signup';
// import Affliate from './pages/affliate/Affliate';
// import Planner from './pages/planner/Planner';
// import Partner from './pages/partner/Partner';
// import Privacy from './pages/privacy/Privacy';
// import NotFound from './pages/Error/NotFound';
// import InvoiceLanding from "./pages/business/pages/Invoice/component/common/InvoiceLandingComponent";

// import CongratsSignup from './pages/congrats_signup/Congrats';
// import './App.css';

// import { Route, Switch, Redirect} from 'react-router-dom';
// // import QuestionContainerODE from './pages/business/component/QuestionContainerODE';
// import QuestionContaineeGeneral from './pages/business/pages/ode/component/common/QuestionContaineeGeneral';
// import InvoiceQuestionContaineeGeneral from './pages/business/pages/Invoice/component/common/InvoiceQuestionContaineeGeneral';
// import EventTypes from './pages/business/pages/ode/component/common/EventTypes';


// // require('dotenv').config()


// function App() {
//   return (
//     <div className="App">
//     <Switch>
//     <Route exact path="/" component={Home} />
//     <Route exact path="/faq/" component={Faq} />
//     <Route exact path="/article/" component={Article} /> 
//     <Route exact path="/contact/" component={Contact} />
 
//     <Route exact path="/blogs/" component={Blog} />
//     <Route exact path="/wedding-blog/" component={WeddingBlog} />
//     <Route exact path="/Tips-blog/" component={TipsBlog} />
//     <Route exact path="/article-special-event-insurance/" component={ArticleSpecialBlog} />
//     <Route exact path="/article-where-to-buy-special-insurance/" component={ArticleWhereToBuyBlog} />
//     <Route exact path="/purchase-special-event/" component={PurchaseSpecialEvent} />
//     <Route exact path="/event-insure-cost/" component={EventInsureCostBlog} />
//     <Route exact path="/choose-right-company/" component={ChooseRightCompanyBlog} />
   
//     <Route exact path="/affliate/" component={Affliate} />
//     <Route exact path="/planner/" component={Planner} />
//     <Route exact path="/partner/" component={Partner} />
//     <Route exact path="/privacy/" component={Privacy} />
//     <Route exact path="/congrats-signup/" component={ CongratsSignup} />
   
//     <Route exact path="/partner-signup/:step?" component={(props) => <PartnerSignup {...props} /> } />
//     <Route exact path="/event-planner-signup/:step?" component={(props) => <PlannerSignup {...props} /> } />
//     <Route exact path="/affliate-signup/:step?" component={(props) => <AffliateSignup {...props} /> } />
    
//     {/* //......Quotes Routes... */}
//     <Route exact path="/onedayevent/:num?/:genreId?" component={(props) => <EventTypes {...props} />} />
//     <Route exact path="/quote/:page?/:num?/:genreId?" component={(props) => <QuestionContaineeGeneral {...props} />} />

//     {/* 
//         ......Invoice Routes...... 
//         Written buy Usman Ghani 16 March 2021
//     */}
//     {/* This is the route where user will land first when user clicks on buy button on its email */}
//     <Route exact path="/ode/invoice/:invoiceId" component={(props) => <InvoiceLanding {...props} />} />
//     {/* This is the route used for moving from one page to another while using invoice. */}
//     <Route exact path="/invoice/:page?/:num?/:genreId?" component={(props) => <InvoiceQuestionContaineeGeneral {...props} />} />

    
//     {/* landing pages */}
//      <Route exact path="/event/:name?" component={(props) => <Products {...props} /> } />

//      <Route exact path="/404/" component={NotFound} />
//       <Redirect from='*' to='/404' />
     
//     </Switch>
    
//     </div>
//   );
// }

// export default App;

