import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ProgressBar from 'react-dennis-progressbar';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
class BackButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'stepNumber': this.props.stepNumber,
			'lskey': this.props.lsKey,
			'redirectUrl': '',
			'redirectToReferrer': false
		}

	}
	handleBack = () => 
    {
        if(this.props.stepNumber == 1)
            alert('No previous question');
        else
            this.setState({redirectToReferrer : true});
    }
	handleClick = () => {
		confirmAlert({
			title: 'Are you sure ?',
			message: 'You will lose all your data on this application and the application will begin from where you started',
			buttons: [
				{
					label: 'No'
				},
				{
					label: 'Yes',
					onClick: () => {
						const localStorageData = JSON.parse(localStorage.getItem(this.state.lskey));
						localStorage.removeItem(this.state.lskey);
						if (localStorageData && localStorageData['1']) {
							this.setState({ 'redirectUrl': localStorageData['1'].startoverRedirect });
							this.setState({ 'redirectToReferrer': true });
						}
					}
				}
			]
		});
	}
	render() {
		if (this.state.redirectToReferrer)
		{
			let queston_number = this.props.stepNumber- parseInt(1);
			this.setState({redirectToReferrer : false});
			return (<Redirect to={"/onedayevent/"+queston_number} />)
		}
		// if (this.state.redirectToReferrer)
		// 	return (<Redirect to={this.state.redirectUrl} />)
		return (
			<div className="col-md-12">
				{/* <a   className="back" onClick={this.handleBack}></a> */}
				{/* <ProgressBar stepNumber={this.state.stepNumber} steps={this.props.progressArray}   lineColor={{ active: '#2cdf2e', inactive: '#FFA500'}} lineHeight={7}/> */}
				{/* <a href="javascript:void(0)" class="back-icon"><img src="assets/images/icon-back-circle.svg" alt=""/>Back</a> */}
			</div>
		);
	}
}
export default BackButton;