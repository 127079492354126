import React, { Component } from "react";
import { Fragment } from "react";
import MessageModel from '../../Error/MessageModel';
import axios from 'axios';
import Inputmask from "inputmask";
import $ from 'jquery';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'showlogo': false,
      'eventPages': '',
      'page_type': '',
      'isLoggedin': false,
      'isAcceptCookie': ''
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    Inputmask().mask(document.querySelectorAll("input"));
    var base_url = process.env.REACT_APP_HOME_API_URL;
    axios({
      method: "post",
      url: base_url + 'pages/get_event_pages_ajax',
      headers: { "content-type": "application/json" },
    })
      .then(result => {
        if (result.data.success === true) {
          this.setState({ eventPages: result.data.pages });
            
          var pData  = JSON.stringify( result.data.pages);
          localStorage.setItem('allLandingNavPages', pData);
        }
        this.props.functionLoader.handleUpdateLoader(false);
      })
      .catch(error => this.setState({ error: error.message }));
    if (localStorage.getItem('isAcceptCookie')) {
      this.setState({ isAcceptCookie: localStorage.getItem('isAcceptCookie') });
    }

    var affinity_partner = new URLSearchParams(window.location.search).get("affinity_partner");

    if (affinity_partner) {
      localStorage.setItem('reffral_Str', affinity_partner);
    }

    var venue_partner = new URLSearchParams(window.location.search).get("venue_partner");

    if (venue_partner == 'SJztFMd2QVMpduSMK2') {
      // localStorage.setItem('venue_Str', venue_partner);
      // console.log('Inside venue_partner');
      var pData = {
        "venue_details" : {
          "state" : "California",
          "alcohol_served" : "Served",
          "waiver_sub_status" : '1',
          "additional_insured" : '2',
          
          "venue_name" : "Deer Creek Ridge LLC",
          "venue_address" : "9785 Deer Creek Rd",
          "venue_city" : "Malibu",
          "venue_state" : "California",
          "venue_zip" : "90265",
          "venue_contact_person" : "Rick Fields",
          "venue_contact_email" : "Deercreekridge@gmail.com",

          "additional_insure_name[0]": "Richard Fields",
          "additional_insure_relation[0]": "",
          "additional_insure_street[0]": "9785 Deer Creek Rd",
          "additional_insure_city[0]": "Malibu",
          "additional_insure_state[0]": "California",
          "additional_insure_zipcode[0]": "90265",
          "additional_insure_email[0]": "",
          "additional_insure_name[1]": "Keith Campbell",
          "additional_insure_relation[1]": "",
          "additional_insure_street[1]": "9785 Deer Creek Rd",
          "additional_insure_city[1]": "Malibu",
          "additional_insure_zipcode[1]": "90265",
          "additional_insure_email[1]": "",
          "additional_insure_state[1]": "California"
        }
      };
      pData  = JSON.stringify(pData);

      localStorage.setItem('venueDetailODE', pData);
    }

    var isLoggedin = new URLSearchParams(window.location.search).get("is_loggedin");

    if (isLoggedin) {
      localStorage.setItem('isLoggedin', isLoggedin);
      this.setState({ isLoggedin: isLoggedin });
    }

    if (localStorage.getItem('isLoggedin')) {
      this.setState({ isLoggedin: localStorage.getItem('isLoggedin') });
    }

  }

  handleCookie = (type, event) => {
    this.setState({ isAcceptCookie: type });
    localStorage.setItem('isAcceptCookie', type);
  }
handleDomToggle = (e) => {
        $(".modal iframe").attr("src", $(".modal iframe").attr("src"));
      } 
     
     
  render() {
    var base_url = process.env.REACT_APP_API_URL;
    var loginLink = base_url + 'users/login';
     // get event dynamic pages
    var eventPages = [];
    let index_number = 0;
    let base = '';
    let paths = window.location.pathname.split('/');
    if (paths && paths[1] && paths[1] === 'event') {
      base = '';
    }
    var length = this.state.eventPages && this.state.eventPages.length > 0 ? this.state.eventPages.length : 0;
    for (var i = 1; i <= length; i++) {
      var page = this.state.eventPages[index_number]['title'];
      page = page.replace(/\s/g, '-').toLowerCase();
      page = base + page;
      eventPages.push(
        <Fragment key={i}>
          <li><a href={page}>{this.state.eventPages[index_number]['navbar_text']}</a></li>
        </Fragment>
      );
      index_number++;
    }
    document.addEventListener('click', this.handleDomToggle);
    return (
      <Fragment>
        {!this.state.isAcceptCookie ?
          <div className="cache-msg">
            <p>One Day Event uses cookies to improve your experience on our site. Cookies enable you to enjoy our sites features like social sharing and saving quotes. You can ﬁnd out more about our cookie and privacy policy on our Privacy page. By continuing to use our site, you consent to use our cookies.</p>
            <a className="cross" onClick={this.handleCookie.bind(this, 'No')}><img src="assets/images/cache-cross.png" alt="" /></a>
            <a className="btn btn-maroon" onClick={this.handleCookie.bind(this, 'Yes')}>I accept</a>
          </div>
          : ''}
          <MessageModel></MessageModel>
        <header>
          <div className="container-fluid header-container">
            <nav className="navbar navbar-default">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="false">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href="/">
                  <img className="logo-img" src="/assets/images/logo-img.svg" alt="" />
                  <img className="logo-text" src="/assets/images/logo-text2.svg" alt="" />
                </a>
              </div>

              <div className="collapse navbar-collapse" id="navbar-collapse-1">
                <ul className="nav navbar-nav">
                  <li className="dropdown">
                    <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Events <span className="caret"></span></a>
                    <ul className="events-dropdown dropdown-menu">
                      {eventPages}
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Learn <span className="caret"></span></a>
                    <ul className="dropdown-menu">
                      <li><a >About Us</a></li>

                      <li><a href="/faq">FAQ's</a></li>
                      <li><a href="/blogs">Blog</a></li>
                      <li><a href="/privacy">Privacy</a></li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Partners <span className="caret"></span></a>
                    <ul className="dropdown-menu">
                      <li><a href="/partner">Insurance Agents</a></li>
                      <li><a href="/planner">Event Planners</a></li>
                      <li><a href="/venue">Venue Partners</a></li>
                      <li><a href="/affliate">Other</a></li>
                    </ul>
                  </li>
                  <li className="visible-xs"><a href={`${loginLink}`}>Login</a></li>
                  <li className="visible-xs"><a href="/contact">Contact</a></li>
                </ul>
              </div>
              {/* <!-- /.navbar-collapse --> */}
              <div className="menu-links hidden-xs">
                <ul>
                  <li><a className="btn btn-primary" href={`${loginLink}`}>{this.state.isLoggedin == 'true' ? 'Portal' : 'Login'}</a></li>
                  <li><a className="btn btn-brand" href="/contact">Contact</a></li>
                </ul>
                <div className="clearfix"></div>
              </div>
            </nav>
          </div>
        </header>

      </Fragment>
    );
  }
}
export default Header;