import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import Inputmask from "inputmask";
import axios from 'axios';

export default class ODE11 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'no_of_relation' : 0,
            'relation' : '',
            'venueAsAdditionalInsured': 'No',
            'page2': false, //for the second page
            'hideQuestion' : false,
            'redirectToReferrer': false,
            'isSetVenue' : false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        this.props.functionPrev.handleUpdatePrevious(prev);
        Inputmask().mask(document.querySelectorAll("input"));
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        const localStorageData2 = JSON.parse(localStorage.getItem('venueDetailODE'));
        setTimeout(function(){
        this.setState({ no_of_relation : localStorageData['eventAddonBakit'].additional_insured})
    }.bind(this),5
    );

    var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url + 'get_quote/get_states_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({ countries: result.data.countries });
                }
            })
            .catch(error => this.setState({ error: error.message }));

        if (localStorageData && localStorageData['addionalInsureBakit'] || localStorageData2 && localStorageData2['venue_details']) {
            if (localStorageData2 && localStorageData2['venue_details']) {
                const length = localStorageData['eventAddonBakit'].additional_insured;
            for(var i=0; i < length; i++)
            {

                const additional_insure_name = 'additional_insure_name[' + i + ']';
                const additional_insure_relation = 'additional_insure_relation['+ i +']';
                const additional_insure_street = 'additional_insure_street['+ i +']';
                const additional_insure_city = 'additional_insure_city['+ i +']';
                const additional_insure_state = 'additional_insure_state['+ i +']';
                const additional_insure_zipcode = 'additional_insure_zipcode['+ i +']';
                const additional_insure_email= 'additional_insure_email['+ i +']';

                
      
                
                this.setState({ [additional_insure_name] : localStorageData2['venue_details'][additional_insure_name]});
                this.setState({ [additional_insure_relation] : localStorageData2['venue_details'][additional_insure_relation]});
                this.setState({ [additional_insure_street] : localStorageData2['venue_details'][additional_insure_street]});
                this.setState({ [additional_insure_city] : localStorageData2['venue_details'][additional_insure_city]});
                this.setState({ [additional_insure_state] : localStorageData2['venue_details'][additional_insure_state]});
                this.setState({ [additional_insure_zipcode] : localStorageData2['venue_details'][additional_insure_zipcode]});
                this.setState({ [additional_insure_email] : localStorageData2['venue_details'][additional_insure_email]});

            }

            this.setState({isSetVenue : true });
            } 
            else
            {
                this.setState({ page2: localStorageData['addionalInsureBakit'].page2 });
                this.setState({ quote_any_else: localStorageData['addionalInsureBakit'].quote_any_else });
                this.setState({no_of_relation : localStorageData['addionalInsureBakit'].no_of_relation });
                if (localStorageData['addionalInsureBakit'].hideQuestion === true)
                {
                    this.setState({ hideQuestion : true});
                }
                const length = localStorageData['eventAddonBakit'].additional_insured;
                for(var i=0; i < length; i++)
                {

                    const additional_insure_name = 'additional_insure_name[' + i + ']';
                    const additional_insure_relation = 'additional_insure_relation['+ i +']';
                    const additional_insure_street = 'additional_insure_street['+ i +']';
                    const additional_insure_city = 'additional_insure_city['+ i +']';
                    const additional_insure_state = 'additional_insure_state['+ i +']';
                    const additional_insure_zipcode = 'additional_insure_zipcode['+ i +']';
                    const additional_insure_email= 'additional_insure_email['+ i +']';
        
                    
                    this.setState({ [additional_insure_name] : localStorageData['addionalInsureBakit'][additional_insure_name]});
                    this.setState({ [additional_insure_relation] : localStorageData['addionalInsureBakit'][additional_insure_relation]});
                    this.setState({ [additional_insure_street] : localStorageData['addionalInsureBakit'][additional_insure_street]});
                    this.setState({ [additional_insure_city] : localStorageData['addionalInsureBakit'][additional_insure_city]});
                    this.setState({ [additional_insure_state] : localStorageData['addionalInsureBakit'][additional_insure_state]});
                    this.setState({ [additional_insure_zipcode] : localStorageData['addionalInsureBakit'][additional_insure_zipcode]});
                    this.setState({ [additional_insure_email] : localStorageData['addionalInsureBakit'][additional_insure_email]});

                }
            }
            if (localStorageData['addionalInsureBakit'].readOnly)
            {
                this.setState({ isSetVenue: true });
            }
        }
    }
    handleAddRelation = (event) => {
        this.setState({no_of_relation : ++this.state.no_of_relation});
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ page2: true }, () => {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
            localStorageData['addionalInsureBakit'] = this.state;
            if(this.props.questionNum >= localStorageData.current_step)
            localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            });
            // optional
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleVenueAsAdditionalInsured = (type, event) => {
        let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        const length = localStorageData['eventAddonBakit'].additional_insured;
        if (type === 'Yes') {
            if (localStorageData && localStorageData['eventVenueBakit']) {

                for (var i = 0; i < length; i++) {

                    const additional_insure_name = 'additional_insure_name[' + i + ']';
                    const additional_insure_relation = 'additional_insure_relation[' + i + ']';
                    const additional_insure_street = 'additional_insure_street[' + i + ']';
                    const additional_insure_city = 'additional_insure_city[' + i + ']';
                    const additional_insure_state = 'additional_insure_state[' + i + ']';
                    const additional_insure_zipcode = 'additional_insure_zipcode[' + i + ']';
                    const additional_insure_email = 'additional_insure_email[' + i + ']';

                    if (i === 0) {
                        this.setState({ [additional_insure_name]: localStorageData['eventVenueBakit'].venue_name });
                        this.setState({ [additional_insure_relation]: '' });
                        this.setState({ [additional_insure_street]: localStorageData['eventVenueBakit'].venue_address });
                        this.setState({ [additional_insure_city]: localStorageData['eventVenueBakit'].venue_city });
                        this.setState({ [additional_insure_state]: localStorageData['eventVenueBakit'].venue_state });
                        this.setState({ [additional_insure_zipcode]: localStorageData['eventVenueBakit'].venue_zip });
                        this.setState({ [additional_insure_email]: localStorageData['eventVenueBakit'].venue_contact_email });

                    }

                }

                this.setState({ venueAsAdditionalInsured: 'Yes' });
            }
        }
        else {
            for (var i = 0; i < length; i++) {

                const additional_insure_name = 'additional_insure_name[' + i + ']';
                const additional_insure_relation = 'additional_insure_relation[' + i + ']';
                const additional_insure_street = 'additional_insure_street[' + i + ']';
                const additional_insure_city = 'additional_insure_city[' + i + ']';
                const additional_insure_state = 'additional_insure_state[' + i + ']';
                const additional_insure_zipcode = 'additional_insure_zipcode[' + i + ']';
                const additional_insure_email = 'additional_insure_email[' + i + ']';

                this.setState({ [additional_insure_name]: '' });
                this.setState({ [additional_insure_relation]: '' });
                this.setState({ [additional_insure_street]: '' });
                this.setState({ [additional_insure_city]: '' });
                this.setState({ [additional_insure_state]: '' });
                this.setState({ [additional_insure_zipcode]: '' });
                this.setState({ [additional_insure_email]: '' });


            }
            this.setState({ venueAsAdditionalInsured: 'No' });
        }
    }
    render() {

        var countries = [];
        let index = 0;
        var length = this.state.countries && this.state.countries.length > 0 ? this.state.countries.length : 0;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index]['name']}>{this.state.countries[index]['name']}</option>
                </Fragment>
            );
            index++;
        }
        var indents = [];
        let index_number = 0;
        for (var i = 1; i <= this.state.no_of_relation; i++) {
            const additional_insure_name = 'additional_insure_name[' + index_number + ']';
            const additional_insure_relation = 'additional_insure_relation['+ index_number +']';
            const additional_insure_street = 'additional_insure_street['+ index_number +']';
            const additional_insure_city = 'additional_insure_city['+ index_number +']';
            const additional_insure_state = 'additional_insure_state['+ index_number +']';
            const additional_insure_zipcode = 'additional_insure_zipcode['+ index_number +']';
            const additional_insure_email= 'additional_insure_email['+ index_number +']';

            indents.push(
                <Fragment key={i}>
                    <div className="additional-block">
                    <h2 className="main">Additional Insured  {i}</h2>
                    <div className="small-width-container">
                    <div className="form-group">
                                <input disabled={this.state.isSetVenue} type="text" className="form-control" name={additional_insure_name} onChange={this.handleChange} value={this.state[additional_insure_name]} onBlur={this.handleBlur} placeholder="Name of Additional Insured" />
                                <div className="margin-bottom-20" style={{ color: 'red' }}>
                            </div>
                    </div>
                    <div className="form-group">
                        <input disabled={this.state.isSetVenue} type="text" className="form-control" name={additional_insure_relation} onChange={this.handleChange} value={this.state[additional_insure_relation]} placeholder="Relation" />
                                <div className="margin-bottom-20" style={{ color: 'red' }}>
                            </div>
                    </div>
                    
                  </div>
                  <div className="small-width-container">
                  <div className="form-group">
                        <input disabled={this.state.isSetVenue} type="text" className="form-control" name={additional_insure_street} onChange={this.handleChange} value={this.state[additional_insure_street]} placeholder="Street" />
                                <div className="margin-bottom-20" style={{ color: 'red' }}>
                            </div>
                    </div>
                    <div className="form-group">
                        <input disabled={this.state.isSetVenue} type="text" className="form-control" name={additional_insure_city} onChange={this.handleChange} value={this.state[additional_insure_city]} placeholder="City" />
                                <div className="margin-bottom-20" style={{ color: 'red' }}>
                            </div>
                    </div>
                  </div>
                  <div className="small-width-container">
                  <div className="form-group">
                  <select disabled={this.state.isSetVenue} className="form-control" name={additional_insure_state} onChange={this.handleChange}  value={this.state[additional_insure_state]} >
                                                <option value="0">Select State</option>
                                                {countries}
                                            </select>
                        {/* <input type="text" className="form-control" name={form-control} onChange={this.handleChange} value={this.state[additional_insure_state]} placeholder="State" /> */}
                                <div className="margin-bottom-20" style={{ color: 'red' }}>
                            </div>
                    </div>
                    <div className="form-group">
                        <input disabled={this.state.isSetVenue} type="text" className="form-control" name={additional_insure_zipcode} onChange={this.handleChange} value={this.state[additional_insure_zipcode]} placeholder="Zipcode" />
                                <div className="margin-bottom-20" style={{ color: 'red' }}>
                            </div>
                    </div>
                  </div>
                  <div className="form-group">
                        <input disabled={this.state.isSetVenue} type="text" className="form-control" name={additional_insure_email} onChange={this.handleChange} value={this.state[additional_insure_email]} placeholder="Additional insured’s email (optional)" />
                     </div>
                     </div>
                </Fragment>
            );
            index_number++;
        }
        const page = this.props.page;
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+ parseInt(1);
            return (<Redirect to={"/quote/"+page+"/" + next} />)
        }
        return (
            <Fragment>
                {/* <BackButton lsKey="onedayeventv2" stepNumber={this.props.questionNum} progressArray={this.props.progressArray} /> */}
                <section className="quote">
                <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
          <div className="content">
                        {!this.state.page2 ?
                            <Fragment>
                                
                                        <h2>Please fill out your additional insured’s details </h2>
                                        <div className="inner">
                                            {/* <igitmg src="/assets/images/quote-guests.png" alt="" /> */}
                                            {/* <div className="main-img-container large" style={{backgroundImage:'url(/assets/images/quote-guests.png)'}}></div> */}
                                    {!this.state.hideQuestion ? '' :
                                        <Fragment>
                                            <p style={{ fontSize: '20px' }}> Do you want to add Venue as additional Insured? </p>
                                            <div className="h-button margin-fix">
                                                <a disabled={this.state.isSetVenue} className={this.state.venueAsAdditionalInsured === 'Yes' ? 'btn btn-maroon2 fix-min-width' : 'btn btn-primary btn-border fix-min-width'} onClick={this.state.isSetVenue ? '':this.handleVenueAsAdditionalInsured.bind(this, 'Yes')}>Yes</a>
                                                <a disabled={this.state.isSetVenue} className={this.state.venueAsAdditionalInsured === 'No' ? 'btn btn-maroon2 fix-min-width' : 'btn btn-primary btn-border fix-min-width'} onClick={this.state.isSetVenue ? '':this.handleVenueAsAdditionalInsured.bind(this, 'No')} >No</a>

                                            </div>
                                        </Fragment>}

                                            <form onSubmit={this.handleSubmit}>
                                                <Fragment>
                                                    {indents}
                                                    <p>You can provide these details later</p>
                                                </Fragment>
                                                <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
                                            </form>
                                        </div>
                                    
                            </Fragment> :
                            <Fragment>
                                <h2>Please Verify Your insured’s details </h2>
                                <div className="inner">
                                    {/* <igitmg src="/assets/images/quote-guests.png" alt="" /> */}
                                    {/* <div className="main-img-container large" style={{backgroundImage:'url(/assets/images/quote-guests.png)'}}></div> */}
                                    <form onSubmit={this.handleSubmit}>
                                        <Fragment>
                                            {indents}
                                        </Fragment>
                                        <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
                                    </form>
                                </div>
                            </Fragment>}

          </div> 
        </section> 
            </Fragment>
        );
    }
} 