import React, { Component, Fragment, useState } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur, handleChangeRadio } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import Inputmask from "inputmask";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css"

// import MultipleDatePicker from 'react-multiple-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import "../../css/customdatepicker.css";


export default class ODE2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'event_type_name': '',
      'event_date': '',
      'when_event': '',
      'today_date': new Date(),
      'tomorrow _date': '',
      'dates': '',
      'singleDate': '',
      'EventSingleDate': '',
      'disableDays': [],
      'disableDate':[],
      'multiDisableDays': [],
      'is_showMultiDate': false,
      'isValidate': false,
      'redirectToReferrer': false,
      'redirectToVender':false,
      'displayOnly' : false,
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    this.handleChangeRadio = handleChangeRadio.bind(this);
    this.handleBlur = handleBlur.bind(this);

  }
  componentDidMount() {

    // if coming form API then show only data (readOnly)
    if (localStorage.getItem('oneLoveVenueDetailODE'))
    {
      this.setState({displayOnly:true});
    }

    if(localStorage.getItem('isHostSet'))
    {
        localStorage.removeItem('isHostSet');
    }

    // Usman 22-Feb-2021
    if(localStorage.getItem('venderToODE')){
      var onedayeventv2_data = JSON.parse(localStorage.getItem('onedayeventv2'));
      
      onedayeventv2_data['current_type'] = 'vender';
      onedayeventv2_data['eventTypeBakit'].event_type = '32';
      onedayeventv2_data['eventTypeBakit'].event_type_name = "Vender at Event";
      onedayeventv2_data['eventTypeBakit'].other_event_code = "";
      onedayeventv2_data['eventTypeBakit'].selcted_noha_type = "500";
      onedayeventv2_data['eventTypeBakit'].selectedOption = "";

      localStorage.setItem('onedayeventv2', JSON.stringify(onedayeventv2_data));
      localStorage.removeItem("venderToODE");
      this.setState({redirectToVender : true});
  }

    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    this.setState({ when_event: 'Other' });
    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));

    if (localStorageData && localStorageData['eventDateBakit']) {
      this.setState({ when_event: localStorageData['eventDateBakit'].when_event });
      this.setState({ event_date: localStorageData['eventDateBakit'].event_date });
      this.setState({ is_showMultiDate: localStorageData['eventDateBakit'].is_showMultiDate });
      this.setState({ disableDays: localStorageData['eventDateBakit'].disableDays });
      this.setState({ disableDate: localStorageData['eventDateBakit'].disableDate });
      this.setState({ multiDisableDays: localStorageData['eventDateBakit'].multiDisableDays });


      if (localStorageData['eventDateBakit'].singleDate) {
        this.setState({ singleDate: localStorageData['eventDateBakit'].singleDate });
        this.setState({ EventSingleDate: localStorageData['eventDateBakit'].EventSingleDate });
      }
      if (localStorageData['eventDateBakit'].dates) {
        this.setState({ dates: localStorageData['eventDateBakit'].dates });
        this.setState({ event_date: localStorageData['eventDateBakit'].dates });
      }
      if (localStorageData['eventDateBakit'].readOnly) {
        {
          this.setState({ displayOnly: true });
        }
      }
    }


    if (localStorageData && localStorageData['eventTypeBakit'].event_type_name) {
      this.setState({ event_type_name:  localStorageData['eventTypeBakit'].event_type_name });
    }
    if (localStorage.getItem('reffral_Str')) {
      localStorage.removeItem('reffral_Str');
    }
    if (localStorage.getItem('mq_msg')) {
      localStorage.removeItem('mq_msg');
    }
    //For event type merge
    this.mergeEventTypeArray();
  }


  mergeEventTypeArray = () => {
    
    let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    const eventTypeArray = JSON.parse(localStorage.getItem('eventTypeArray'));
    const page = this.props.page;

    if (eventTypeArray) {
      var event_info = {
        'event_type': eventTypeArray.event_type,
        'event_type_name': eventTypeArray.event_type_name,
        'other_event_code': eventTypeArray.other_event_code,
        'selectedOption': eventTypeArray.selectedOption,
        'selcted_noha_type': eventTypeArray.selcted_noha_type,
        'referral_string': eventTypeArray.referral_string
      };
      if (eventTypeArray && !localStorageData) {
        var form_info = { 'current_step': 2, 'current_type': page, 'eventTypeBakit': event_info, };
        form_info = JSON.stringify(form_info);
        localStorage.setItem('onedayeventv2', form_info);

      } else if (eventTypeArray && localStorageData) {

        localStorageData['eventTypeBakit'] = event_info;
        localStorageData['current_type'] = page;

        if (localStorage.getItem('onedayeventv2')) {
          localStorageData = JSON.stringify(localStorageData);
          localStorage.setItem('onedayeventv2', localStorageData);
        }

      }
      this.setState({ event_type_name: eventTypeArray.event_type_name });
      localStorage.removeItem('eventTypeArray');

    } else {
      if (!localStorageData)
        this.setState({ redirectToReferrer: true });
    }

  }
  handleEvent = (event) => {
    this.setState({ when_event: event });
  }

  handleSubmit = (event) => {
    if (event) {

      event.preventDefault();

    }

    if (this.validator.allValid()) {

      let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
      const eventTypeArray = JSON.parse(localStorage.getItem('eventTypeArray'));

      const forms_bus_name = {
        'when_event': this.state.when_event,
        'event_date': this.state.event_date,
        'dates': this.state.dates,
        'EventSingleDate': this.state.singleDate,
        'singleDate': this.state.singleDate,
        'is_showMultiDate': this.state.is_showMultiDate,
        'disableDays': this.state.disableDays,
        'disableDate': this.state.disableDate,
        'multiDisableDays': this.state.multiDisableDays
      };

      //   var event_info = {
      //     'event_type': eventTypeArray.event_type,
      //     'event_type_name': eventTypeArray.event_type_name,
      //     'other_event_code': eventTypeArray.other_event_code,
      //     'selectedOption': eventTypeArray.val,
      //     'selcted_noha_type': eventTypeArray.selcted_noha_type,
      //     'referral_string': eventTypeArray.reffral_Str
      // };

      if (localStorage.getItem('onedayeventv2')) {
        let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        // localStorageData['eventTypeBakit'] = event_info;
        localStorageData['eventDateBakit'] = forms_bus_name;

        localStorageData.current_step = parseInt(this.props.questionNum) + 1;
        localStorageData = JSON.stringify(localStorageData);
        localStorage.setItem('onedayeventv2', localStorageData);
      }
      // else {
      //     var url_forms_info = { 'current_step': 3, 'eventTypeBakit': event_info, 'eventDateBakit': forms_bus_name };
      //     url_forms_info = JSON.stringify(url_forms_info);
      //     localStorage.setItem('onedayeventv2', url_forms_info);
      // }
      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  dateFormatChanger = (date) => {
    var dd = date.getDate()
    var mm = date.getMonth()+1 
    var yyyy = date.getFullYear()
    
    return mm+'/'+dd+'/'+yyyy
  }


  handleEventDate = (date, event) => {
    var today = this.dateFormatChanger(new Date());
    var tomorrow = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = this.dateFormatChanger(tomorrow);
    var dt = [];
    if (date == 'Today') {
      dt = { '0': today }
    }
    else if (date == 'Tomorrow') {
      dt = { '0': tomorrow }
    }
    else if (date == 'Other') {
      dt = [];
    }


    this.setState({ event_date: dt });
    this.setState({ when_event: date });
    this.setState({ dates: '' });
    this.setState({ singleDate: '' });
    this.setState({ EventSingleDate: '' });
    this.setState({ disableDays: [] });
    this.setState({ disableDate: [] });
    this.setState({ multiDisableDays: [] });


    setTimeout(function () {
      this.handleSubmit();
    }.bind(this), 5);
  };
  handleDate = (dates) => {
    var twoWeeks = [];
    var days = [];
    var data = dates;
    if (data.length > 0) {
      for (var j = 0; j < data.length; j++) {
        var year = data[j].year;
        var day = data[j].day;
        var month = data[j].month.number;
        var dat = month + '/' + day + '/' + year;
        twoWeeks.push(dat);
        days.push(day);

      }
    }
    this.setState({ multiDisableDays: days })
    this.setState({ dates: twoWeeks });
    this.setState({ event_date: dates });
  }

  handleSingeDate = (dates) => {
    var twoWeeks = [];
    var data = dates;
    var year = data.year;
    var day = data.day;
    var month = data.month.number;
    var dat = month + '/' + day + '/' + year;
    twoWeeks.push(new Date(dat));
    this.setState({ disableDate: dat });
    this.setState({ disableDays: day });
    this.setState({ singleDate: dat });
    this.setState({ EventSingleDate: dates });
  }


  swapCalander = (event) => {
    if (event == 'single') {
      this.setState({ is_showMultiDate: false });
    } else {
      this.setState({ is_showMultiDate: true });
    }
  }
  render() {

    // Usman 22-Feb-2021 : 
    if (this.state.redirectToVender) {
      return (<Redirect to={"/quote/"+'vendor'+"/" + 3} />)
    }
    const page = this.props.page;
    if (this.state.redirectToReferrer) {
      const next = parseInt(this.props.questionNum) + parseInt(1);
      return (<Redirect to={"/quote/" + page + "/" + next} />)
    }
    var dateObject = this.state.event_date;
    this.validator.purgeFields();
    var title = '';
    
    if(this.state.event_type_name.toLocaleLowerCase() == 'vendor at event')
    {
        title = 'When is your event ?'; 
    }
    else
    {
        title = `When is your ${this.state.event_type_name.toLocaleLowerCase()} ?`;
    }
    return (
      <Fragment>
        <section className="quote event">
          <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>

          <div className="content quote-date">
            <h2>{title}</h2>
            <div className="inner">
              <div className="main-img-container " style={{ backgroundImage: 'url(/assets/images/quote-event.png)' }}></div>
              {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-event.png" alt="" /> */}
              <form onSubmit={this.handleSubmit} type="post">
                <ul className="type">
                  <li><a disabled={this.state.displayOnly} className={this.state.when_event === 'Today' ? 'btn btn-primary btn-border active' : 'btn btn-primary'} onClick={this.state.displayOnly ? '' :this.handleEventDate.bind(this, 'Today')}>Today</a></li>
                  <li><a disabled={this.state.displayOnly} className={this.state.when_event === 'Tomorrow' ? 'btn btn-primary btn-border active' : 'btn btn-primary'} onClick={this.state.displayOnly ? '' :this.handleEventDate.bind(this, 'Tomorrow')}>Tomorrow</a></li>
                  <li><a disabled={this.state.displayOnly} className={this.state.when_event === 'Other' ? 'btn btn-primary btn-border active' : 'btn btn-primary'} onClick={this.state.displayOnly ? '' :this.handleEventDate.bind(this, 'Other')} >Other</a></li>
                </ul>
                {this.state.when_event === 'Other' ?
                  <div>
                    <div className="form-group"  >

                      <DatePicker
                        disabled = {this.state.displayOnly}
                        inputClass="form-control"
                        multiple={false}
                        value={this.state.EventSingleDate}
                        mustShowDates={false}
                        scrollSensitive={false}
                        name="event_date"
                        onChange={this.handleSingeDate.bind(this)}
                        format="MM/DD/YYYY"
                        placeholder="Select date"
                        minDate={new Date()}
                        inputMode="none"
                        mapDays={({ date }) => {
                          let props = {}

                          if (this.state.multiDisableDays.includes(date.day)) props.disabled = true

                          return props
                        }}
                      />
                    </div>
                    <div className="form-group" style={{ color: 'red' }}>
                      {this.validator.message('event_date', this.state.EventSingleDate, 'required')}
                    </div>

                    {this.state.is_showMultiDate ?
                      <Fragment>
                        <div className="form-group">
                          <DatePicker
                          disabled = {this.state.displayOnly}
                            multiple
                            inputClass="form-control"
                            popperPlacement="top-start"
                            value={dateObject}
                            mustShowDates={false}
                            scrollSensitive={true}
                            name="event_date"
                            onChange={this.handleDate.bind(this)}
                            format="MM/DD/YYYY"
                            placeholder=" Click here to select more dates"
                            minDate={new Date()}
                            readOnly={true}
                            className="rmdp-mobile"
                            mapDays={({ date }) => {
                              let props = {}

                              if (this.state.disableDate.includes(date.month+'/'+date.day+'/'+date.year)) props.disabled = true

                              return props
                            }}
                          />
                        </div>
                      </Fragment>
                      :
                      <p>
                        To add more dates <a onClick={this.swapCalander.bind(this, 'multi')}>Click here</a>
                      </p>
                    }
                  </div>
                  : ''}

                <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
} 