import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Inputmask from "inputmask";
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import "react-datepicker/dist/react-datepicker.css";
import "../../css/customtimepicker.css";
import axios from 'axios';


export default class ODE3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'event_type': '',
            'event_state': '',
            'start_time': '',
            'countries': '',
            'privateResidence': 'No',
            'is_validate': false,
            'is_consectiveDate': '',
            'redirectToReferrer': false,
            'stateDisabled' : false,
            'isVendor' : false,
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        // this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {

        // if coming form API then show only data (readOnly)
        if (localStorage.getItem('oneLoveVenueDetailODE')) {
            this.setState({ stateDisabled: true });
        }

        const prev = this.props.questionNum - 1;
        this.setState({ prevQuestion: prev });
        this.props.functionPrev.handleUpdatePrevious(prev);
        Inputmask().mask(document.querySelectorAll("input"));
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        const localStorageData2 = JSON.parse(localStorage.getItem('venueDetailODE'));
        const localStorageData3 = JSON.parse(localStorage.getItem('partnerDetailODE'));

        // if vendor, then hide the private residence question
        if (localStorageData.current_type == 'vendor')
        {
            this.setState({isVendor : true});
        }
        this.props.functionLoader.handleUpdateLoader(true);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url + 'get_quote/get_states_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({ countries: result.data.countries });
                }
                this.props.functionLoader.handleUpdateLoader(false);
            })
            .catch(error => this.setState({ error: error.message }));

        

        if (localStorageData && localStorageData['eventStateBakit'] || localStorageData2 && localStorageData2['venue_details']) {
            if (localStorageData2 && localStorageData2['venue_details']) {
                this.setState({ event_state: localStorageData2['venue_details'].state });
                this.setState({ stateDisabled: true });
                if(localStorageData && localStorageData['eventStateBakit'])
                {
                    this.setState({ start_time: new Date(localStorageData['eventStateBakit'].start_time) });
                }
            }
            else
            {
                this.setState({ event_state: localStorageData['eventStateBakit'].event_state });
                this.setState({ start_time: new Date(localStorageData['eventStateBakit'].start_time) });
            }
            if (localStorageData['eventStateBakit'].readOnly) {
                {
                    this.setState({ stateDisabled: true });
                }
            }
            
        }
        if (localStorageData && localStorageData['eventTypeBakit']) {
            this.setState({ event_type: localStorageData['eventTypeBakit'].event_type });
        }
        if (localStorageData3)
        {
            this.setState({ event_state: 'California' });
            this.setState({ stateDisabled: true });
        }

        this.checkDateIsConsective();
    }
    canBeSubmitted() {

        if (this.state.event_state.length === 0) {
            this.setState({ is_validate: true });
        }
        return this.state.event_state.length > 0;
    }


    checkDateIsConsective= ()=>{
         // ......................
         const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
         if(localStorageData && localStorageData['eventDateBakit'].dates){
            var allDatesArray = [];

            allDatesArray.push(new Date (localStorageData['eventDateBakit'].EventSingleDate));
        if(localStorageData['eventDateBakit'].dates != ''){
            if(localStorageData['eventDateBakit'].dates[0]){
                allDatesArray.push(new Date (localStorageData['eventDateBakit'].dates[0]));
            }
            if(localStorageData['eventDateBakit'].dates[1]){
                allDatesArray.push(new Date (localStorageData['eventDateBakit'].dates[1]));
            }

            if(localStorageData['eventDateBakit'].dates[2]){
                allDatesArray.push(new Date (localStorageData['eventDateBakit'].dates[2]));
            }
            
            var sortedArray = allDatesArray.sort((a, b) => a - b);
            
            if(allDatesArray.length > 3){
                this.setState({is_consectiveDate: 'No'});
            }else if(allDatesArray.length == 3){
                if(Date.parse(sortedArray[2]) - Date.parse(sortedArray[1]) == '86400000' && Date.parse(sortedArray[1]) - Date.parse(sortedArray[0]) == '86400000'){
                    this.setState({is_consectiveDate: 'Yes'});
                }else{
                    this.setState({is_consectiveDate: 'No'});
                }
            }else if(allDatesArray.length == 2){
                if(Date.parse(sortedArray[1]) - Date.parse(sortedArray[0]) == '86400000'){
                    this.setState({is_consectiveDate: 'Yes'});
                }else{
                    this.setState({is_consectiveDate: 'No'});
                }
            }

         }
        }
        //............................................
    }
   addDays = (dates, days)=> {
        var date = new Date(dates);
        date.setDate(date.getDate() + days);
        return date;
    }
    dateFormte= (first)=>{
        var year = first.getFullYear();
    var day = first.getMonth();
    var month =first.getDate();
    var dat = month + '/' + day + '/' + year;
    return dat
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.canBeSubmitted()) {
            return;
        }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
            const forms_zip = {
                'event_state': this.state.event_state,
                'start_time': this.state.start_time,
                'is_consectiveDate': this.state.is_consectiveDate,
                'privateResidence': this.state.privateResidence,
            };
            localStorageData['eventStateBakit'] = forms_zip;
            localStorageData.current_step = parseInt(this.props.questionNum) + 1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleChangeDate = date => {
        this.setState({
            start_time: date
        });
    };
    handlePrivateResidenceEvent(type, event) {
        event.preventDefault();
        this.setState({ privateResidence: type });
    }
    render() {
        // Checking the Affinity Partner

        var pDetail = JSON.parse(localStorage.getItem('partnerDetailODE'));
        if(pDetail && pDetail.venue_details.state != null)
        {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
            const forms_zip = {
                'event_state': pDetail.venue_details.state,
                'start_time': "",
                'is_consectiveDate': this.state.is_consectiveDate,
                'privateResidence': this.state.privateResidence,
            };
            localStorageData['eventStateBakit'] = forms_zip;
            localStorageData.current_step = parseInt(this.props.questionNum) + 1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            this.setState({ redirectToReferrer: true });
        }
       
        var countries = [];
        let index_number = 0;
        var length = this.state.countries && this.state.countries.length > 0 ? this.state.countries.length : 0;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index_number]['name']}>{this.state.countries[index_number]['name']}</option>
                </Fragment>
            );
            index_number++;
        }
        const page = this.props.page;
        if (this.state.redirectToReferrer) {
            const next = parseInt(this.props.questionNum) + parseInt(1);
            return (<Redirect to={"/quote/"+page+"/" + next} />)
        }
        // const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        // if (localStorageData['eventStateBakit'].hideScreen === true) {
        //     {
        //         const next = parseInt(this.props.questionNum) + parseInt(1);
        //         return (<Redirect to={"/quote/" + page + "/" + next} />)
        //     }
        // }

        var handleColor = {
            borderColor: ''
        }
        if (this.state.is_validate) {
            handleColor = {
                borderColor: 'red'
            }
        }
        return (
            <Fragment>
                <section className="quote event">
                <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
                    <div className="content">
                        <h2>What state is the event in?</h2>
                        <div className="inner">
                        <div className="main-img-container" style={{backgroundImage:'url(/assets/images/quote-state.png)'}}></div>
                            {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-state.png" alt="" /> */}
                            <form onSubmit={this.handleSubmit} method="post">
                                <div className="form-group error">
                                    <div className="select-div error">
                                        <select  style={handleColor} className="form-control" name="event_state" onChange={this.handleChange} value={this.state.event_state}  disabled={this.state.stateDisabled}>
                                            <option  value="">Select State</option>
                                            {countries}
                                        </select>
                                        <div className="form-group" style={{ color: 'red' }}>
                                            {/* {this.validator.message('event_state', this.state.event_state, 'required')} */}
                                        </div>
                                    </div>
                                    {this.state.event_state != '' && !this.state.isVendor ?
                                        <Fragment>
                                            <div style={{ marginTop: '40px' }} className="hidden-qa">
                                                <h4>Is the event held at a private residence?</h4>
                                                <div className="small-width-container two-buttons">
                                                    <div className="form-group">
                                                        <button type="button" className={this.state.privateResidence === 'Yes' ? 'btn btn-primary active' : 'btn btn-primary '} onClick={this.handlePrivateResidenceEvent.bind(this, 'Yes')} disabled={this.state.stateDisabled}>Yes</button>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="button" className={this.state.privateResidence === 'No' ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.handlePrivateResidenceEvent.bind(this, 'No')} disabled={this.state.stateDisabled}>No</button>
                                                    </div>
                                                </div>
                                                <div className="form-group" style={{ color: 'red' }}>
                                                    {this.validator.message('Private Residence is', this.state.privateResidence, 'required')}
                                                </div>
                                            </div>
                                        </Fragment>
                                        : ''

                                    }
                                </div>
                                <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
                            </form>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}
