// -> Written by Usman Ghani 17 March 2021

// Module Description
// This module is used for moving from one page to another.
// It takes the step number and page name and setup the common compponent like Header and call the Question Layout which will decide which component should
// render under the Header commponent.


import React, { Fragment, useState } from 'react';
import HeaderQuestion from '../../../../../common/component/HeaderQuestion';
// import { Redirect } from 'react-router-dom';
// import Layout from '../../component/vendors/Layout';
import InvoiceQuestionLayout from '../questions/InvoiceQuestionLayout';
import Loader from '../../../../../loader/Loader';


function InvoiceQuestionContaineeGeneral(props) {
	let page = props.match.params.page;
	let number = '';

	// check if step no is certificate because it canont be parse into Int as other step numbers can.
	if(props.match.params.num === "certificate")
	{
		number = props.match.params.num;
	}
	else
	{
		number = parseInt(props.match.params.num);
	}
	// let number = (props.match.params.num === "certificate" ? props.match.params.num :  parseInt(props.match.params.num));
	let pendingQuoteId = props.match.params.genreId;

	const [prev, setPrev] = useState(0);

	const handleUpdatePrevious = (number) => {
		setPrev(number);
	}

	const [loader, setLoader] = useState(false);

	const handleUpdateLoader = (ajaxState) => {
		setLoader(ajaxState);
	}
	var Tstatus='';
	
	if(localStorage.getItem('thankx')){
		Tstatus = 'Yes';
	}
	// 	Calling InvoiceQuestionLayout, where it will decide which component should render accourding to page name and step number.
	let component = <InvoiceQuestionLayout page={page} questionNum={number} pendingQuote={pendingQuoteId} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

	return (
		<Fragment>
			<Loader isLoading={loader}></Loader>
			<div className="wrapper quote-pg">
				<HeaderQuestion prevQuestion={number} url={"/invoice/"+page+"/"} lsKey="" functionLoader={{ handleUpdateLoader: handleUpdateLoader }} ></HeaderQuestion>
				{component}
				<footer className="quote-footer"></footer>
			</div>
		</Fragment>
	);
}
export default InvoiceQuestionContaineeGeneral;
