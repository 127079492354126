import React, { Component} from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { handleChange, handleChangeRadio, handleBlur } from '../common_function/common_function';
import axios from 'axios';
export default class MainContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'password' : '',
            'confirm_password' : '',
            'agree_terms' : '',
            'redirectThanksReferrer': false,
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
 
    handleRadio = (event)=> {
        this.setState({agree_terms: !this.state.agree_terms })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('partner_signup'));
            this.props.functionLoader.handleUpdateLoader(true);
            const forms_zip = { 
                'password' : this.state.password,
                'agree_terms' : this.state.agree_terms
            };
            localStorageData['3'] = forms_zip;
            var modal = document.getElementById("messageOverlayModel");
            var base_url = process.env.REACT_APP_API_URL;
            axios({
                    method: "post",
                    url: base_url+'leads/partner_signup_ajax',
                    data : {"dataArray" : localStorageData},
                    headers: { "content-type": "application/json" },
                })
                .then(result => {
                    if (result.data.status === true){
                       // alert("Data posted successfully");
                        localStorage.removeItem('partner_signup');
                        this.setState({redirectThanksReferrer : true});
                    }else{
                        //alert("Email already exists");
                        modal.style.display = "block";
                        document.getElementById("textDanger").innerHTML= "Email already exists test";
                        this.setState({redirectToReferrer : true});
                    }

                    setTimeout(function () {
                        modal.style.display = "none";
                        document.getElementById("textDanger").innerHTML= "";
                        document.getElementById("textSuccess").innerHTML= "";
                    }.bind(this), 3000  );
                   
                    this.props.functionLoader.handleUpdateLoader(false);
                })
                .catch(error => this.setState({ error: error.message }));
     
            } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render()
    {
        if (this.state.redirectToReferrer){
            return (<Redirect to={"/partner-signup/"+1} />)
        }
        if (this.state.redirectThanksReferrer){
            return (<Redirect to={"/congrats-signup/"} />)
        }
        return(
            <section className="quote signup">
            <div className="progress">
              <ul>
                <li className="active"></li>
                <li className="active"></li>
                <li className="active"></li>
              </ul>
            </div> 
            <div className="content">
              <h2>Sign Up for Partners</h2>
              <h4>Finish</h4>
              <div className="inner">
                {/* <!-- <img src="assets/images/quote-guests.png" alt="" /> --> */}
                <form onSubmit={this.handleSubmit}>
                    <div className="small-width-container">
                      <div className="form-group">
                          <input type="password" className="form-control" name="password"  onChange={this.handleChange} value={this.state.password} placeholder="Password" />
                          <div  style={{color:'red'}}>  {this.validator.message('password', this.state.password, 'required')}
                     </div>
                      </div>
                      <div className="form-group">
                          <input type="password" className="form-control" name="confirm_password"  onChange={this.handleChange} value={this.state.confirm_password} placeholder="Confirm password" />
                          <div  style={{color:'red'}}>   {this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, {messages: {in: 'Passwords need to match!'}})}
               
                     </div>
                      </div>
                    </div>
                    <div className="small-width-container flex-cancel">
                      <div className="well">
                        <h4>Partner Program Terms & Conditions</h4>
                         <p>In the states where our partner program is available, we will pay you 15% of the premium, and only the premium, per sale. You must use your personalized referral link to refer your clients in order to receive payment. You must also have a PayPal account so we may transfer you the funds, or accept checks that we can mail to you at the designated pay periods. Our employees may not participate in our partner program. </p>
                         <p>You cannot “refer” yourself. When referring someone, you can tell them about One Day Event and our stellar customer service abilities. You get paid when your client purchases a policy. The event must be booked with a date, time, and venue to be considered for the partner program. If the policy is cancelled for any reason you will not receive any referral fees. We reserve the right to withhold payment and shut down your partner program if we find any fraudulent activity, misuse of our proprietary information, misuse of our logo or name, breach of our Privacy Policy or any of our compliance, license expiration or lack thereof, poor service with clients, or any reason to believe you are not acting with the customers best interest in mind. Once a client applies for coverage and purchases a policy, they can’t be referred again by a different person.  </p>
                         <p>You cannot publish or distribute your referral link on websites or blogs with the primary purpose of soliciting referral fees. You cannot spam anyone with referral invitations. This includes mass emailing, texting, or messaging people you do not know or using automated systems or bots through any channel to distribute your referral link. You are prohibited from paying to advertise your referral link. You also cannot pay your clients or friends to apply for coverage or share your referral fee with them. </p>
                         <p>We may make changes to, suspend or terminate our partner program or your ability to participate in our partner program at any time for any reason. We reserve the right to investigate all referral activities and to suspend accounts or not pay fees if we notice any activity that we believe is abusive, fraudulent, or in violation of these Partner Program Terms or our website Terms.  </p>
                        </div>
                      <div className="checkbox checkbox-primary">
                          <input id="checkbox2" className="styled checkbox2" type="checkbox" onChange={this.handleRadio} name="agree_terms" value={this.state.agree_terms} />
                           <label htmlFor="checkbox2">
                              I accept and read the terms & conditions
                          </label>
                      </div>
                      <div  style={{color:'red'}}>  {this.validator.message('Terms', this.state.agree_terms, 'required')}
                     </div>
                    </div>
                    <button type="submit" className="btn btn-maroon">Submit</button>
                </form>
                
              </div> 
              {/* <!--inner--> */}
            </div>
             {/* <!--content--> */}
          </section> 
         
          );
    }
}