import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router-dom';
import { handleChange, handleBlur } from '../../common_function/common_function';
import BackButton from '../../business/component/BackButton';
import SimpleReactValidator from 'simple-react-validator';
import MessageModel from '../../Error/MessageModel';
import Inputmask from "inputmask";
import $, { nodeName } from 'jquery';
import axios from 'axios';
function validate(email, phone) {
    return {
        email: email.length === 0,
        phone: phone.length === 0,
    };
}
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'lskey': this.props.lsKey,
            'email': '',
            'phone': '',
            'popupVisible': false,
            'popupPhoneVisible': false,
            'numbertooltipVisible': false,
            'redirectToReferrer': false,
            'focusOut': {
                'email': false,
                'phone': false
            },
            'isDisabled': '',
            'isAcceptCookie': '',
            'teague' : false,
            'CasaDeLago' : false
        }
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleNumbertooltip = this.toggleNumbertooltip.bind(this);

        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.$('.pop').popover();
        if (localStorage.getItem('isAcceptCookie')) {
            this.setState({ isAcceptCookie: localStorage.getItem('isAcceptCookie') });
        }

        if (localStorage.getItem('partnerQuoteODE')) {
            this.setState({ teague: true });
            const data = JSON.parse(localStorage.getItem('partnerQuoteODE'))
            if (data.venue_name == 'Casa-de-Lago-Events')
            {
                this.setState({ CasaDeLago: true });

            }
        }

        Inputmask().mask(document.querySelectorAll("input"));
        
    }

    toggleNumbertooltip(event) {
        $('.save-popover').css("display", "none");
        setTimeout(function () {
            this.setState({ numbertooltipVisible: !this.state.numbertooltipVisible })
            window.$('.pop').popover();
        }.bind(this), 5
        );

    }
    togglePopup(event) {
        event.preventDefault();
        event.stopPropagation();
        setTimeout(function () {
            window.$('.qtpopup').css("display", "block");
            window.$('.phonepopup').css("display", "none");
        }.bind(this), 5
        );
        this.setState({ popupVisible: !this.state.popupVisible });
    }

    togglePhonePopup(event) {
        event.preventDefault();
        event.stopPropagation();
        setTimeout(function () {
            window.$('.phonepopup').css("display", "block");
            window.$('.qtpopup').css("display", "none");
        }.bind(this), 5
        );
        this.setState({ popupPhoneVisible: !this.state.popupPhoneVisible });
    }

    handleValidateBlur = field => evt => {
        this.setState({
            focusOut: { ...this.state.focusOut, [field]: true }
        });
        this.validator.showMessageFor(field);
    };
    canBeSubmitted() {
        const errors = validate(this.state.email, this.state.phone);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }

    getLocalTimeZone = () => {
        var dd = new Date();
        var ddStr = dd.toString();
        var ddArr = ddStr.split(' ');
        var tmznSTr = ddArr[5];
        tmznSTr = tmznSTr.substring(3, tmznSTr.length);
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date + ' ' + time;
        return dateTime + tmznSTr + '---' + Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.canBeSubmitted()) {
            return;
        }
        const button = document.querySelector('button');
        if (this.validator.allValid()) {
            button.disabled = true;
            this.props.functionLoader.handleUpdateLoader(true);
            var base_url = process.env.REACT_APP_API_URL;
            var dates = [];
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
            let pending_quote_id = JSON.parse(localStorage.getItem('pending_quote_id'));
            if (localStorageData && localStorageData['eventDateBakit']) {
                var dates = localStorageData['eventDateBakit'].dates;
            }
           
            const min = 1;
            const max = 1000;
            var rand = Math.floor(Math.random() * (max - min + 1) + min)
            const forms_zip = {
                'email': this.state.email,
                'phone': this.state.phone.slice(1),
                'dates': dates,
                'pending_quote_id': JSON.stringify(pending_quote_id),
                'pending_quote_status': 'pending',
                'submission_status': 'saved',
                'pending_ode_quote_link_id': '000' + rand,
                'current_date': this.getLocalTimeZone(),
                'pending_json_form_array': JSON.stringify(localStorageData)
            };
            localStorageData[localStorageData.current_step] = forms_zip;
           // var modal = document.getElementById("messageSuccessModel");
            axios({
                method: "post",
                url: base_url + 'leads/ode_quote_ajax',
                data: { "dataArray": localStorageData },
                headers: { "content-type": "application/json" },
            })
                .then(result => {
                    this.props.functionLoader.handleUpdateLoader(false);
                    button.disabled = false;
                   
                    // if (result.data.success === true) {
                       // modal.style.display = "block";
                        document.getElementById("successTagHeadMsg").innerHTML= "Thanks!";

                        window.$("#message-popup").modal("show");
                        var odeCertificate = JSON.stringify({
                            'email': this.state.email,
                        });
                        localStorage.setItem('odeCertificate', odeCertificate);
                        window.$('.save-popover').css("display", "none");
                        setTimeout(function () {
                            window.$("#message-popup").modal("hide");
                            document.getElementById("successTagHeadMsg").innerHTML= "";
                        }.bind(this), 3000  );
                    // }

                    // free cache storage
                    if(localStorage.getItem('partnerDetailODE'))
                    {
                        localStorage.removeItem('partnerDetailODE');
                    }
                    if(localStorage.getItem('partnerQuoteODE'))
                    {
                        localStorage.removeItem('partnerQuoteODE');
                    }
                    if(localStorage.getItem('reffral_Str'))
                    {
                        localStorage.removeItem('reffral_Str');
                    }
                    if (localStorage.getItem('oneLoveVenueDetailODE')) {
                        localStorage.removeItem('oneLoveVenueDetailODE');
                    }
                })
                .catch(error => this.setState({ error: error.message }));
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleBack = () => {
        let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        if (localStorageData && localStorageData['addionalInsureBakit']) {
            console.log(localStorageData);
            localStorage.setItem(localStorageData['addionalInsureBakit'].page2, false);
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            console.log(localStorageData);

        }

        if (this.props.prevQuestion == 1)
            alert('No previous question');
        else
            this.setState({ redirectToReferrer: true });

    }

    handleDomToggle = (e) => {
        $('[data-toggle=popover]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                window.$(this).popover('hide');

            }
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.save-popover').has(e.target).length === 0) {

                window.$('.save-popover').css("display", "none");
            }
        });

        //this.setState({popupVisible: false});

    }

    render() {
        if (this.state.redirectToReferrer) {
            let queston_number = this.props.prevQuestion - 1;
            if (this.props.prevQuestion == 'thanks') {
                queston_number = 9;
            }

            this.setState({ redirectToReferrer: false });
            if(queston_number === 1)
               return (<Redirect to={"/onedayevent/" + queston_number} />)
            else
               return (<Redirect to={this.props.url + queston_number} />)
        }
        document.addEventListener('click', this.handleDomToggle);
        const errors = validate(this.state.email, this.state.phone);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        const shouldMarkError = field => {
            const hasError = errors[field];
            const shouldShow = this.state.focusOut[field];
            return hasError ? shouldShow : false;
        };
        return (
            <Fragment>
                <MessageModel></MessageModel>
                <header>
                    <div className="container">
                        {this.props.prevQuestion != 1 && this.props.prevQuestion != 13 && this.props.prevQuestion != 'confirmation' && this.props.prevQuestion != 'certificate' ?
                            <a className="back-icon" style={{ cursor: 'pointer' }} onClick={this.handleBack}><img src="/assets/images/icon-back-circle.svg" alt="" />  <BackButton lsKey="onedayevent" stepNumber={this.props.prevQuestion} /></a>
                            : ''}
                        <div className="logo">
                            <a href="/."><img src="/assets/images/logo-img.svg" alt="" /></a>
                        </div>
                        <div className="menu-right  hidden-xs">
                            <ul>
                                <li></li>
                                {this.props.prevQuestion != 1 && this.props.prevQuestion != 'confirmation' && this.props.prevQuestion != 'certificate' ?
                                    <li>
                                        <a onClick={this.togglePopup.bind(this)} className="btn  btn-brand quote-popover " data-placement="bottom" data-toggle="popover" data-container="body" data-placement="bottom" type="button" data-html="true" href="#">Save Quote</a>
                                        {/* {this.state.popupVisible === true ? */}
                                        <Fragment>
                                            <div className="popover save-popover qtpopup" id="savePopover" style={{ display: "none" }}>
                                                <div className="top-arrow"></div>
                                                <form onSubmit={this.handleSubmit} role="form">
                                                    <div className="form-group">
                                                        <input type="text" className={shouldMarkError("email") ? "error" : ""} autoComplete="off" placeholder="Email address" name="email" value={this.state.email} onChange={this.handleChange} onBlur={this.handleValidateBlur("email")} autoFocus={true} />
                                                    </div>
                                                    <div className="form-group" style={{ color: 'red' }}>
                                                        {this.validator.message('email', this.state.email, 'required|email')}
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" data-inputmask="'mask': '1(999) 999-9999'" className={shouldMarkError("phone") ? "error" : ""} placeholder="Phone Number" autoComplete="off" name="phone" value={this.state.phone} onChange={this.handleChange} onBlur={this.handleValidateBlur("phone")} />
                                                    </div>
                                                    <div className="form-group" style={{ color: 'red' }}>
                                                        {this.validator.message('phone', this.state.phone, 'required|phone')}
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" disabled={isDisabled} className={isDisabled ? "btn btn-brand disabled" : "btn btn-brand"} disabled={this.props.disabled} >Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </Fragment>
                                        {/* : '' } */}
                                    </li>
                                    : ''}
                                {/* <li> */}
                                {/* <a className="btn btn-default pop" onClick={this.toggleNumbertooltip} data-container="body" data-toggle="popover" data-placement="bottom" data-placement="bottom" data-html="true" data-content=" <a href='tel:408-217-6787'>Tel:408-217-6787</a><br/>
                 <a href='mailto:support@onedayevent.com'>Mail:support@onedayevent.com </a>"><img src="/assets/images/icon-phone-header.svg" alt="" />Phone</a> </li> */}
                                <li>
                                    <a onClick={this.togglePhonePopup.bind(this)} className="btn  btn-default quote-popover " data-placement="bottom" data-toggle="popover" data-container="body" data-placement="bottom" type="button" data-html="true" href="#">Phone</a>
                                    <div className="popover save-popover phonepopup" style={{ display: 'none' }}>
                                        <div className="top-arrow"></div>
                                        <div className="phone">
                                            <a href="tel:+1408-217-6787"><img src="/assets/images/phone-call.svg" alt="" /> 408-217-6787</a>
                                            <a href="mailto:support@onedayevent.com"><img src="/assets/images/phone-email.svg" alt="" />support@onedayevent.com </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {this.state.teague ? 
                        <div style={{marginRight:'320px'}} className="menu-right" style={{float:'none',textAlign:'center'}}>

                            {this.state.CasaDeLago ? 
                                     <a href="/."><img src="/assets/images/casa-de-lago.png" style={{width:'150px', marginTop:'-35px'}} alt="" /></a>

                            : 
                                    <a href="/."><img src="/assets/images/Teague.jpg" alt="" /></a>

                                }
                        </div> : 
                        null}
                        <div className="menu-right  visible-xs">
                            <ul>
                                {this.props.prevQuestion != 1 && this.props.prevQuestion != 'confirmation' && this.props.prevQuestion != 'certificate' ?
                                    <li>
                                        <a onClick={this.togglePopup.bind(this)} className="btn  btn-brand quote-popover " data-placement="bottom" data-toggle="popover" data-container="body" data-placement="bottom" type="button" data-html="true" href="#">Save</a>
                                        {/* {this.state.popupVisible === true ? */}
                                        <Fragment>
                                            <div className="popover save-popover qtpopup" id="savePopover" style={{ display: "none" }}>
                                                <div className="top-arrow"></div>
                                                <form onSubmit={this.handleSubmit} role="form">
                                                    <div className="form-group">
                                                        <input type="text" className={shouldMarkError("email") ? "error" : ""} autoComplete="off" placeholder="Email address" name="email" value={this.state.email} onChange={this.handleChange} onBlur={this.handleValidateBlur("email")} autoFocus={true} />
                                                    </div>
                                                    <div className="form-group" style={{ color: 'red' }}>
                                                        {this.validator.message('email', this.state.email, 'required|email')}
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" data-inputmask="'mask': '(999) 999-9999'" className={shouldMarkError("phone") ? "error" : ""} placeholder="Phone Number" autoComplete="off" name="phone" value={this.state.phone} onChange={this.handleChange} onBlur={this.handleValidateBlur("phone")} />
                                                    </div>
                                                    <div className="form-group" style={{ color: 'red' }}>
                                                        {this.validator.message('phone', this.state.phone, 'required|phone')}
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" disabled={isDisabled} className={isDisabled ? "btn btn-brand disabled" : "btn btn-brand"} disabled={this.props.disabled} >Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </Fragment>
                                    </li>
                                    : ''}
                                {/* <li><a className="pop" onClick={this.toggleNumbertooltip} data-container="body" data-toggle="popover" data-placement="bottom" data-placement="bottom" data-html="true" data-content=" <a href='tel:408-217-6787'>Tel:408-217-6787</a><br/>
                 <a href='mailto:support@onedayevent.com'>Mail:support@onedayevent.com </a>"><img src="/assets/images/icon-phone-header.svg" alt="" /></a></li> */}
                                <li>
                                    <a onClick={this.togglePhonePopup.bind(this)} className="quote-popover " data-placement="bottom" data-toggle="popover" data-container="body" data-placement="bottom" type="button" data-html="true" href="#"><img src="/assets/images/icon-phone-header.svg" alt="" /></a>
                                    <div className="popover save-popover phonepopup" style={{ display: 'none' }}>
                                        <div className="top-arrow"></div>
                                        <div className="phone">
                                            <a href="tel:+1408-217-6787"><img src="/assets/images/phone-call.svg" alt="" /> 408-217-6787</a>
                                            <a href="mailto:support@onedayevent.com"><img src="/assets/images/phone-email.svg" alt="" />support@onedayevent.com </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </header>
            </Fragment>
        );
    }
}
export default Header;