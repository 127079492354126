import React, { Component, Fragment } from 'react';
export default class MessageModel extends Component {

    render() {
        return (
            <Fragment>
<div className="message-overlay" style={{ display:"none"}} id="messageOverlayModel">
    <div className="inner"> 
            <a  className="cross"> <img src="/assets/images/cross.svg" alt="" /> </a>
            <p className="text-danger" id="textDanger"></p>
            <p className="text-success" id="textSuccess" ></p>    
    </div>
</div> 

<div className="modal fade msg-popup" tabIndex="-1" role="dialog" id="message-popup">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
           
            <div className="modal-body success-body-modal">
                <img className="" src="/assets/images/success-tick.svg" alt="" />
                <div className="text-center">
                    <h2 id="successTagHeadMsg">Success</h2>
                    {/* <p className="text-success">Using color to add meaning only provides a visual indication,</p> */}
                </div>
            </div>
        </div>
    </div>
</div>
            </Fragment>
       );
    }
}