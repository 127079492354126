import React, { Component} from 'react';
import Header from '../common/component/Header';
import Footer from '../common/component/Footer';
import MainContent from './MainContent';
export default class Home extends Component {
    
    render()
    {
        return(
            <div className="wrapper">
                <Header></Header>
                <MainContent></MainContent>
                <Footer></Footer>
            </div>
        );
    }
}