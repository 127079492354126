import React, { Component, Fragment } from 'react'
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import { Redirect, Link } from 'react-router-dom';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import Slider from "react-slick";
import Inputmask from "inputmask";
import $ from 'jquery';
import axios from 'axios';

export default class ODE7 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'coverage_limit': '',
      'plan_lowest': '',
      'lowest_limit': '',
      'plan_lowest_max_limit': '',
      'plan_lowest_min_limit': '',
      'plan_lowest_total': '0',
      'plan_lowest_carrier': '',

      'plan_mediocre': '',
      'mediocre_lowest': '',
      'plan_mediocre_max_limit': '',
      'plan_mediocre_min_limit': '',
      'plan_mediocre_total': '0',
      'plan_mediocre_carrier': '',

      'plan_highest': '',
      'highest_limit': '',
      'plan_highest_max_limit': '',
      'plan_highest_min_limit': '',
      'plan_highest_total': '0',
      'plan_highest_carrier': '',

      'plan_most_expensive': '',
      'most_expensive_limit': '',
      'plan_most_expensive_max_limit': '',
      'plan_most_expensive_min_limit': '',
      'plan_most_expensive_total': '0',
      'plan_most_expensive_carrier': '',

      'plan_recommended': '',
      'recommended_limit': '',
      'plan_recommended_max_limit': '',
      'plan_recommended_min_limit': '',
      'plan_recommended_total': '0',
      'plan_recommended_carrier': '',

      'Selected_max_limit': '',
      'Selected_min_limit': '',
      'Selected_max_total': '',
      'selected_plan_type': '',
      'selected_dynamic_rates_array': '',
      'selected_plan_full_array': '',

      'plenght': '0',
      'mlenght': '0',
      'hlenght': '0',
      'rlenght': '0',
      'elenght': '0',

      'selected_lenght': '',

      'plan_lowest_limits_array': '',
      'plan_mediocre_limits_array': '',
      'plan_highest_limits_array': '',
      'plan_most_expensives_array': '',
      'plan_recommended_limits_array': '',

      'plan_lowest_full_array': '',
      'plan_mediocre_full_array': '',
      'plan_highest_full_array': '',
      'plan_most_expensives_array': '',
      'plan_recommended_full_array': '',

      'is_plan_lowest_recomended': '',
      'is_plan_mediocre_recomended': '',
      'is_plan_highest_recomended': '',
      'is_plan_most_expensives_recomended': '',

      'selected_carrier': '',
      'host_liquor': '',
      'liquor': '',
      'window_width': '',
      'view_status': '',
      'see_more_status': false,
      'more_page_status': false,
      'thanksRedirectStatus': false,
      'redirectToReferrer': false
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    this.handleChangeRadio = handleChangeRadio.bind(this);
    this.handleBlur = handleBlur.bind(this);
  }



  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    if (localStorage.getItem('thankx')) {
      localStorage.removeItem('thankx');
    }

    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    const localStorageData2 = JSON.parse(localStorage.getItem('oneLoveVenueDetailODE'));
   
    var liquor = '0';
    var host_liquor = '0';
    var is_sold_profitable ='0' ;
    var eventType = '';
    var event_state = '';
    var total_no_people = '';
    var hired_status ='';
    var primary_basis = '';
    var waiver_sub_status = '';
    var additional_insured = '';
    var privateResidence = '';
    var allASIPolicies = 0;
    if (localStorageData2)
    {
      allASIPolicies = 1;
    }
   
    if(localStorageData.current_type != 'vendor'){
      if (localStorageData && localStorageData['alchoalAteventBakit'].alcohol_served && localStorageData['alchoalAteventBakit'].alcohol_served === 'Sold')
        liquor = '1';
      else if (localStorageData && localStorageData['alchoalAteventBakit'].alcohol_served && localStorageData['alchoalAteventBakit'].alcohol_served === 'Served' || localStorageData['alchoalAteventBakit'].alcohol_served === 'BYOB')
        host_liquor = '1';
        if (localStorageData['alchoalAteventBakit'].is_sold_profitable && localStorageData['alchoalAteventBakit'].is_sold_profitable === 'Yes')
        is_sold_profitable = '1';
    }


    if(localStorageData && localStorageData["eventTypeBakit"].event_type) 
        eventType = localStorageData["eventTypeBakit"].event_type;
        
    if(localStorageData && localStorageData["eventStateBakit"].event_state) 
    {
      event_state = localStorageData["eventStateBakit"].event_state;
      if (localStorageData["eventStateBakit"].privateResidence === 'Yes') {
        privateResidence = '1';
      }
      else {
        privateResidence = '0';
      }
    }


    var eventDatesArray = [];
   if(localStorageData['eventDateBakit'].EventSingleDate != '')
      eventDatesArray.push(localStorageData['eventDateBakit'].EventSingleDate);
    
    if(localStorageData['eventDateBakit'].dates != '')
    {
       eventDatesArray.push(localStorageData['eventDateBakit'].dates);
    }else if(localStorageData['eventDateBakit'].event_date != '')
    {
       eventDatesArray.push(this.dateFormte(new Date (localStorageData['eventDateBakit'].event_date['0'])));
    }
    
    this.setState({ host_liquor: host_liquor });
    this.setState({ liquor: liquor });
    if(localStorageData &&  localStorageData['eventPeopleBakit'])
       total_no_people = localStorageData['eventPeopleBakit'].no_of_people;

     if(localStorageData &&  localStorageData['eventAddonBakit']){
       hired_status = localStorageData['eventAddonBakit'].hired_status;
       primary_basis = localStorageData['eventAddonBakit'].primary_basis;
       waiver_sub_status = localStorageData['eventAddonBakit'].waiver_sub_status;
       additional_insured = localStorageData['eventAddonBakit'].additional_insured;
    }
    var e_a_i = '0';
    if(localStorageData['eventAddonBakit'].additional_insured != '' && localStorageData['eventAddonBakit'].additional_insured != '0')
      e_a_i = '1';
      
    this.props.functionLoader.handleUpdateLoader(true);
    var base_url = process.env.REACT_APP_HOME_API_URL;
    if(JSON.parse(localStorage.getItem('venueDetailODE')))
    {
      var api_url = base_url + 'events/get_quote_ajax?eventType=' + eventType + '&eventDates=' + eventDatesArray + '&privateResidence=' + privateResidence + '&isSetVenue=' + '1' + '&event_state=' + event_state + '&number_of_persons=' + total_no_people + '&host_liquor=' + host_liquor + '&liquor=' + liquor + '&noha=' + hired_status + '&w_o_s=' + waiver_sub_status + '&p_a_n_c_b=' + primary_basis + '&e_a_i=' + e_a_i + '&additional_insureds=' + additional_insured + '&is_sold_profitable=' + is_sold_profitable + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    else
    {
      if(localStorageData.current_type != 'vendor')
      {
        if(localStorageData['alchoalAteventBakit'].alcohol_served == 'Sold' && localStorageData['alchoalAteventBakit'].is_sold_profitable == 'No')
        {
          host_liquor = 1;
          liquor = 0;
        }
      }
      var api_url = base_url + 'events/get_quote_ajax?eventType=' + eventType + '&allASIpolicies=' + allASIPolicies + '&eventDates=' + eventDatesArray + '&privateResidence=' + privateResidence + '&event_state=' + event_state + '&number_of_persons=' + total_no_people + '&host_liquor=' + host_liquor + '&liquor=' + liquor + '&noha=' + hired_status + '&w_o_s=' + waiver_sub_status + '&p_a_n_c_b=' + primary_basis + '&e_a_i=' + e_a_i + '&additional_insureds=' + additional_insured + '&is_sold_profitable=' + is_sold_profitable + '&ASIpolicies=' + allASIPolicies +'&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
      
    }

    axios({
      method: "post",
      url: api_url,
      headers: { "content-type": "application/json" },
    })
      .then(result => {
        this.props.functionLoader.handleUpdateLoader(false);
        if (result.status === 200) {
          const offeredPlans = {
            'planArray': result.data,
          }
          localStorageData['plansArray'] = offeredPlans;
          localStorage.setItem("onedayeventv2", JSON.stringify(localStorageData));
          var resDataLenght = Object.keys(result.data).length;
          if (resDataLenght < '1') {
            this.setState({ redirectToReferrer: true });
            localStorage.setItem('thankx', 'Yes');
          }
          const Option = {
            additional_audience: 0,
            broker_fee: 0,
            carrier: "",
            e_a_i: 0,
            id: "",
            limits: {
              0: { gl_limit: "", gl_limit_type: "" },
              1: { gl_limit: "", gl_limit_type: "" },
              2: { gl_limit: "", gl_limit_type: "" },
              3: { gl_limit: "", gl_limit_type: "" },
              4: { gl_limit: "", gl_limit_type: "" },
              count: 0,
            },
            max_limit: "0",
            min_limit: "0",
            noha: 0,
            p_a_n_c_b: 0,
            premium: "0",
            total: 0,
            w_o_s: 0,
          }
          const plan_lowest = result.data.firstOption != undefined ? result.data.firstOption : Option;
          const plan_mediocre = result.data.secondOption != undefined ? result.data.secondOption : Option;
          const plan_highest = result.data.thirdOption != undefined ? result.data.thirdOption : Option;
          const plan_most_expensive = result.data.fourthOption != undefined ? result.data.fourthOption : Option;

          var lowest_limit = [];
          var mediocre_limit = [];
          var highest_limit = [];
          var most_expensive_limit = [];
          
          // Commented by Waqar
//          if (plan_lowest.limits.count == '0' || plan_mediocre.limits.count == '0' || plan_highest.limits.count == '0' || plan_most_expensive.limits.count == '0') {
//            this.setState({ see_more_status: true });
//          }

          //  Store full array
          this.setState({ plan_lowest_full_array: plan_lowest });
          this.setState({ plan_mediocre_full_array: plan_mediocre });
          this.setState({ plan_highest_full_array: plan_highest });
          this.setState({ plan_most_expensive_full_array: plan_most_expensive });
        
          this.setState({ plenght: plan_lowest.limits.count });
          this.setState({ mlenght: plan_mediocre.limits.count });
          this.setState({ hlenght: plan_highest.limits.count });
          this.setState({ elenght: plan_most_expensive.limits.count });
        
          this.setState({ plan_lowest_limits_array: plan_lowest.limits });
          this.setState({ plan_mediocre_limits_array: plan_mediocre.limits });
          this.setState({ plan_highest_limits_array: plan_highest.limits });        
          this.setState({ plan_most_expensive_limits_array: plan_most_expensive.limits });

          this.setState({ plan_lowest_total: plan_lowest.total });
          this.setState({ plan_mediocre_total: plan_mediocre.total });
          this.setState({ plan_highest_total: plan_highest.total });
          this.setState({ plan_most_expensive_total: plan_most_expensive.total });

          this.setState({ extraCharge: plan_lowest.extraCharge });
         
          this.setState({ plan_lowest_carrier: plan_lowest.carrier });
          this.setState({ plan_mediocre_carrier: plan_mediocre.carrier });
          this.setState({ plan_highest_carrier: plan_highest.carrier });
          this.setState({ plan_most_expensive_carrier: plan_most_expensive.carrier });
         

          this.setState({ is_plan_lowest_recomended: plan_lowest.recommended });
          this.setState({ is_plan_mediocre_recomended: plan_mediocre.recommended });
          this.setState({ is_plan_highest_recomended: plan_highest.recommended });
          this.setState({ is_plan_most_expensive_recomended: plan_most_expensive.recommended });

          if (plan_lowest.carrier === 'IH' || plan_mediocre.carrier === 'IH' || plan_highest.carrier === 'IH' || plan_most_expensive.carrier === 'IH'
            && total_no_people === 'Yes' || hired_status === 'Yes' || primary_basis === 'Yes' || waiver_sub_status === 'Yes'
          ) {
            this.setState({ more_page_status: true });
          }

          setTimeout(function () {
            if (this.state.plan_lowest_total !== '' && this.state.plan_lowest_total > 0) {
              if (this.state.plenght > 0) {
                for (var i = 0; i < this.state.plenght; i++) {
                  lowest_limit.push(
                    <Fragment key={i + 1}>
                      <li>
                        <div className="left">{plan_lowest.limits[i]['gl_limit_type'] !== '' ? plan_lowest.limits[i].gl_limit_type : '0'}:</div>
                        <div className="right">{plan_lowest.limits[i].gl_limit !== '' ? plan_lowest.limits[i].gl_limit : '0'} </div>
                      </li>
                    </Fragment>
                  );
                }
              }
              this.setState({ lowest_limit: [lowest_limit] });
              this.setState({ plan_lowest_max_limit: plan_lowest.max_limit });
              this.setState({ plan_lowest_min_limit: plan_lowest.min_limit });
            }

            if (this.state.plan_mediocre_total !== '' && this.state.plan_mediocre_total > 0) {
              if (this.state.mlenght > 0) {
                for (var j = 0; j < this.state.mlenght; j++) {
                  mediocre_limit.push(
                    <Fragment key={j + 1}>
                      <li>
                        <div className="left">{plan_mediocre.limits[j].gl_limit_type}:</div>
                        <div className="right">{plan_mediocre.limits[j].gl_limit} </div>
                      </li>
                    </Fragment>
                  );
                }
              }
              this.setState({ mediocre_limit: mediocre_limit });
              this.setState({ plan_mediocre_max_limit: plan_mediocre.max_limit });
              this.setState({ plan_mediocre_min_limit: plan_mediocre.min_limit });
            }

            if (this.state.plan_highest_total !== '' && this.state.plan_highest_total > 0) {
              if (this.state.hlenght > 0) {
                for (var k = 0; k < this.state.hlenght; k++) {
                  highest_limit.push(
                    <Fragment key={k + 1}>
                      <li>
                        <div className="left">{plan_highest.limits[k].gl_limit_type}:</div>
                        <div className="right">{plan_highest.limits[k].gl_limit} </div>
                      </li>
                    </Fragment>
                  );
                }
              }
              this.setState({ highest_limit: highest_limit });
              this.setState({ plan_highest_max_limit: plan_highest.max_limit });
              this.setState({ plan_highest_min_limit: plan_highest.min_limit });
            }
            if (this.state.plan_most_expensive_total != '' && this.state.plan_most_expensive_total > 0) {

              if (this.state.elenght > 0) {
                for (var l = 0; l < this.state.elenght; l++) {
                  most_expensive_limit.push(
                    <Fragment key={l + 1}>
                      <li>
                        <div className="left">{plan_most_expensive.limits[l].gl_limit_type}:</div>
                        <div className="right">{plan_most_expensive.limits[l].gl_limit} </div>
                      </li>
                    </Fragment>
                  );
                }
              }
              this.setState({ most_expensive_limit: [most_expensive_limit] });
              this.setState({ plan_most_expensive_max_limit: plan_most_expensive.max_limit });
              this.setState({ plan_most_expensive_min_limit: plan_most_expensive.min_limit });
            }

          }.bind(this), 100);
        }

      })
      .catch(error => this.setState({ error: error.message }));
    if (localStorageData && localStorageData['eventAddonBakit']) {
      this.setState({ coverage_limit: localStorageData['eventAddonBakit'].coverage_limit });
      this.setState({ Selected_max_limit: localStorageData['eventAddonBakit'].Selected_max_limit });
      this.setState({ Selected_min_limit: localStorageData['eventAddonBakit'].Selected_min_limit });
      this.setState({ Selected_total: localStorageData['eventAddonBakit'].Selected_total });
      this.setState({ selected_plan_type: localStorageData['eventAddonBakit'].selected_plan_type });
    }

  }

  handlePlanEvent = (plan, event) => {
    this.setState({ selected_plan_type: plan });
    if (plan == 'lowest') {

      let coverage_limit = this.state.plan_lowest_min_limit + '/' + this.state.plan_lowest_max_limit;
      this.setState({ Selected_max_limit: this.state.plan_lowest_max_limit });
      this.setState({ Selected_min_limit: this.state.plan_lowest_min_limit });
      this.setState({ Selected_total: this.state.plan_lowest_total });
      this.setState({ coverage_limit: coverage_limit });
      this.setState({ Selected_dynamic_rates_array: this.state.plan_lowest_limits_array });
      this.setState({ selected_plan_full_array: this.state.plan_lowest_full_array });
      this.setState({ selected_lenght: this.state.plenght });


    } else if (plan == 'mediocre') {

      let coverage_limit = this.state.plan_mediocre_min_limit + '/' + this.state.plan_mediocre_max_limit;
      this.setState({ Selected_max_limit: this.state.plan_mediocre_max_limit });
      this.setState({ Selected_min_limit: this.state.plan_mediocre_min_limit });
      this.setState({ Selected_total: this.state.plan_mediocre_total });
      this.setState({ coverage_limit: coverage_limit });
      this.setState({ Selected_dynamic_rates_array: this.state.plan_mediocre_limits_array });
      this.setState({ selected_plan_full_array: this.state.plan_mediocre_full_array });
      this.setState({ selected_lenght: this.state.mlenght });

    } else if (plan == 'highest') {

      let coverage_limit = this.state.plan_highest_min_limit + '/' + this.state.plan_highest_max_limit;
      this.setState({ Selected_max_limit: this.state.plan_highest_max_limit });
      this.setState({ Selected_min_limit: this.state.plan_highest_min_limit });
      this.setState({ Selected_total: this.state.plan_highest_total });
      this.setState({ coverage_limit: coverage_limit });
      this.setState({ Selected_dynamic_rates_array: this.state.plan_highest_limits_array });
      this.setState({ selected_plan_full_array: this.state.plan_highest_full_array });
      this.setState({ selected_lenght: this.state.hlenght });

    }
    else if (plan == 'most_expensive') {

      let coverage_limit = this.state.plan_most_expensive_min_limit + '/' + this.state.plan_most_expensive_max_limit;
      this.setState({ Selected_max_limit: this.state.plan_most_expensive_max_limit });
      this.setState({ Selected_min_limit: this.state.plan_most_expensive_min_limit });
      this.setState({ Selected_total: this.state.plan_most_expensive_total });
      this.setState({ coverage_limit: coverage_limit });
      this.setState({ Selected_dynamic_rates_array: this.state.plan_most_expensive_limits_array });
      this.setState({ selected_plan_full_array: this.state.plan_most_expensive_full_array });
      this.setState({ selected_lenght: this.state.elenght });


    }
    else if (plan == 'recommended') {

      // let coverage_limit  = this.state.plan_recommended_min_limit+'/'+this.state.plan_recommended_max_limit;
      // this.setState({Selected_max_limit : this.state.plan_recommended_max_limit });
      // this.setState({Selected_min_limit : this.state.plan_recommended_min_limit });
      // this.setState({Selected_total : this.state.plan_recommended_total});
      // this.setState({coverage_limit : coverage_limit});
      // this.setState({Selected_dynamic_rates_array : this.state.plan_recommended_limits_array});
      // this.setState({selected_plan_full_array : this.state.plan_recommended_full_array});
      // this.setState({selected_lenght : this.state.rlenght});

    }
    setTimeout(function () {
      this.handleSubmit();
    }.bind(this), 5
    );
    this.setState({ window_width: $(window).width() });
  }

  dateFormte= (first)=>{
    var year = first.getFullYear();
    var month = first.getMonth()+1;
    var day =first.getDate();
    var dat =  month+ '/' + day + '/' + year;
    return dat
 }
  handleSubmit = (event) => {
    // event.preventDefault();
    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
      const forms_zip = {
        'coverage_limit': this.state.coverage_limit,
        'Selected_max_limit': this.state.Selected_max_limit,
        'Selected_min_limit': this.state.Selected_min_limit,
        'selected_plan_type': this.state.selected_plan_type,
        'Selected_total': this.state.Selected_total,
        'Selected_dynamic_rates_array': this.state.Selected_dynamic_rates_array,
        'selected_plan_full_array': this.state.selected_plan_full_array,
        'selected_lenght': this.state.selected_lenght,
        'carrier': this.state.carrierm,
        'host_liquor': this.state.host_liquor,
        'liquor': this.state.liquor
      };

      localStorageData['eventRatesBakit'] = forms_zip;
      localStorageData = Object.fromEntries(
        Object.entries(localStorageData).filter(([key, value]) => key !== 'plansArray'));
      localStorageData.current_step = parseInt(this.props.questionNum) + 1;
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem('onedayeventv2', localStorageData);

      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {


    if (this.state.thanksRedirectStatus) {
      return (<Redirect to={"/quote/ode/thanks"} />)
    }
     const page = this.props.page;
    if (this.state.redirectToReferrer) {
      const next = this.props.questionNum + 1;
      return (<Redirect to={"/quote/"+page+"/" + next} />)
    }

    this.state.plan_lowest = this.state.plan_lowest_min_limit + '/' + this.state.plan_lowest_max_limit;
    this.state.plan_mediocre = this.state.plan_mediocre_min_limit + '/' + this.state.plan_mediocre_max_limit;
    this.state.plan_highest = this.state.plan_highest_min_limit + '/' + this.state.plan_highest_max_limit;
    this.state.plan_most_expensive = this.state.plan_most_expensive_min_limit + '/' + this.state.plan_most_expensive_max_limit;
    // this.state.plan_recommended = this.state.plan_recommended_min_limit+'/'+this.state.plan_recommended_max_limit;  

    // Slick silder setting 
    var settings = {
      dots: false,
      infinite: false,
      mobileFirst: true,
      centerMode: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots:false,
      responsive: [
        {
          breakpoint: 9999,
          settings: 'unslick',
        },
        {
          breakpoint: 800,
          settings: {
            centerMode: true,
            centerPadding: '0%',
          },
        },
      ],

    };
    // firstPlan Initliaztion for mobile
    var firstPlan =
      <div>
        {this.state.plan_lowest_total && this.state.plan_lowest_total > 0 ?
          <div className={this.state.is_plan_lowest_recomended ? "section maroon select" : "section blue no-best-text"}>
            {this.state.is_plan_lowest_recomended ?
              <Fragment>
                <div className="recommend">Recommended</div>
                <h3><span>Best Plan</span></h3>
              </Fragment>
              : ''}
            <div className="price">{this.state.plan_lowest_min_limit} Million / {this.state.plan_lowest_max_limit} Million</div>
            <ul>
              {this.state.lowest_limit}
            </ul>
            <div className="total">
              <span>Price</span>
              <b>{this.state.plan_lowest_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0)}</b>
            </div>
            {/* <!--total--> */}
            <div className="clearfix"></div>
            <a onClick={this.handlePlanEvent.bind(this, 'lowest')}>Buy Now</a>
            <div className="select-bottom"></div>
          </div>
          : ''}
      </div>
    // secondPlan Initliaztion for mobile
    var secondPlan =
      <div>
        {this.state.plan_mediocre_total && this.state.plan_mediocre_total > 0 ?
          <div className={this.state.is_plan_mediocre_recomended ? "section maroon select" : "section orange no-best-text"}>
            {this.state.is_plan_mediocre_recomended ?
              <Fragment>
                <div className="recommend">Recommended</div>
                <h3><span>Best Plan</span></h3>
              </Fragment>
              : ''}
            <div className="price">{this.state.plan_mediocre_min_limit} Million / {this.state.plan_mediocre_max_limit} Million</div>
            <ul>
              {this.state.mediocre_limit}
            </ul>
            <div className="total">
              <span>Price</span>
              <b>{this.state.plan_mediocre_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0 )}</b>
            </div>
            {/* <!--total--> */}
            <div className="clearfix"></div>
            <a onClick={this.handlePlanEvent.bind(this, 'mediocre')}>Buy Now</a>
            <div className="select-bottom"></div>
          </div>
          : ''}
      </div>;

    // thirdPlan Initliaztion for mobile
    var thirdPlan =
      <div>
        {this.state.plan_highest_total && this.state.plan_highest_total > 0 ?
          <div className={this.state.is_plan_highest_recomended ? "section maroon select" : "section no-best-text green"}>
            {this.state.is_plan_highest_recomended ?
              <Fragment>
                <div className="recommend">Recommended</div>
                <h3><span>Best Plan</span></h3>
              </Fragment>
              : ''}

            <div className="price">{this.state.plan_highest_min_limit} Million / {this.state.plan_highest_max_limit} Million</div>
            <ul>
              {this.state.highest_limit}
            </ul>
            <div className="total">
              <span>Price</span>
              <b>{this.state.plan_highest_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0)}</b>
            </div>
            {/* <!--total--> */}
            <div className="clearfix"></div>
            <a onClick={this.handlePlanEvent.bind(this, 'highest')}>Buy Now</a>
            <div className="select-bottom"></div>
          </div>
          : ''}
      </div>;
    // forthPlan Initliaztion for mobile
    var forthPlan =
      <div>
        {this.state.plan_most_expensive_total && this.state.plan_most_expensive_total > 0 ?
          <div className={this.state.is_plan_most_expensive_recomended ? "section maroon select" : "section navy no-best-text"}>
            {this.state.is_plan_most_expensive_recomended ?
              <Fragment>
                <div className="recommend">Recommended</div>
                <h3><span>Best Plan</span></h3>
              </Fragment>
              : ''}
            <div className="price"> {this.state.plan_most_expensive_min_limit} Million /  {this.state.plan_most_expensive_max_limit} Million</div>
            <ul>
              {this.state.most_expensive_limit}
            </ul>
            <div className="total">
              <span>Price</span>
              <b> {this.state.plan_most_expensive_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0)}</b>
            </div>
            {/* <!--total--> */}
            <div className="clearfix"></div>
            <a onClick={this.handlePlanEvent.bind(this, 'most_expensive')}>Buy Now</a>
            <div className="select-bottom"></div>
          </div>
          : ''}
      </div>;

    var mobilePageContent = [];
    if (this.state.is_plan_lowest_recomended) {
      mobilePageContent = [
        firstPlan,
        secondPlan,
        thirdPlan,
        forthPlan,
      ];
    } else if (this.state.is_plan_mediocre_recomended) {
      mobilePageContent = [
        secondPlan,
        firstPlan,
        thirdPlan,
        forthPlan,
      ];

    } else if (this.state.is_plan_highest_recomended) {
      mobilePageContent = [
        thirdPlan,
        firstPlan,
        secondPlan,
        forthPlan,
      ];

    } else if (this.state.is_plan_most_expensive_recomended) {
      mobilePageContent = [
        forthPlan,
        firstPlan,
        secondPlan,
        thirdPlan,
      ];
    }

    // if(win_width<768){
    var window_width = $(window).width();
    return (
      <Fragment>
        {window_width < 768 ?
          // For Mobile view  
          <section className="quote limit">
             <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
            <div className="content">
              <h2>Select your limits of coverage:</h2>
              {this.state.see_more_status ?
                <a className="few-see-more-plans"><Link to="/onedayeventv2/thanks" replace>See more Plans</Link></a>
                : ''}
              <div className="inner">
                <div className="pricing few ">
                  <Slider {...settings}>
                    {mobilePageContent}
                  </Slider>
                </div>
                {/* <!--pricing--> */}
                <div className="clearfix"></div>
              </div>
              {/* // <!--inner--> */}
            </div>
            {/* <!--content--> */}
          </section>
          :
          // For Desktop view  
          <section className="quote limit">
             <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
            <div className="content">
              <h2>Select your limits of coverage:</h2>
              {this.state.see_more_status ?
                <a className="few-see-more-plans" ><Link to="/onedayeventv2/thanks" replace>See more Plans</Link></a>
                : ''}
              <div className="inner">
                <div className="pricing few ">
                  {this.state.plan_lowest_total && this.state.plan_lowest_total > 0 ?
                    <div className={this.state.is_plan_lowest_recomended ? "section maroon select" : "section blue no-best-text"}>
                      {this.state.is_plan_lowest_recomended ?
                        <Fragment>
                          <div className="recommend">Recommended</div>
                          <h3><span>Best Plan</span></h3>
                        </Fragment>
                        : ''}
                      <div className="price">{this.state.plan_lowest_min_limit} Million / {this.state.plan_lowest_max_limit} Million</div>
                      <ul>
                        {this.state.lowest_limit}
                      </ul>
                      <div className="total">
                        <span>Price</span>
                        <b>{this.state.plan_lowest_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0)}</b>
                      </div>
                      {/* <!--total--> */}
                      <div className="clearfix"></div>
                      <a onClick={this.handlePlanEvent.bind(this, 'lowest')}>Buy Now</a>
                      <div className="select-bottom"></div>
                    </div>
                    : ''}

                  {/* <!--section--> */}
                  {this.state.plan_mediocre_total && this.state.plan_mediocre_total > 0 ?
                    <div className={this.state.is_plan_mediocre_recomended ? "section maroon select" : "section orange no-best-text"}>
                      {this.state.is_plan_mediocre_recomended ?
                        <Fragment>
                          <div className="recommend">Recommended</div>
                          <h3><span>Best Plan</span></h3>
                        </Fragment>
                        : ''}
                      <div className="price">{this.state.plan_mediocre_min_limit} Million / {this.state.plan_mediocre_max_limit} Million</div>
                      <ul>
                        {this.state.mediocre_limit}
                      </ul>
                      <div className="total">
                        <span>Price</span>
                        <b>{this.state.plan_mediocre_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0)}</b>
                      </div>
                      {/* <!--total--> */}
                      <div className="clearfix"></div>
                      <a onClick={this.handlePlanEvent.bind(this, 'mediocre')}>Buy Now</a>
                      <div className="select-bottom"></div>
                    </div>
                    : ''}
                  {/* <!--section--> */}
                  {this.state.plan_highest_total && this.state.plan_highest_total > 0 ?
                    <div className={this.state.is_plan_highest_recomended ? "section maroon select" : "section no-best-text green"}>
                      {this.state.is_plan_highest_recomended ?
                        <Fragment>
                          <div className="recommend">Recommended</div>
                          <h3><span>Best Plan</span></h3>
                        </Fragment>
                        : ''}

                      <div className="price">{this.state.plan_highest_min_limit} Million / {this.state.plan_highest_max_limit} Million</div>
                      <ul>
                        {this.state.highest_limit}
                      </ul>
                      <div className="total">
                        <span>Price</span>
                        <b>{this.state.plan_highest_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0)}</b>
                      </div>
                      {/* <!--total--> */}
                      <div className="clearfix"></div>
                      <a onClick={this.handlePlanEvent.bind(this, 'highest')}>Buy Now</a>
                      <div className="select-bottom"></div>
                    </div>
                    : ''}
                  {/* <!--section--> */}
                  {this.state.plan_most_expensive_total && this.state.plan_most_expensive_total > 0 ?
                    <div className={this.state.is_plan_most_expensive_recomended ? "section maroon select" : "section navy no-best-text"}>
                      {this.state.is_plan_most_expensive_recomended ?
                        <Fragment>
                          <div className="recommend">Recommended</div>
                          <h3><span>Best Plan</span></h3>
                        </Fragment>
                        : ''}
                      <div className="price"> {this.state.plan_most_expensive_min_limit} Million /  {this.state.plan_most_expensive_max_limit} Million</div>
                      <ul>
                        {this.state.most_expensive_limit}
                      </ul>
                      <div className="total">
                        <span>Price</span>
                        <b> {this.state.plan_most_expensive_total+(this.state.extraCharge && this.state.extraCharge != undefined ? this.state.extraCharge : 0)}</b>
                      </div>
                      {/* <!--total--> */}
                      <div className="clearfix"></div>
                      <a onClick={this.handlePlanEvent.bind(this, 'most_expensive')}>Buy Now</a>
                      <div className="select-bottom"></div>
                    </div>
                    : ''}
                </div>
                {/* <!--pricing--> */}
                <div className="clearfix"></div>
              </div>
              {/* // <!--inner--> */}
            </div>
            {/* <!--content--> */}
          </section>

        }
      </Fragment>
    );
  }
} 