// -> Written by Usman 17 March 2021.

// -> Component description
// -> This module is redirting user to Pyament from componet when user is landing using Invoice.


import React, { Component, Fragment, useState } from 'react'
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import NextButton from '../../../../component/NextButton';
import HeaderQuestion from '../../../../../common/component/HeaderQuestion';


import { formatPhoneNumber } from 'react-phone-number-input';


export default class InvoiceLanding extends Component {
    constructor(props) {
        super(props);
        this.state={
            'currentDayEvent' : true,
            'quoteID': '',
        }
        console.log("running");
    }

    componentDidMount() {
        // get invoice id from query string and set to local storage.
        let invoice_id = this.props.match.params.invoiceId;
        localStorage.setItem("invoice_id", invoice_id);
        // Check ASI Plans or not
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "POST",
            url: base_url + 'invoice/index_modified?invoice_id=' + invoice_id,
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    if(result.data.invoice_detail.carrier == 'ASI')
                    {
                        this.setState({'quoteID' : result.data.invoice_detail.lead_id})

                        // check the Event date with current date.
                        var offset = -7;
                        var current_time = new Date(new Date().getTime() + offset * 3600 * 1000);
                        var event_time = new Date(result.data.lead_details.event_date);
                        const current_array = [current_time.getMonth(), current_time.getDate(), current_time.getFullYear()];
                        const event_array = [event_time.getMonth(), event_time.getDate(), event_time.getFullYear()];
                        if (JSON.stringify(current_array) == JSON.stringify(event_array))
                        {
                            // purchasing on the same day as of event
                            this.setState({currentDayEvent : true})
                        }
                        else{
                            // event is not today
                            this.setState({ currentDayEvent: false })

                        }
                    }
                    else
                    {
                        // Not an ASI Policy
                        this.setState({ currentDayEvent: false })
                    }
                }

                }).catch(error => this.setState({ error: error.message }));

    } 

    render() {
        // Initail step number
        const next = 10;
        // This is the page name will used for moving from one page to another.
        const page = "quote";
        // Redirect to Invoice Question container where it will decide which componet should render and 
        // at this stage it will render the invoice payment form.
       
        return (<Redirect to={"/invoice/"+page+"/" + next} />)
        // return(
        //     <Fragment>
        //         {this.state.currentDayEvent ?
        //             <div className="wrapper quote-pg" style={{marginTop:'50px'}}>
        //                 <HeaderQuestion prevQuestion={1} url="/quote/vendor/" lsKey="" ></HeaderQuestion>

        //                 <section className="quote event">
        //                     <div className="content">
        //                         <div className="inner">
        //                             {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-guests.png" alt="" /> */}
        //                             <div className="main-img-container large" style={{ backgroundImage: 'url(/assets/images/quote-guests.png)' }}></div>
        //                             <h4>This quote is expired, Please contact support to renew Plan</h4> <br/>
        //                             <h4>Email :: <span style={{ fontSize: '14px', color:'#0091ea',fontWeight:'initial' }}>support@onedayevent.com</span></h4>
        //                             <h4>Phone :: <span style={{ fontSize: '14px', color:'#0091ea',fontWeight:'initial' }}>408-217-6787</span></h4>
        //                             <br />
        //                             <h4> <span style={{ fontSize: '14px', color: 'black', fontWeight: 'initial' }}>Your Quote ID is <strong style={{ fontSize: '16px' }}>ODE00{this.state.quoteID}</strong></span></h4>

        //                             {/* <form onSubmit={this.handleSubmit} method="post"> */}
                                        
        //                                 <p></p>
        //                                 {/* <p>Put the greatest number of attendees you think will attend if you are unsure so there are no holdups if a claim arises.</p> */}
        //                                 {/* <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} /> */}
        //                             <a href="https://onedayevent.com/"><NextButton btntext="New Quote" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} /></a>

        //                             {/* </form> */}
        //                         </div>
        //                     </div> 
        //                 </section>
        //             </div>
        //     :
        //             <Redirect to={"/invoice/" + page + "/" + next} /> }
        //     </Fragment>
        // );
        
    }
} 