import React, { Component, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom';
import SignupHeader from '../common/component/SignupHeader';
import Loader from '../loader/Loader';
import Partner1 from './partner1';
import Partner2 from './partner2';
import Partner3 from './partner3';

function Signup(props) {

    let number = props.match.params.step;
    const [prev, setPrev] = useState(0);
    const localStorageData = JSON.parse(localStorage.getItem('partner_signup'));
    const handleUpdatePrevious = (number) => {
		setPrev(number);
	}
    const [loader, setLoader] = useState(false);
	const handleUpdateLoader = (ajaxState) =>{
		setLoader(ajaxState);
    }
    
    let component = <Partner1 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }}  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
	
    if(number == 2){
        component = <Partner2 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }}  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;

    }else if (number == 3) {
        component = <Partner3 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }}  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
	
    }
    if (localStorageData && localStorageData.current_step && number > localStorageData.current_step) {
		return (<Redirect to={"/partner-signup/" + localStorageData.current_step} />)
	     }
        return(
            <Fragment>
            <Loader isLoading={loader}></Loader>
            <div className="wrapper quote-pg">
            <SignupHeader prevQuestion={number} url="/partner-signup/" lsKey="onedayevent" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} ></SignupHeader>
			
                {component}
                <footer className="quote-footer"></footer>
            </div>
            </Fragment>
        );
  
}

export default Signup;