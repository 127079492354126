import React, { Component} from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { handleChange, handleChangeRadio, handleBlur } from '../common_function/common_function';
import Inputmask from "inputmask";
import axios from 'axios';
export default class planner2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'email' : '',
            'phone' : '',
            'password' : '',
            'confirm_password' : '',
            'agree_terms' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        Inputmask().mask(document.querySelectorAll("input"));
         }
    handleRadio = (event)=> {
        this.setState({agree_terms: !this.state.agree_terms })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('planner_signup'));
            this.props.functionLoader.handleUpdateLoader(true);
            const forms_zip = { 
                'email' : this.state.email,
                'phone' : this.state.phone,
                'password' : this.state.password,
                'agree_terms' : this.state.agree_terms
            };
            localStorageData['2'] = forms_zip;
            var modal = document.getElementById("messageOverlayModel");
            var base_url = process.env.REACT_APP_API_URL;
            axios({
                    method: "post",
                    url: base_url+'leads/event_planner_signup_ajax',
                    data : {"dataArray" : localStorageData},
                    headers: { "content-type": "application/json" },
                })
                .then(result => {
                    window.scrollTo(0, 0);
                    if (result.data.status === true){   
                       // alert("Data posted successfully");
                        this.setState({redirectToReferrer : true});
                    }else{
                        //alert("Email already exists");
                        modal.style.display = "block";
                        document.getElementById("textDanger").innerHTML= "Email already exists";
                        
                    }
                    setTimeout(function () {
                        modal.style.display = "none";
                        document.getElementById("textDanger").innerHTML= "";
                        document.getElementById("textSuccess").innerHTML= "";
                    }.bind(this), 3000  );
                    this.props.functionLoader.handleUpdateLoader(false);
                })
                .catch(error => this.setState({ error: error.message }));
     
            } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render()
    {
        if (this.state.redirectToReferrer){
           // return (<Redirect to={"/event-planner-signup/"+1} />)
           return (<Redirect to={"/congrats-signup/"} />)
        }
        return(
            <section className="quote signup planners">
            <div className="progress">
              <ul>
                <li className="active"></li>
                <li className="active"></li>
              </ul>
            </div> 
  
            <div className="content">
              <h2>Sign Up for Planners</h2>
              <h4>Finish</h4>
              <div className="inner">
                {/* <!-- <img src="assets/images/quote-guests.png" alt="" /> --> */}
                <form onSubmit={this.handleSubmit}>
                <div className="small-width-container">
                    <div className="form-group">
                        <input type="text" name="email" className="form-control" onChange={this.handleChange} value={this.state.email} placeholder="Email Address" />
                        <div  style={{color:'red'}}>  {this.validator.message('email', this.state.email, 'required|email')}
                     </div>
                    </div>
                    <div className="form-group">
                        <input type="text" name="phone" data-inputmask="'mask': '(999) 999-9999'" className="form-control" onChange={this.handleChange} value={this.state.phone} placeholder="Phone Number" />
                        <div  style={{color:'red'}}>  {this.validator.message('Phone', this.state.phone, 'required|phone')}
                     </div>
                    </div>
                  </div>
                    <div className="small-width-container">
                      <div className="form-group">
                          <input type="password" className="form-control" name="password"  onChange={this.handleChange} value={this.state.password} placeholder="Password" />
                          <div  style={{color:'red'}}>  {this.validator.message('password', this.state.password, 'required')}
                     </div>
                      </div>
                      <div className="form-group">
                          <input type="password" className="form-control" name="confirm_password"  onChange={this.handleChange} value={this.state.confirm_password} placeholder="Confirm password" />
                          <div  style={{color:'red'}}>   {this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, {messages: {in: 'Passwords need to match!'}})}
               
                     </div>
                      </div>
                    </div>
                    <div className="small-width-container flex-cancel">
                      <div className="well">
                          <h4>Terms and condition</h4>
                          <p>Since referral payments are regulated on a state-by-state basis based on the state's insurance regulations, our referral program is not available in Louisiana, Mississippi, Montana, New Mexico, New York, Washington and West Virginia right now. This means that we can't pay you for referrals if you live in one of these states. Our employees may not participate in our referral program.</p>
                          <p>In the states where our referral program is available, we will pay you $5 per referral up to a maximum of $100, if you cross that amount you may sign up with our Partner Program to be eligible for more funds. You must use your personalized referral link to refer your friends in order to receive payment. You must also have a PayPal account so we may transfer you the funds, or accept checks that we can mail to you at the designated pay periods.</p>
                          <p>You can refer a friend or spouse and get paid for it. You cannot “refer” yourself. When referring someone, you can tell them about One Day Event and our stellar customer service abilities, but you cannot talk about the insurance coverage, amounts, or rates. If you discuss the policy to someone you are referring, you will not be eligible to receive payment. You must leave it to us as the licensed agent to help your friends with any questions they have about the insurance, coverage, premiums, or policy details.</p>
                          <p>You get paid when your friend qualifies for a quote, whether they choose to purchase a policy or not. We do not pay for referrals when your friend or spouse does not qualify for an offer, or if this is not an actual event. The event must be booked with a date, time, and venue to be considered for the referral program. If the policy is cancelled for any reason you will not receive any referral fees.</p>
                          <p>Once a friend applies for coverage and gets a quote, they can’t be referred again by a different person. The first referral link the friend uses is the only one that counts.</p>
                          <p>You cannot publish or distribute your referral link on websites or blogs with the primary purpose of soliciting referral fees. You cannot spam anyone with referral invitations. This includes mass emailing, texting, or messaging people you do not know or using automated systems or bots through any channel to distribute your referral link. You are prohibited from paying to advertise your referral link. You also cannot pay your friends to apply for coverage or share your referral fee with them.</p>
                          <p>We may make changes to, suspend or terminate our referral program or your ability to participate in our referral program at any time for any reason. We reserve the right to investigate all referral activities and to suspend accounts or not pay fees if we notice any activity that we believe is abusive, fraudulent, or in violation of these Referral Program Terms or our website Terms.</p>
                      </div>
                      <div className="checkbox checkbox-primary">
                          <input id="checkbox2" className="styled checkbox2" type="checkbox" onChange={this.handleRadio} name="agree_terms" value={this.state.agree_terms} />
                          <label htmlFor="checkbox2">
                              I accept and read the terms & conditions
                          </label>
                      </div>
                     </div>
                     <div  style={{color:'red'}}>  {this.validator.message('Terms', this.state.agree_terms, 'required')}
                     </div>
                    <button type="submit" className="btn btn-maroon">Submit</button>
                </form>
                
              </div> 
              {/* <!--inner--> */}
            </div>
             {/* <!--content--> */}
          </section> 
         
          );
    }
}