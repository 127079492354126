import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router-dom';
import BackButton from '../../business/component/BackButton';
import MessageModel from '../../Error/MessageModel';
import Inputmask from "inputmask";
import $ from 'jquery';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'numbertooltipVisible':false,
            'redirectToReferrer' : false,
            'popupPhoneVisible':false,
            'isAcceptCookie' : ''
        }
      //  this.toggleNumbertooltip = this.toggleNumbertooltip.bind(this);

         }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.$('.pop').popover();
        if(localStorage.getItem('isAcceptCookie')){
            this.setState({isAcceptCookie: localStorage.getItem('isAcceptCookie')});
           }
           Inputmask().mask(document.querySelectorAll("input"));
    }

    togglePhonePopup(event) {
        event.preventDefault();
        event.stopPropagation();
        setTimeout(function(){
        window.$('.phonepopup').css("display", "block");
        }.bind(this),5
        );
        this.setState({popupPhoneVisible: !this.state.popupPhoneVisible});
     }
   
    handleBack = () => 
    {
        if(typeof(this.props.prevQuestion) == "undefined")
            alert('No previous question');
        else
            this.setState({redirectToReferrer : true});
    }
    handleDomToggle= (e)=>{
        $('[data-toggle=popover]').each(function () {
         if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.save-popover').has(e.target).length === 0) {
         
             window.$('.save-popover').css("display", "none");
         }
       });
      
       //this.setState({popupVisible: false});
      
     }
    handleCookie = (type, event)=> {
        this.setState({isAcceptCookie: type});
        localStorage.setItem('isAcceptCookie', type);
    }
    
    render() {
        if (this.state.redirectToReferrer)
        {
            const queston_number = this.props.prevQuestion-1;
            this.setState({redirectToReferrer : false});
			return (<Redirect to={this.props.url+queston_number} />)
        }
        document.addEventListener('click', this.handleDomToggle);
        return (
            <Fragment>
                  <MessageModel></MessageModel>
                  {!this.state.isAcceptCookie ? 
              <div className="cache-msg">
          <p>One Day Event uses cookies to improve your experience on our site. Cookies enable you to enjoy our sites features like social sharing and saving quotes. You can ﬁnd out more about our cookie and privacy policy on our Privacy page. By continuing to use our site, you consent to use our cookies.</p>
          <a className="cross" onClick={this.handleCookie.bind(this, 'No')}><img src="assets/images/cache-cross.png" alt="" /></a>
          <a className="btn btn-maroon" onClick={this.handleCookie.bind(this, 'Yes')}>I accept</a>
        </div>
         : ''}
              <header>
                <div className="container">   
                {this.props.prevQuestion != 1 && this.props.prevQuestion != undefined  ?  
                   <a className="back-icon"  style={{ cursor: 'pointer' }} onClick={this.handleBack}><img src="/assets/images/icon-back-circle.svg" alt="" />  <BackButton lsKey="onedayevent" stepNumber={this.props.prevQuestion} /></a>
                   : ''}
                   <div className="logo">
                   <a  href="/."><img src="/assets/images/logo-img.svg" alt="" /></a>
                </div>
                <div className="menu-right  hidden-xs">
                <ul> 
                    {/* <li><a className="btn btn-default pop" onClick={this.toggleNumbertooltip} data-container="body" data-toggle="popover" data-placement="bottom" data-placement="bottom" data-html="true" data-content=" <a href='tel:408-217-6787'>Tel:408-217-6787</a><br/>
                 <a href='mailto:support@onedayevent.com'>Mail:support@onedayevent.com </a>"><img src="/assets/images/icon-phone-header.svg" alt="" />Phone</a></li> */}
                 <li>
             <a  onClick={this.togglePhonePopup.bind(this)} className="btn  btn-default quote-popover " data-placement="bottom" data-toggle="popover" data-container="body" data-placement="bottom" type="button" data-html="true"   href="#">Phone</a>
               <div className="popover save-popover phonepopup" style={{display:'none'}}>
                      <div className="top-arrow"></div>
                      <div className="phone">
                        <a href="tel:+1408-217-6787"><img src="/assets/images/phone-call.svg" alt=""/> 408-217-6787</a>
                        <a href="mailto:support@onedayevent.com"><img src="/assets/images/phone-email.svg" alt=""/>support@onedayevent.com </a>
                      </div>
                  </div>
                 </li>
                </ul>
                </div>
                
               <div className="menu-right  visible-xs">
                <ul>
                <li>
                <a  onClick={this.togglePhonePopup.bind(this)} className="quote-popover " data-placement="bottom" data-toggle="popover" data-container="body" data-placement="bottom" type="button" data-html="true"   href="#"><img src="/assets/images/icon-phone-header.svg" alt=""/></a>
               <div className="popover save-popover phonepopup" style={{display:'none'}}>
                      <div className="top-arrow"></div>
                      <div className="phone">
                        <a href="tel:+1408-217-6787"><img src="/assets/images/phone-call.svg" alt=""/> 408-217-6787</a>
                        <a href="mailto:support@onedayevent.com"><img src="/assets/images/phone-email.svg" alt=""/>support@onedayevent.com </a>
                      </div>
                  </div>
                </li>
                    {/* <li><a className="pop" onClick={this.toggleNumbertooltip} data-container="body" data-toggle="popover" data-placement="bottom" data-placement="bottom" data-html="true" data-content=" <a href='tel:408-217-6787'>Tel:408-217-6787</a><br/>
                 <a href='mailto:support@onedayevent.com'>Mail:support@onedayevent.com </a>"><img src="/assets/images/icon-phone-header.svg" alt="" /></a></li> */}
                </ul>
                </div>
        
          </div>
        </header>
                </Fragment>
            );
        }
    }
    export default Header;