import React, { Component ,Fragment} from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { handleChange, handleBlur } from '../common_function/common_function';
import axios from 'axios';


export default class planner1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'venue_name': '',
      'venue_street': '',
      'venue_city': '',
      'venue_state': '',
      'venue_zip': '',
      'countries':'',
      'venue_max_capacity': '',
      'redirectToReferrer': false
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    this.handleBlur = handleBlur.bind(this);
  }

  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);

    const localStorageData = JSON.parse(localStorage.getItem('venue_signup'));
    if (localStorageData && localStorageData['2']) {
      this.setState({ venue_name: localStorageData['2'].venue_name });
      this.setState({ venue_street: localStorageData['2'].venue_street });
      this.setState({ venue_city: localStorageData['2'].venue_city });
      this.setState({ venue_state: localStorageData['2'].venue_state });
      this.setState({ venue_zip: localStorageData['2'].venue_zip });
      this.setState({ venue_max_capacity: localStorageData['2'].venue_max_capacity });
    }
    var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url + 'get_quote/get_states_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({ countries: result.data.countries });
                }
                this.props.functionLoader.handleUpdateLoader(false);
            })
            .catch(error => this.setState({ error: error.message }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem('venue_signup'));
      const forms_info = {
        'venue_name': this.state.venue_name,
        'venue_street': this.state.venue_street,
        'venue_city': this.state.venue_city,
        'venue_state': this.state.venue_state,
        'venue_zip': this.state.venue_zip,
        'venue_max_capacity': this.state.venue_max_capacity,
      };
      localStorageData['2'] = forms_info;
      localStorageData.current_step = 3;
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem('venue_signup', localStorageData);

      // var url_forms_info = { 'current_step': 3, '2': forms_info };
      //     url_forms_info = JSON.stringify(url_forms_info);
      //     localStorage.setItem('venue_signup', url_forms_info);
      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {

    if (this.state.redirectToReferrer) {
      //const next = this.props.questionNum+1;
      return (<Redirect to={"/venue-signup/" + 3} />)
    }
    var countries = [];
        let index_number = 0;
        var length = this.state.countries && this.state.countries.length > 0 ? this.state.countries.length : 0;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index_number]['name']}>{this.state.countries[index_number]['name']}</option>
                </Fragment>
            );
            index_number++;
        }

    return (

      <section className="quote signup venue">
        <div className="progress">
          <ul>
            <li className="active"></li>
            <li className="active"></li>
            <li></li>
          </ul>
        </div>
        <div className="content">
          <h2>Sign Up for Venue</h2>
          <h4>Personal Information</h4>
          <div className="inner">
            {/* <img src="assets/images/quote-guests.png" alt="" />  */}
            <form onSubmit={this.handleSubmit}>
              <div className="small-width-container">
                <div className="form-group">
                  <input type="text" className="form-control" name="venue_name" onChange={this.handleChange} value={this.state.venue_name} autoFocus={true} placeholder="Venue Name" />
                  <div style={{ color: 'red' }}>  {this.validator.message('venue_name', this.state.venue_name, 'required')}
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="venue_street" onChange={this.handleChange} value={this.state.venue_street} placeholder="Venue Street" />
                  <div style={{ color: 'red' }}>  {this.validator.message('venue_street', this.state.venue_street, 'required')}
                  </div>
                </div>
              </div>
              <div className="small-width-container">
                <div className="form-group">
                  <input type="text" className="form-control" name="venue_city" onChange={this.handleChange} value={this.state.venue_city} autoFocus={true} placeholder="Venue City" />
                  <div style={{ color: 'red' }}>  {this.validator.message('venue_city', this.state.venue_city, 'required')}
                  </div>
                </div>
                <div className="form-group">
                  <select type="text" className="form-control" name="venue_state" onChange={this.handleChange} value={this.state.venue_state} placeholder="Venue State" >
                  <option value="">Select State</option>
                     {countries}
                  </select>
                  <div style={{ color: 'red' }}>  {this.validator.message('venue_state', this.state.venue_state, 'required')}
                  </div>
                </div>
              </div>
              <div className="small-width-container">
                <div className="form-group">
                  <input type="number" className="form-control" name="venue_zip" onChange={this.handleChange} value={this.state.venue_zip} autoFocus={true} placeholder="Venue Zip" />
                  <div style={{ color: 'red' }}>  {this.validator.message('venue_zip', this.state.venue_zip, 'required')}
                  </div>
                </div>
                <div className="form-group">
                  <input type="number" className="form-control" name="venue_max_capacity" onChange={this.handleChange} value={this.state.venue_max_capacity} placeholder="Venue Max Capacity" />
                  <div style={{ color: 'red' }}>  {this.validator.message('venue_max_capacity', this.state.venue_max_capacity, 'required')}
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-maroon">Next</button>
            </form>
            {/* <a href="signup-partners2.html" className="btn btn-maroon">Next</a> */}
          </div>
        </div>
        {/* <!--content--> */}
      </section>

    );
  }
}