export function handleChange (event)
{
    event.preventDefault();
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name] : value
    });
}

export function validator ()
{
    return {
        validators: {
            alpha_space_apostrophe_dashes: {  // name the rule
            message: 'The :values may only contain letters, apostrophes, dashes and spaces.',
            rule: (val, params, validator) => {
                var charRegExp = /^[a-zA-Z '-]+$/;
                return val.search(charRegExp) == 0;
            },
            messageReplace: (message, params) => message.replace(':values', params[0]),  // optional
            required: true  // optional
          },
          alpha_space_apostrophe_dot:{
            message: 'The :values may only contain letters, apostrophes, dashes and dot.',
            rule: (val, params, validator) => {
                var charRegExp = /^[a-zA-Z '-.]+$/;
                return val.search(charRegExp) == 0;
            },
            messageReplace: (message, params) => message.replace(':values', params[0]),  // optional
            required: true  // optional
          }
        }
      };
}

export const focusInput = (component) => {
  if (component) {
      component.focus();
  }
};

export function handleBlur (event) {
    event.preventDefault();
    const name = event.target.name;
    this.validator.showMessageFor(name);
    var element = document.getElementsByName(name);
    if(this.validator.fieldValid(name))
        element[0].classList.remove("error-border");    
    else
        element[0].classList.add("error-border");
    this.forceUpdate();

}

export function handleChangeRadio (event)
{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name] : value
    });
}

export function handleChangeCheckBox (event)
{
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(value === true)
    {
        value=target.value;
    }
    else
    {
        value=false;
    }
    this.setState({
        [name] : value
    });
}

export function handleChangeAmount (event) {
    event.preventDefault();
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var doller_sign = value.replace(/\$/g, '');
    var result = doller_sign.replace(/,/g, "");
    value = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    value = '$'+value;
    this.setState({
        [name] : value
    });
}
export function noOfPeopleCost(value)
{
  var cost = 0;
  if(value >= 1 && value <=100){
      cost = 62 * 1;
  }
  else if(value >= 101 && value <=250){
      cost = 62 * 1;
  }
  else if(value >= 251 && value <=500){
      cost = 62 * 1;
  }
  else if(value >= 501 && value <=750){
      cost = 62 * 2;
  }
  else if(value >= 751 && value <=1000){
      cost = 62 * 2;
  }
  else if(value >= 1001 && value <=1500){
      cost = 62 * 3;
  }
  else if(value >= 1501 && value <=3000){
      cost = 62 * 6;
  }
  else if(value >= 3001 && value <=6000){
      cost = 62 * 12;
  }
  else if(value >= 6001 && value <=12000){
      cost = 62 * 24;
  }
  else if(value >= 12001 && value <=15000){
      cost = 62 * 30;
  }
  return cost;
}

export function noOfPeopleLiquorCost(value)
{
  var cost = 0;
  if(value >= 1 && value <=100){
      cost = 5 * 1;
  }
  else if(value >= 101 && value <=250){
      cost = 5 * 1;
  }
  else if(value >= 251 && value <=500){
      cost = 5 * 1;
  }
  else if(value >= 501 && value <=750){
      cost = 5 * 2;
  }
  else if(value >= 751 && value <=1000){
      cost = 5 * 2;
  }
  else if(value >= 1001 && value <=1500){
      cost = 5 * 3;
  }
  else if(value >= 1501 && value <=3000){
      cost = 5 * 6;
  }
  else if(value >= 3001 && value <=6000){
      cost = 5 * 12;
  }
  else if(value >= 6001 && value <=12000){
      cost = 5 * 24;
  }
  else if(value >= 12001 && value <=15000){
      cost = 5 * 30;
  }
  return cost;
}