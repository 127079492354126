// written by Usman Ghani
// Component Description
// This component is replicate of ODECertificate. It runs at last when user is buying using invoice and ends up all 
// the steps so in the end we should show him a certificate.

import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

export default class InvoiceCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_name: "",
      event_date: "",
      email: "",
      event_singleDate: "",
      event_coverage_limit: "",
      redirectToReferrer: false,
    };
  }
  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    const localStorageData = JSON.parse(localStorage.getItem("odeCertificateInvoice"));
    // <!-- Global site tag (gtag.js) - Google Ads: 1071797610 -->
    //  .........................................................
    window.scrollTo(0, 0);
    if (localStorageData) {
      this.setState({ event_name: localStorageData.event_name });
      this.setState({ email: localStorageData.email });
      this.setState({ event_date: localStorageData.event_date });
      

      let max = localStorageData.max_limit;
      let min = localStorageData.min_limit;
      let converage = min + " / " + max;
      this.setState({ event_coverage_limit: converage });
    }
  }

  dateFormat = (mydate) => {
    var month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][mydate.getMonth()];
    var date = month + " " + mydate.getDate() + ", " + mydate.getFullYear();
    return date;
  };

  render() {
    if (this.state.redirectToReferrer) {
      console.log("*** certificate redirect is running");
      const page = this.props.page;
      return <Redirect to={"/quote/" + 'ode' + "/" + 1} />;
    }
    var email = new Buffer(this.state.email).toString("base64");
    var base_url = process.env.REACT_APP_API_URL;
    var loginLink = base_url + "users/setup_new_password/" + email;
    return (
      <Fragment>
        <section className="quote certificate">
          <div className="content">
            <div className="inner">
              <img
                className="animate__animated animate__fadeInUp"
                src="/assets/images/certificate.png"
                alt=""
              />
              <h2>Congrats! </h2>
              <p>
                Your Certificate will be emailed to you & uploaded to your
                portal in the next 24 hours!
              </p>
              <div className="h-button three">
                <dl>
                  <dt>Event Name</dt>
                  <dd>{this.state.event_name}</dd>
                </dl>
                <dl>
                  <dt>Event Date</dt>
                  <dd>
                    {this.state.event_singleDate
                      ? this.state.event_singleDate + ","
                      : ""}
                    {this.state.event_date ? this.state.event_date : ""}
                  </dd>
                </dl>
                <dl>
                  <dt>Limits of Coverage</dt>
                  <dd>$ {this.state.event_coverage_limit} million</dd>
                </dl>
              </div>

              <div className="h-button">
                <a href="/blogs" className="btn btn-maroon">
                  Blog
                </a>
                <a href={`${loginLink}`} className="btn btn-maroon btn-brand">
                  Your Portal
                </a>
              </div>
            </div>
            {/* <!--inner--> */}
          </div>
          {/* <!--content--> */}
        </section>
      </Fragment>
    );
  }
}
