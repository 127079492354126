import React, { Component, Fragment} from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { handleChange, handleBlur } from '../common_function/common_function';
import axios from 'axios';

export default class MainContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'npn_no' : '',
            'resident_no' : '',
            'npn_no' : '',
            'resident_licence_state' : '',
            'additional_licence_state' : '',
            'have_eo_licence_no' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    
    componentDidMount() {
      const prev = this.props.questionNum - 1;
      this.setState({ prevQuestion: prev });
      this.props.functionPrev.handleUpdatePrevious(prev);
      
          const localStorageData = JSON.parse(localStorage.getItem('partner_signup'));
        if (localStorageData && localStorageData['2']) {
            this.setState({ npn_no: localStorageData['2'].npn_no });
            this.setState({ resident_no: localStorageData['2'].resident_no });
            this.setState({ resident_licence_state: localStorageData['2'].resident_licence_state });
            this.setState({ additional_licence_state: localStorageData['2'].additional_licence_state });
            this.setState({ have_eo_licence_no: localStorageData['2'].have_eo_licence_no });
        }

        this.props.functionLoader.handleUpdateLoader(true);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url + 'get_quote/get_states_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({ countries: result.data.countries });
                }
                this.props.functionLoader.handleUpdateLoader(false);
            })
            .catch(error => this.setState({ error: error.message }));
    }
    handleRadio= (type, event)=>{
        this.setState({have_eo_licence_no : type});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('partner_signup'));
            const forms_zip = { 
                'npn_no' : this.state.npn_no,
                'resident_no' : this.state.resident_no,
                'resident_licence_state' : this.state.resident_licence_state,
                'additional_licence_state' : this.state.additional_licence_state,
                'have_eo_licence_no' : this.state.have_eo_licence_no,

            };
            localStorageData[this.props.questionNum] = forms_zip;
            localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('partner_signup', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render()
    {
       if (this.state.redirectToReferrer){
        return (<Redirect to={"/partner-signup/"+3} />)
    }

    var countries = [];
        let index_number = 0;
        var length = this.state.countries && this.state.countries.length > 0 ? this.state.countries.length : 0;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index_number]['id']}>{this.state.countries[index_number]['name']}</option>
                </Fragment>
            );
            index_number++;
        }
        return(
         <section className="quote signup">
        <div className="progress">
          <ul>
            <li className="active"></li>
            <li className="active"></li>
            <li></li>
          </ul>
        </div> 

        <div className="content">
          <h2>Sign Up for Partners</h2>
          <h4>Licensing</h4>
          <div className="inner">
            {/* <!-- <img src="assets/images/quote-guests.png" alt="" /> --> */}
            <form onSubmit={this.handleSubmit}>
                <div className="small-width-container">
                  <div className="form-group">
                      <input type="text" className="form-control" name="resident_no"  onChange={this.handleChange} value={this.state.resident_no} placeholder="Resident License Number" />
                      <div  style={{color:'red'}}>  {this.validator.message('resident_licence_number', this.state.resident_no, 'required')}
                     </div>
                  </div>
                  <div className="form-group">
                      {/* <input type="text" className="form-control" name="resident_licence_state"  onChange={this.handleChange} value={this.state.resident_licence_state} placeholder="Resident License State" /> */}
                      <select  className="form-control" name="resident_licence_state" onChange={this.handleChange} value={this.state.resident_licence_state} placeholder="Resident License State" >
                                            <option value="">Select Resident License State</option>
                                            {countries}
                                        </select>
                      <div  style={{color:'red'}}>  {this.validator.message('resident_licence_state', this.state.resident_licence_state, 'required')}
                     </div>
                  </div>
                </div>

             {/* <div className="small-width-container">
                <div className="form-group">
                      <input type="text" className="form-control" name="npn_no"  onChange={this.handleChange} value={this.state.npn_no} placeholder="NPN#" />
                      <div  style={{color:'red'}}>  {this.validator.message('NPN number', this.state.npn_no, 'required')}
                     </div>
                  </div>
                  <div className="form-group">
                      <select  className="form-control" name="additional_licence_state" onChange={this.handleChange} value={this.state.additional_licence_state} placeholder="Additional License State" >
                                            <option value="">Select Additional License State</option>
                                            {countries}
                                        </select>
                   </div>
                </div> */}
                <div className="small-width-container yes-no">
                  <div className="radio-group">
                    <p>DO YOU HAVE E&O COVERAGE? YES/NO</p>
                        <div className="h-button">
                            <a className={this.state.have_eo_licence_no === 'Yes' ? 'btn btn-maroon2' : 'btn btn-primary btn-border'} onClick={this.handleRadio.bind(this, 'Yes')}>Yes</a>
                            <a className={this.state.have_eo_licence_no === 'No' ? 'btn btn-maroon2' : 'btn btn-primary btn-border'} onClick={this.handleRadio.bind(this, 'No')} >No</a>

                        </div>
                      <div  style={{color:'red'}}>  {this.validator.message('E&O license', this.state.have_eo_licence_no, 'required')}
                     </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-maroon">Next</button>
            </form>
          </div> 
        </div> 
        {/* <!--content--> */}
      </section> 
    
          
          );
    }
}