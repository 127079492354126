import React, { Component, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom';
import SignupHeader from '../common/component/SignupHeader';
import Loader from '../loader/Loader';
import Venue1 from './venue1';
import Venue2 from './venue2';
import Venue3 from './venue3';


function Signup(props) {

    let number = props.match.params.step;
    const [prev, setPrev] = useState(0);
    const localStorageData = JSON.parse(localStorage.getItem('venue_signup'));
    const handleUpdatePrevious = (number) => {
		setPrev(number);
	}
    const [loader, setLoader] = useState(false);
	const handleUpdateLoader = (ajaxState) =>{
		setLoader(ajaxState);
    }
    
    let component = <Venue1 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
	
    if(number == 2){
        component = <Venue2 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }}  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;

    }
    if(number == 3){
        component = <Venue3 questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }}  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;

    }
    if (localStorageData && localStorageData.current_step && number > localStorageData.current_step) {
		return (<Redirect to={"/venue-signup/" + localStorageData.current_step} />)
	     }

        return(
            <Fragment>
            <Loader isLoading={loader}></Loader>
            <div className="wrapper quote-pg">
            <SignupHeader prevQuestion={number} url="/venue-signup/" lsKey="onedayevent" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} ></SignupHeader>
			    {component}
                <footer className="quote-footer"></footer>
            </div>
            </Fragment>
        );
}

export default Signup;