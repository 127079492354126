import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import Inputmask from "inputmask";
import SimpleReactValidator from 'simple-react-validator';

export default class ODE8 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'additional_insured': '0',
      'waiver_sub_status': '0',
      'hired_status': '0',
      'hired_msg' : true,
      'primary_basis': '0',
      'insurance_claim': '0',
      'add_vendors': '',
      'selcted_noha_type': '',
      'redirectToReferrer': false,
      'isSetVenue' : false
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    this.handleChangeRadio = handleChangeRadio.bind(this);
    this.handleBlur = handleBlur.bind(this);
  }

  componentDidMount() {
    // if coming form API then show only data (readOnly)
    if (localStorage.getItem('oneLoveVenueDetailODE')) {
      this.setState({ isSetVenue: true });
    }

    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    window.$('.pop').popover();
    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    const localStorageData2 = JSON.parse(localStorage.getItem('venueDetailODE'));
    if (localStorageData && localStorageData['eventAddonBakit'] || localStorageData2 && localStorageData2['venue_details']) {
      if(localStorageData2 && localStorageData2['venue_details'])
      {
        
        this.setState({ 'waiver_sub_status': localStorageData2['venue_details'].waiver_sub_status }); 
        this.setState({ 'additional_insured': localStorageData2['venue_details'].additional_insured });
        this.setState({'isSetVenue' : true });
      }
      else
      {

        this.setState({ 'waiver_sub_status': localStorageData['eventAddonBakit'].waiver_sub_status });
        this.setState({ 'hired_status': localStorageData['eventAddonBakit'].hired_status });
        this.setState({ 'primary_basis': localStorageData['eventAddonBakit'].primary_basis });
        this.setState({ 'additional_insured': localStorageData['eventAddonBakit'].additional_insured });
      }
      if (localStorageData['eventAddonBakit'].readOnly)
      {
        this.setState({ isSetVenue: true });
      }
    }
    if (localStorageData && localStorageData['eventTypeBakit'] && localStorageData['eventTypeBakit'].selcted_noha_type) {
      this.setState({ 'selcted_noha_type': localStorageData['eventTypeBakit'].selcted_noha_type });
    }

    if(localStorage.getItem('thankx')){
      localStorage.removeItem('thankx');
    }
  }
  handWaiverEvent = (event) => {
    this.setState({ waiver_sub_status: event })
  }
  handHiredEvent = (event) => {
    if(this.state.hired_msg && event == '1')
    {
      this.setState({hired_msg : false});
    }
    else{
      this.setState({ hired_status: event });
    }
  }
  handPrimaryEvent = (event) => {
    this.setState({ primary_basis: event });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
      const forms_zip = {
        'waiver_sub_status': this.state.waiver_sub_status,
        'hired_status': this.state.hired_status,
        'primary_basis': this.state.primary_basis,
        'additional_insured': this.state.additional_insured

      };
      localStorageData['eventAddonBakit'] = forms_zip;
      localStorageData.current_step = parseInt(this.props.questionNum) + 1;
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem('onedayeventv2', localStorageData);
      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    const page = this.props.page;
    if (this.state.redirectToReferrer) {
      const next = this.props.questionNum + 1;
      return (<Redirect to={"/quote/"+page+"/" + next} />)
    }
    return (
      <Fragment>

        <section className="quote event policy-addons">
           <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
          <div className="content">
            <h2>Does your venue require any of these?</h2>
            <div className="inner">
              <form onSubmit={this.handleSubmit} method="post">
              <h4>Waiver of Subrogation <i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="This prevents a carrier from recovering money paid on a claim from a third party. "></i></h4>
                <div className="small-width-container two-buttons">
                  <div className="form-group">
                    <button disabled={this.state.isSetVenue} type="button" className={this.state.waiver_sub_status === '1' ? 'btn btn-primary active btn-border' : 'btn btn-primary'} onClick={this.handWaiverEvent.bind(this, '1')}>Yes</button>
                  </div>
                  <div className="form-group">
                    <button disabled={this.state.isSetVenue} type="button" className={this.state.waiver_sub_status === '0' ? 'btn btn-primary active btn-border' : 'btn btn-primary'} onClick={this.handWaiverEvent.bind(this, '0')}>No</button>
                  </div>
                </div>
                <div className="form-group" style={{ color: 'red' }}>
                  {this.validator.message('waiver_sub_status', this.state.waiver_sub_status, 'required')}
                </div>
                <h4>Primary/Non-contributory Basis <i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="This makes your insurance the primary coverage so it pays claims first before anyone else's policy has to"></i></h4>

                <div className="small-width-container two-buttons">
                  <div className="form-group">
                    <button disabled={this.state.isSetVenue} type="button"  className={this.state.primary_basis === '1' ? 'btn btn-primary active btn-border' : 'btn btn-primary'} onClick={this.handPrimaryEvent.bind(this, '1')}>Yes</button>
                  </div>
                  <div className="form-group">
                    <button disabled={this.state.isSetVenue} type="button" className={this.state.primary_basis === '0' ? 'btn btn-primary active btn-border' : 'btn btn-primary'} onClick={this.handPrimaryEvent.bind(this, '0')}>No</button>
                  </div>
                </div>
                <div className="form-group" style={{ color: 'red' }}>
                  {this.validator.message('Primary/Non-contributory Basis', this.state.primary_basis, 'required')}
                </div>
                {/* {this.state.selcted_noha_type != '' && this.state.selcted_noha_type != 'N/A' ? 
                <Fragment> */}
                <h4>Hired/Non-owned Auto <i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="This covers any hired automobiles involved with the event. "></i></h4>
                {!this.state.hired_msg ? <p style={{marginBottom:'10px'}}><strong>Warning:</strong> <br /> Non-Owned/Hired Auto is subject to approval and will affect your quote & premium. If this is required, select Yes</p> : ''}
                <div className="small-width-container two-buttons">
                  <div className="form-group" style={{ color: 'red' }}>
                    <button disabled={this.state.isSetVenue} type="button" style={{ background: this.state.hired_msg ? 'transparent' : 'white', }} className={this.state.hired_status === '1' ? 'btn btn-primary active btn-border' : 'btn btn-primary'} onClick={this.handHiredEvent.bind(this, '1')}>Yes</button>
                  </div>
                  <div className="form-group">
                    <button disabled={this.state.isSetVenue} type="button" className={this.state.hired_status === '0' ? 'btn btn-primary active btn-border' : 'btn btn-primary'} onClick={this.handHiredEvent.bind(this, '0')}>No</button>
                  </div>
                </div>
                <div className="form-group" style={{ color: 'red' }}>
                  {this.validator.message('hiredHired/Non-owned Auto_status', this.state.hired_status, 'required')}
                </div>
                {/* </Fragment> 
                 : '' } */}
               
                <h4>Additional Insured <i className="pop" data-placement="bottom" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-content="This is an eligible person, place, or business included in your policy. Vendors cannot be additional insured’s."></i></h4>
               
                <div className="form-group relative">
                  <input disabled={this.state.isSetVenue} type="number"  min="0"   name="additional_insured" value={this.state.additional_insured} onChange={this.handleChange} className="form-control" placeholder="0" />
                  {/* <a href="javascript:void(0)"><i className="fa fa-sort"></i></a> */}
                  <div className="form-group" style={{ color: 'red' }}>
                  {this.validator.message('additional_insured', this.state.additional_insured, 'numeric|min:0,num')}
                </div>
                </div>
                <p> Only select these if you or your venue requires them, usually they are not needed.</p>
                               
                <NextButton btntext="Next" prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
} 