import React, { Component, Fragment, useState } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange,  handleBlur } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Inputmask from "inputmask";
import axios from 'axios';
import $ from 'jquery';

function validate(first_name, last_name, email, phone, creditCardNumber,  expDateMonth, expDateYear, cvcNumber, billing_address, billing_city, billing_state, billing_zip, agree_term) {
    return {
        first_name: first_name.length === 0,
        last_name: last_name.length === 0,
        email: email.length === 0,
        phone: phone.length === 0,
        creditCardNumber: creditCardNumber.length === 0,
        expDateYear: expDateYear.length === 0,
        expDateMonth: expDateMonth.length === 0,
        cvcNumber: cvcNumber.length === 0,
        billing_address: billing_address.length === 0,
        billing_city: billing_city.length === 0,
        billing_state: billing_state.length === 0,
        billing_zip: billing_zip.length === 0,
        agree_term: agree_term.length === 0,
        
    };
}
export default class ODEPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'first_name': '',
            'last_name': '',
            'email': '',
            'phone': '',
            'expDateMonth': '',
            'creditCardNumber': '',
            'expDateYear': '',
            'cvcNumber': '',
            'creditCardType': '',
            'billing_address': '',
            'billing_city': '',
            'billing_state': '',
            'evt_state': '',
            'billing_zip': '',
            'amount': '0.00',
            'coverage_limit': '',
            'total_persons': '',
            'payRId': '',
            'Selected_dynamic_rates_array': '',
            'price_list': '',

            'additional_insured': '',
            'max_limit': '',
            'min_limit': '',
            'total_amount': '',
            'premium': '',
            'liquor': '',
            'host_liquor': '',
            'medicle_limit': '',
            'event_type_name': '',
            'acknowledge': '',
            'agree_term': '',
            'agree_term1': '',
            'price_premium': '0.00',
            'price_broker_fee': '0.00',
            'processing_fee': '0.00',
            'price_noha': '0.00',
            'price_w_o_s': '0.00',
            'price_primary_non_basis': '0.00',
            'additional_insureds': '0.00',
            'price_total': '0.00',
            'stripe_token' : '',
            'errorMsg': '',
            'focusOut': {
                'first_name': false,
                'last_name': false,
                'email': false,
                'phone': false,
                'creditCardNumber': false,
                'expDateYear': false,
                'expDateMonth': false,
                'cvcNumber': false,
                'billing_address': false,
                'billing_city': false,
                'billing_state': false,
                'billing_zip': false,
                'agree_term':  false,
            },
            'redirectToReferrer': false
        }

        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
     // this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    handleChangeRadio = ()=>{
        this.setState({ agree_term:   !this.state.agree_term });
    }
    
    sigPad = {};
    clear = () => {
        this.sigPad.clear();
    }
    trim = () => {
        this.setState({ errorMsg: '' })
    }


    componentDidMount() {
        const prev = this.props.questionNum - 1;
        this.setState({ prevQuestion: prev });
        this.props.functionPrev.handleUpdatePrevious(prev);
        Inputmask().mask(document.querySelectorAll("input"));
        setTimeout(function () {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v2/";
            script.async = true;
            document.body.appendChild(script);
            }.bind(this), 5);
        window.scrollTo(0, 0);
        this.props.functionLoader.handleUpdateLoader(true);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url + 'get_quote/get_states_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({ countries: result.data.countries });
                }
                this.props.functionLoader.handleUpdateLoader(false);
            })
            .catch(error => this.setState({ error: error.message }));
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        const eventTypeArray = JSON.parse(localStorage.getItem('eventTypeArray'));
        if (localStorageData) {
            if (localStorageData['eventPaymentBakit']) {
                this.setState({ email: localStorageData['eventPaymentBakit'].email });
                this.setState({ phone: localStorageData['eventPaymentBakit'].phone });
                this.setState({ zip: localStorageData['eventPaymentBakit'].zip });
            }
            if(localStorageData['eventTypeBakit'] && localStorageData['eventTypeBakit'].event_type_name)
             this.setState({ event_type_name: localStorageData['eventTypeBakit'].event_type_name });
             
            if(localStorageData['eventPeopleBakit'] && localStorageData['eventPeopleBakit'].no_of_people)
             this.setState({ total_persons: localStorageData['eventPeopleBakit'].no_of_people });

            if(localStorageData['eventAddonBakit'] && localStorageData['eventAddonBakit'].additional_insured)
            this.setState({ additional_insured: localStorageData['eventAddonBakit'].additional_insured });
           
            if(localStorageData['eventRatesBakit']){
                this.setState({ coverage_limit: localStorageData['eventRatesBakit'].coverage_limit });
                this.setState({ max_limit: localStorageData['eventRatesBakit'].Selected_max_limit });
                this.setState({ min_limit: localStorageData['eventRatesBakit'].Selected_min_limit });
                this.setState({ total_amount: localStorageData['eventRatesBakit'].Selected_total });
                this.setState({ liquor: localStorageData['eventRatesBakit'].liquor });
                this.setState({ host_liquor: localStorageData['eventRatesBakit'].host_liquor });
                this.setState({ Selected_dynamic_rates_array: localStorageData['eventRatesBakit'].Selected_dynamic_rates_array });
                this.setState({ selected_plan_full_array: localStorageData['eventRatesBakit'].selected_plan_full_array });
            }
            // 
            var medicle_limit = [];
            var price_list = [];

            setTimeout(function () {
                if (this.state.Selected_dynamic_rates_array.count > 0) {
                    for (var k = 0; k < this.state.Selected_dynamic_rates_array.count; k++) {
                        medicle_limit.push(
                            <Fragment key={k + 1}>
                                <dl className="dl-horizontal">
                                    <dt>{this.state.Selected_dynamic_rates_array[k].gl_limit_type}</dt>
                                    <dd>{this.state.Selected_dynamic_rates_array[k].gl_limit}</dd>
                                </dl>
                            </Fragment>
                        );
                    }
                    this.setState({ medicle_limit: [medicle_limit] });
                }

                if (this.state.selected_plan_full_array) {

                    if (this.state.selected_plan_full_array) {

                        this.setState({ price_premium: this.state.selected_plan_full_array.premium }); 
                        this.setState({ price_broker_fee: this.state.selected_plan_full_array.broker_fee });
                        this.setState({ processing_fee: this.state.selected_plan_full_array.processing_fee });
                        this.setState({ price_noha: this.state.selected_plan_full_array.noha });
                        this.setState({ price_w_o_s: this.state.selected_plan_full_array.w_o_s });
                        this.setState({ price_primary_non_basis: this.state.selected_plan_full_array.p_a_n_c_b });
                        this.setState({ additional_insureds: this.state.selected_plan_full_array.additional_audience });

                         
                    }

                }
            }.bind(this), 5
            );
        }
        if(localStorage.getItem('pending_quote_email')){
            this.setState({email : localStorage.getItem('pending_quote_email')});
        }
    } 
    
    handleValidateBlur = field => evt => {
        this.setState({
            focusOut: { ...this.state.focusOut, [field]: true }
        });
        this.validator.showMessageFor(field);
    };

    
    handleValidateClick = field => evt => {
        if (!this.canBeSubmitted()) {
            return;
        }
        var checked = evt.target.checked;
        var buton =  document.getElementsByClassName("btn_next");
        if(checked== true){
            buton.forEach(function (event) {
                event.classList.remove("disabled");
                event.disabled = false;

              });
         } else{
            buton.forEach(function (event) {
                event.classList.add("disabled");
                event.disabled = true;
              });
        }
    };
                       // ................................. the card detect
getCardType = (cur_val)=> {
    //JCB
    const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
    // American Express
    const amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
    // Diners Club
    const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
    // Visa
    const visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    const  mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
    const  maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    //Discover
    const  discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    // get rid of anything but numbers
     cur_val = cur_val.replace(/\D/g, '');
    // checks per each, as their could be multiple hits
    var sel_brand = "unknown";
    if (cur_val.match(jcb_regex)) {
        sel_brand = "jcb";
    } else if (cur_val.match(amex_regex)) {
        sel_brand = "amex";
    } else if (cur_val.match(diners_regex)) {
        sel_brand = "diners_club";
    } else if (cur_val.match(visa_regex)) {
        sel_brand = "visa";
    } else if (cur_val.match(mastercard_regex)) {
        sel_brand = "mastercard";
    } else if (cur_val.match(discover_regex)) {
        sel_brand = "discover";
    } else if (cur_val.match(maestro_regex)) {
        if (cur_val[0] == '5') { //started 5 must be mastercard
            sel_brand = "mastercard";
        } else {
            sel_brand = "maestro"; //maestro is all 60-69 which is not something else, thats why this condition in the end
        }
    }
    
    return sel_brand;
}
   detactCard = (event)=>{
       var cardNumber = event.target.value;
     var  element =  document.getElementById('carNum');
    if(cardNumber.length > 4){
        var cardtype = this.getCardType(cardNumber);
        
        if(element.classList.contains('jcb') && cardtype != 'jcb'){
            element.classList.remove('jcb')
        }
        
        if(element.classList.contains('amex') && cardtype != 'amex'){
            element.classList.remove('amex')
        }
        
        if(element.classList.contains('diners_club') && cardtype != 'diners_club'){
            element.classList.remove('diners_club')
        }
        
        if(element.classList.contains('visa') && cardtype != 'visa'){
            element.classList.remove('visa')
        }
        
        if(element.classList.contains('mastercard') && cardtype != 'mastercard'){
            element.classList.remove('mastercard')
        }
        
        if(element.classList.contains('discover') && cardtype != 'discover'){
            element.classList.remove('discover')
        }
        
        if(element.classList.contains('maestro') && cardtype != 'maestro'){
            element.classList.remove('maestro')
        }
        
        if(element.classList.contains('unknown') && cardtype != 'unknown'){
            element.classList.remove('unknown')
        }
        element.classList.add(cardtype);
    }
}
// ...................................end card detect
    canBeSubmitted() {
        const errors = validate(this.state.first_name, this.state.last_name, this.state.email, this.state.phone, this.state.creditCardNumber, this.state.expDateMonth, this.state.expDateYear, this.state.cvcNumber, this.state.billing_address, this.state.billing_city, this.state.billing_state, this.state.billing_zip, this.state.agree_term);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }
   
     stripeResponseHandler = (status, response)=> {
        if (response.error) {
            this.setState({errorMsg : response.error.message});
            window.scrollTo(0, 0);
            this.props.functionLoader.handleUpdateLoader(false);
            setTimeout(function(){
                this.setState({errorMsg: ''})
            }.bind(this),10000
            );
        } else {
            //get token id
            var token = response['id'];
            this.setState({stripe_token : token});
            this.handleSubmit();
        }
    }
     stripePay= (e)=> {
        e.preventDefault();
        var publishKey = process.env.REACT_APP_STRIPE_PUBLICKEY;
        window.Stripe.setPublishableKey(publishKey);
        if (this.validator.allValid()) {
        this.props.functionLoader.handleUpdateLoader(true);
         window.Stripe.createToken({
                number:  this.state.creditCardNumber,
                cvc:  this.state.cvcNumber,
                exp_month:  this.state.expDateMonth,
                exp_year: this.state.expDateYear
            }, this.stripeResponseHandler);
    
            //submit from callback
            return false;
        }else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    getLocalTimeZone = ()=> {
        var dd = new Date();
        var ddStr = dd.toString();
        var ddArr = ddStr.split(' ');
        var tmznSTr = ddArr[5];
        tmznSTr = tmznSTr.substring(3, tmznSTr.length);
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
        return dateTime+tmznSTr+'---'+Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    handleSubmit = (event) => {
        //event.preventDefault();
        if (!this.canBeSubmitted()) {
            return;
        }
        let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        if (this.validator.allValid()) {
            this.props.functionLoader.handleUpdateLoader(true);
            var pending_quote_id;
            if (localStorage.getItem('pending_quote_id')) {
                pending_quote_id = JSON.parse(localStorage.getItem('pending_quote_id'));
            }
            const forms_zip = {
                'first_name': this.state.first_name,
                'last_name': this.state.last_name,
                'email': this.state.email,
                'phone': this.state.phone,
                'creditCardNumber': this.state.creditCardNumber,
                //'creditCardType': this.state.creditCardType,
                'expDateMonth': this.state.expDateMonth,
                'expDateYear': this.state.expDateYear,
                'cvv2Number': this.state.cvcNumber,
                'billing_address': this.state.billing_address,
                'city': this.state.billing_city,
                'evt_state': this.state.billing_state,
                'zip': this.state.billing_zip,
                'amount': this.state.total_amount+(this.state.selected_plan_full_array && this.state.selected_plan_full_array != undefined && this.state.selected_plan_full_array.extraCharge ? this.state.selected_plan_full_array.extraCharge :0),
                'premium' : this.state.price_premium,
                'stripe_token': this.state.stripe_token,
                'pending_quote_id': pending_quote_id,
                'submission_status': 'tried',
                'acknowledge': this.state.acknowledge,
                'agree_term': this.state.agree_term,
                'countries': '',
                'current_date':this.getLocalTimeZone(),
                'payRId': ''
            };
            localStorageData["eventPaymentBakit"] = forms_zip;
            if(localStorageData['eventRatesBakit'] && localStorageData['eventRatesBakit'].selected_plan_full_array && localStorageData['eventRatesBakit'].selected_plan_full_array.broker_fee && localStorageData['eventRatesBakit'].selected_plan_full_array.total && this.state.selected_plan_full_array && this.state.selected_plan_full_array != undefined && this.state.selected_plan_full_array.extraCharge){
                localStorageData['eventRatesBakit'].selected_plan_full_array.broker_fee += this.state.selected_plan_full_array.extraCharge
                localStorageData['eventRatesBakit'].selected_plan_full_array.total += this.state.selected_plan_full_array.extraCharge
            }

            var base_url = process.env.REACT_APP_API_URL;

            axios({
                method: "post",
                url: base_url + 'leads/ode_quote_ajax',
                data: { "dataArray": localStorageData },
                headers: { "content-type": "application/json" },
            })
                .then(result => {
                   
                    
                    if (result.data.success === true) {
                        
                        
                        const script = document.createElement("script");
                            script.src = "https://www.googletagmanager.com/gtag/js?id=AW-1071797610'";
                            script.async = true;
                            document.body.appendChild(script);
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){window.dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'AW-1071797610');
                            gtag('event', 
                            'conversion', {
                            'send_to': 'AW-1071797610/HvDECNbIhvMBEOqqif8D',
                            'value': result.data.amount,
                            'currency': 'USD',
                            'transaction_id': result.data.transaction_id,
                          });
                       
                    
                        if (result.data.pending_quote_id != '') {
                            const forms_store = {
                                'first_name': this.state.first_name,
                                'last_name': this.state.last_name,
                                'email': this.state.email,
                                'phone': this.state.phone,
                                'current_date':this.getLocalTimeZone(),
                                'billing_address': this.state.billing_address,
                                'city': this.state.billing_city,
                                'evt_state': this.state.billing_state,
                                'zip': this.state.billing_zip,
                                'amount': this.state.total_amount,
                                'premium' : this.state.price_premium,
                                'pending_quote_id': result.data.pending_quote_id,
                                'submission_status': result.data.pay_status ? 'payment' : 'tried',
                                'acknowledge': this.state.acknowledge,
                                'agree_term': this.state.agree_term,
                                'countries': '',
                                'payRId': ''
                            };
                            localStorageData['eventPaymentBakit'] = forms_store;
                            // Usman 23-Feb-2021 : In oder to stop duplicate entries.
                            localStorage.setItem('pending_quote_id', result.data.pending_quote_id);
                        }
                        this.setState({ pending_quote_id: result.data.pending_quote_id });
                        localStorageData.current_step = parseInt(this.props.questionNum) + 1;
                        localStorageData = JSON.stringify(localStorageData);
                        localStorage.setItem('onedayeventv2', localStorageData);
                        if (localStorage.getItem('oneLoveVenueDetailODE')) {
                            localStorage.removeItem('oneLoveVenueDetailODE');
                        }

                        this.setState({ redirectToReferrer: true });
                    } else if (result.data.pay_status == false) {
                        this.setState({ errorMsg:result.data.message });
                    }
                    else
                    {
                        this.setState({ errorMsg:result.data.message });
                    }
                    this.props.functionLoader.handleUpdateLoader(false);
                })
                .catch(error => {
                    
                    // alert('Please Enter your Correct Card Details');
                    this.setState({ errorMsg: 'Transaction was Unsuccessful, Please Try Again'});
                    this.props.functionLoader.handleUpdateLoader(false);
                    this.forceUpdate();
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    toggleSlide = (type,e)=> {
        e.preventDefault();
    $("."+type).slideToggle();
    var element = document.getElementsByClassName(type+"-icon");
    var icon = element[0].getAttribute("data-id");
    if(icon == "open"){
        element[0].setAttribute("data-id", "close");
        element[0].classList.remove("fa-minus");
        element[0].classList.add("fa-plus");

    }else if(icon == "close"){
        element[0].setAttribute("data-id", "open");
        element[0].classList.remove("fa-plus");
        element[0].classList.add("fa-minus");

    }

    }

    render() {
        if (this.state.redirectToReferrer) {
            const next = this.props.questionNum + 1;
            const page = this.props.page;
            return (<Redirect to={"/quote/"+page+"/" + next} />)
        }
        var win_width = $(window).width();
        if (win_width>991){
            $(".read-more").on("click",function(){
                $(this).parents(".bottom").find("ol").css("height","auto");
                $(this).remove();
            })
        } else {
            $(".read-more").on("click",function(){
                $(this).parents(".bottom").find(".well").css("height","auto");
                $(this).parents(".right").find(".well").css("height","auto");
                $(this).remove();
            })
        }
        var countries = [];
        let index_number = 0;
        var length = this.state.countries && this.state.countries.length > 0 ? this.state.countries.length : 0;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index_number]['name']}>{this.state.countries[index_number]['name']}</option>
                </Fragment>
            );
            index_number++;
        }
        const errors = validate(this.state.first_name, this.state.last_name, this.state.email, this.state.phone, this.state.creditCardNumber, this.state.expDateMonth, this.state.expDateYear, this.state.cvcNumber, this.state.billing_address, this.state.billing_city, this.state.billing_state, this.state.billing_zip, this.state.agree_term);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        const shouldMarkError = field => {
            const hasError = errors[field];
            const shouldShow = this.state.focusOut[field];
            return hasError ? shouldShow : false;
        };

        var termsCheckBox = 
                           [
                            <Fragment key={1}>
                            <div className="checkbox checkbox-primary">
                            <input id="checkbox2" className="styled" type="checkbox" onChange={this.handleChangeRadio}  defaultChecked={this.state.agree_term}  onClick={this.handleValidateClick("agree_term")} name="agree_term"   value={this.state.agree_term} onBlur={this.handleValidateBlur("agree_term")} />
                            <label htmlFor="checkbox2">
                            I agree to the terms and conditions below
                            </label>
                            </div>
                            </Fragment>
                           ];
        const NextButtonComp =  <NextButton btntext="Submit" disabled={isDisabled} prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />;
                               
        return (
            <Fragment>
                <section className="quote-main payment-info">
                    <div className="container">
                    <form id="frmStripePayment" onSubmit={this.stripePay} method="post">
                        <div className="left">
                            <h2>Payment Information</h2>
                            {this.state.errorMsg ?
                            <div className="alert alert-danger alert-dismissible fade in">
                             <a className="close" onClick={this.trim} data-dismiss="alert" aria-label="close">&times;</a>
                             {this.state.errorMsg}
                            </div>
                             : ''}
                           
                                <div className="small-width-container">
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("first_name") ? "form-control error" : " form-control"} name="first_name" onChange={this.handleChange} value={this.state.first_name} onBlur={this.handleValidateBlur("first_name")} autoFocus={true} placeholder="First Name" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('first_name', this.state.first_name, 'alpha_space')}
                                       </span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("last_name") ? "form-control error" : " form-control"} name="last_name" onChange={this.handleChange} value={this.state.last_name} onBlur={this.handleValidateBlur("last_name")} placeholder="Last Name" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('last_name', this.state.last_name, 'alpha_space')}
                                       </span>
                                    </div>
                                </div>
                                <div className="small-width-container">
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("email") ? "form-control error" : " form-control"} name="email" onChange={this.handleChange} value={this.state.email} onBlur={this.handleValidateBlur("email")} placeholder="Email" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('email', this.state.email, 'email')}
                                       </span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" min="0" data-inputmask="'mask': '(999) 999-9999'" className={shouldMarkError("phone") ? "form-control error" : " form-control"} name="phone" onChange={this.handleChange} value={this.state.phone} onBlur={this.handleValidateBlur("phone")} placeholder="Phone" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('phone', this.state.phone, 'phone')}
                                       </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" min="0" id="carNum" data-inputmask="'mask': ['9999 9999 9999 999','9999 9999 9999 9999']" className={shouldMarkError("creditCardNumber") ? "form-control error icon credit" : " form-control icon credit"} onKeyUp={this.detactCard.bind(this)} name="creditCardNumber" onChange={this.handleChange} onBlur={this.handleValidateBlur("creditCardNumber")} value={this.state.creditCardNumber} placeholder="Credit Card Number" />
                                    <span style={{ color: 'red' }}>
                                            {this.validator.message('creditCardNumber', this.state.creditCardNumber, 'card_num')}
                                       </span>
                                </div>
                                <div className="small-width-container three">
                                    <div className="form-group">
                                        <div className="select-div no-arrow">
                                            <select className={shouldMarkError("expDateMonth") ? "form-control selecterror icon exp" : " form-control icon exp "} name="expDateMonth" onChange={this.handleChange} onBlur={this.handleValidateBlur("expDateMonth")} value={this.state.expDateMonth}>
                                                <option value="">Exp Month</option>
                                                <option value="1">01</option>
                                                <option value="2">02</option>
                                                <option value="3">03</option>
                                                <option value="4">04</option>
                                                <option value="5">05</option>
                                                <option value="6">06</option>
                                                <option value="7">07</option>
                                                <option value="8">08</option>
                                                <option value="9">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group " >
                                        <div className="select-div no-arrow selecterror">
                                            <select className={shouldMarkError("expDateYear") ? "form-control selecterror icon exp" : " form-control icon exp"} name="expDateYear" onChange={this.handleChange} onBlur={this.handleValidateBlur("expDateYear")} value={this.state.expDateYear}>
                                                <option value="">Exp Year</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" data-inputmask="'mask': ['999','9999']" className={shouldMarkError("cvcNumber") ? "form-control error icon credit" : " form-control icon credit"} name="cvcNumber" onChange={this.handleChange} onBlur={this.handleValidateBlur("cvcNumber")} value={this.state.cvcNumber} placeholder="CVC" />
                                          <span style={{ color: 'red' }}> 
                                            {this.validator.message('cvcNumber', this.state.cvcNumber, 'numeric|min:3')}
                                            </span>
                                  </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" className={shouldMarkError("billing_address") ? "form-control error icon credit" : " form-control icon credit"} placeholder="Billing Address" name="billing_address" onChange={this.handleChange} onBlur={this.handleValidateBlur("billing_address")} value={this.state.billing_address} />
                                </div>
                                <div className="small-width-container">
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("billing_city") ? "form-control error icon credit" : " form-control icon credit"} name="billing_city" onChange={this.handleChange} onBlur={this.handleValidateBlur("billing_city")} value={this.state.billing_city} placeholder="City" />
                                    </div>
                                    <div className="form-group error">
                                        <div className="select-div error">
                                            <select className={shouldMarkError("billing_state") ? "form-control selecterror" : " form-control"} name="billing_state"  onChange={this.handleChange} onBlur={this.handleValidateBlur("billing_state")} value={this.state.billing_state} >
                                                <option value="">Select State</option>
                                                {countries}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="small-width-container">
                                    <div className="form-group">
                                        <input type="text"  className={shouldMarkError("billing_zip") ? "form-control error icon credit" : " form-control icon credit"} name="billing_zip" onChange={this.handleChange} onBlur={this.handleValidateBlur("billing_zip")} value={this.state.billing_zip} placeholder="Zip" />
                                        <span style={{ color: 'red' }}> 
                                            {this.validator.message('billing_zip', this.state.billing_zip, 'numeric')}
                                            </span>
                                   </div>
                                </div>
                                <div className="margin-bottom-20"></div>
                                <div className=" hidden-xs hidden-sm" >
                                    {termsCheckBox}
                               
                                    <div className="form-group" style={{ color: 'red' }}>
                                        {this.validator.message('agree_terms', this.state.agree_term, 'required')}
                                    </div>

                                <div className="margin-bottom-20"></div>
                               {NextButtonComp}
                                {/* <NextButton btntext="Submit" disabled={isDisabled} prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} /> */}
                               </div>
                           
                           
                       <div className="bottom hidden-xs hidden-sm">
                        <h2>Terms and Conditions</h2>
                        <ul>
                           <li> By purchasing, I accept I have stated everything about the event to the best of my knowledge, and I authorize this charge to my credit card and this is my acceptance for the charge. If any of the information I provided is incorrect the policy may be voided and no coverage will be in effect. I further accept and acknowledge that I have read and understand the terms and conditions, and understand that there may be no refund or cancelation on this policy, even if my event is not held.
                                    Payment made does not mean coverage is bound, no coverage is final until the carrier has approved and issued the policy and confirmed the event details. If the Insurance company declines or fails to underwrite the policy we will refund all premiums and no coverage is in force.
                      </li>
                             </ul>
                        <a style={{ cursor: 'pointer' }} className="read-more hidden-xs">Read more</a>
                    </div>
                        </div>
                        {/* <!--left--> */}
                        <div className="right">
                            <h2>Price <a onClick={this.toggleSlide.bind(this, 'price')}  className="show_hide visible-xs visible-sm"><i className="fa fa-minus price-icon" data-id="open"></i></a></h2>
                            <div className="well price-well price">
                                <dl className="dl-horizontal">
                                    <dt>PREMIUM</dt>
                                    <dd> {this.state.price_premium}</dd>
                                </dl>
                                <dl className="dl-horizontal">
                                    <dt>Broker Fee</dt>
                                    <dd>   {this.state.price_broker_fee+(this.state.selected_plan_full_array && this.state.selected_plan_full_array != undefined && this.state.selected_plan_full_array.extraCharge ? this.state.selected_plan_full_array.extraCharge :0)}</dd>
                                </dl>
                                
                                {this.state.price_noha != '' && this.state.price_noha > 0 ?
                                    <dl className="dl-horizontal">
                                        <dt>NOHA:</dt>
                                        <dd>  {this.state.price_noha}</dd>
                                    </dl>
                                    : ''} 
                                {this.state.price_w_o_s != '' && this.state.price_w_o_s > 0 ?
                                    <dl className="dl-horizontal">
                                        <dt>Waiver of subrogation:</dt>
                                        <dd> {this.state.price_w_o_s}</dd>
                                    </dl>
                                    : ''}

                                   {this.state.price_primary_non_basis != '' && this.state.price_primary_non_basis > 0 ?
                                    <dl className="dl-horizontal">
                                        <dt>Primary/Non-contributor:</dt>
                                        <dd> {this.state.price_primary_non_basis}</dd>
                                    </dl>
                                    : ''}

                                  {this.state.additional_insureds > 0 ?
                                    <dl className="dl-horizontal">
                                        <dt>Additional Insureds:</dt>
                                        <dd> {this.state.additional_insureds}</dd>
                                    </dl>
                                    : ''}
                                <dl className="dl-horizontal total">
                                    <dt>Total</dt>
                                    <dd><span className="price-icon">{this.state.total_amount+(this.state.selected_plan_full_array && this.state.selected_plan_full_array != undefined && this.state.selected_plan_full_array.extraCharge ? this.state.selected_plan_full_array.extraCharge :0)}</span></dd>
                                </dl>
                                <div className="clearfix"></div>
                            </div>
                            {/* <!--price-well--> */}
                            <h2>Policy Details <a onClick={this.toggleSlide.bind(this, 'policy')}  className="show_hide visible-xs visible-sm"><i className="fa fa-minus policy-icon" data-id="open"></i></a></h2>
                            {/* <i className="fa fa-plus"></i> */}
                            <div className="well policy">
                                <dl className="dl-horizontal">
                                    <dt>Limits</dt>
                                    <dd>${this.state.min_limit} million per occurrence /<br /> ${this.state.max_limit} million aggregate</dd>
                                </dl>
                                {/* dynamic fields list */}
                                {this.state.medicle_limit}
                                <dl className="dl-horizontal">
                                    <dt>Guests</dt>
                                    <dd>{this.state.total_persons}</dd>
                                </dl>
                                {this.state.host_liquor==='1' ?
                                <dl className="dl-horizontal">
                                    <dt>Host liquor liability </dt>
                                    <dd>
                                        <img src="/assets/images/icon-liquor-tick.svg" alt="" /> 
                                    </dd>
                                </dl>
                                : ''}
                                <dl className="dl-horizontal">
                                    <dt>Additional insured's</dt>
                                    <dd>{this.state.additional_insured}</dd>
                                </dl>
                                <div className="clearfix"></div>
                            </div>
                            {/* <button type="button" className="btn btn-maroon visible-xs visible-sm">Submit</button> */}
                            <div className="bottom visible-xs visible-sm terms-bottom">
                            <h2>Terms and Conditions <a onClick={this.toggleSlide.bind(this, 'termsCon')}  className="show_hide visible-xs visible-sm"><i className="fa fa-minus termsCon-icon" data-id="open"></i></a> </h2>
                        <ul  className="termsCon">
                           <li> By purchasing, I accept I have stated everything about the event to the best of my knowledge, and I authorize this charge to my credit card and this is my acceptance for the charge. If any of the information I provided is incorrect the policy may be voided and no coverage will be in effect. I further accept and acknowledge that I have read and understand the terms and conditions, and understand that there may be no refund or cancelation on this policy, even if my event is not held.
                                    Payment made does not mean coverage is bound, no coverage is final until the carrier has approved and issued the policy and confirmed the event details. If the Insurance company declines or fails to underwrite the policy we will refund all premiums and no coverage is in force.
                      </li>
                             </ul>
                            </div>
                            <div className="visible-xs visible-sm" >
                            {termsCheckBox}
                                <div className="form-group" style={{ color: 'red' }}>
                                            {this.validator.message('agree_terms', this.state.agree_term, 'required')}
                                        </div>
                                     {NextButtonComp}  
                               </div>
                        </div>
                        {/* //  <!--right--> */}
                        </form>
                        <div className="clearfix"></div>
                    </div>
                </section>
                {/* <!--quote--> */}

            </Fragment>
        );
    }
} 