import React, { useState, Fragment, Suspense, lazy, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Loader from '../loader/Loader';
import $ from 'jquery';
const Header = lazy(() => import('../common/component/Header'));
const Footer = lazy(() => import('../common/component/Footer'));
const MainContent = lazy(() => import('./MainContent'));

export default function Home(props) {
  const [loader, setLoader] = useState(false);
  const handleUpdateLoader = (ajaxState) => {
    setLoader(ajaxState);
  }

  useEffect(() => {

    //   ..................
    $(document).ready(function() {
    (function ($) {
      $.fn.extend({
        rotaterator: function (options) {

          var defaults = {
            fadeSpeed: 1000,
            pauseSpeed: 100,
            child: null
          };

          var options = $.extend(defaults, options);

          return this.each(function () {
            var o = options;
            var obj = $(this);
            var items = $(obj.children(), obj);
            items.each(function () { $(this).hide(); })
            if (!o.child) {
              var next = $(obj).children(':first');
            } else {
              var next = o.child;
            }
            $(next).fadeIn(o.fadeSpeed, function () {
              $(next).delay(o.pauseSpeed).fadeOut(o.fadeSpeed, function () {
                var next = $(this).next();
                if (next.length == 0) {
                  next = $(obj).children(':first');
                }
                $(obj).rotaterator({ child: next, fadeSpeed: o.fadeSpeed, pauseSpeed: o.pauseSpeed });
              })
            });
          });
        }
      });
    })(window.jQuery);


    $(window).scroll(function (event) {
      var scroll = $(window).scrollTop();
      if (scroll > 200) {
        $(".goto-top").addClass("active");
      } else {
        $(".goto-top").removeClass("active");
      }
    });
    //...............
    
  });
    $(".goto-top").click(function () {
      $("html").animate({ scrollTop: 0 }, "slow");
    });


    $('.collapse').on('shown.bs.collapse', function () {
      $(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");
    }).on('hidden.bs.collapse', function () {
      $(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
    });

    // var win_width = $(window).width();
    // if(win_width < 768){

    // }
    //   ................
  });

  window.onload = function () {
    setLoader(false);
    window.$('#rotate').rotaterator({ fadeSpeed: 200, pauseSpeed: 3000 });
  };
  return (

    <Fragment>
      {/* for SEO */}
      <Helmet>
        <meta name="Special Event Insurance" content="Get free special event insurance liability coverage for weddings, birthdays, vendors. Get quotes & your certificate instantly. Simple, fast, reliable." />

        {/* <!-- Schema.org markup for Google+ --> */}
        <meta itemprop="name" content="One day event" />
        <meta itemprop="description" content="One Day Event Insurance is a special event insurance company that sells event liability insurance for all types of events such as weddings, birthdays, vendors, concerts, barmitzvahs, and festivals." />
        <meta itemprop="image" content="https://onedayevent.com/assets/images/hero-bg2.png" />

        {/* <!-- Twitter Card data --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@publisher_handle" />
        <meta name="twitter:title" content="One day event" />
        <meta name="twitter:description" content="One Day Event Insurance is a special event insurance company that sells event liability insurance for all types of events such as weddings, birthdays, vendors, concerts, barmitzvahs, and festivals." />
        <meta name="twitter:creator" content="@jeewanjee.com" />
        {/* <!-- Twitter summary card with large image must be at least 280x150px --> */}
        <meta name="twitter:image:src" content="https://onedayevent.com/assets/images/hero-bg2.png" />

        {/* <!-- Open Graph data --> */}
        <meta property="og:title" content="One Day event" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://onedayevent.com/" />
        <meta property="og:image" content="https://onedayevent.com/assets/images/hero-bg2.png" />
        <meta property="og:description" content="One Day Event Insurance is a special event insurance company" />
        <meta property="og:site_name" content="One day event" />
        <meta property="article:section" content="One Day Event Insurance is a special event insurance company" />
        <meta property="article:tag" content="One day event" />
        <meta property="fb:admins" content="Zeeshan" />
      </Helmet>
      {/* END */}
      {/* <Loader isLoading={loader}></Loader> */}
      <Suspense fallback={<div>Loading...</div>}>
        <div className="wrapper home">
          <Header></Header>
          <MainContent functionLoader={{ handleUpdateLoader: handleUpdateLoader }}></MainContent>
          <Footer></Footer>
        </div>
      </Suspense>
    </Fragment>
  );
}
