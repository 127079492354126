// written by Usman Ghani
// Component Description
// This component is replicate of ODE9. 
// In this component we ask user for "What is the name & address of the insured?" of user who is buying using invoice.

import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur, validator } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import QuotePrograssbar from '../progressBar/InvoiceQuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import Inputmask from "inputmask";
import axios from 'axios';

function validate(ins_first_name, ins_last_name, ins_street, ins_city, ins_state, ins_zip) {
    return {
        ins_first_name: ins_first_name.length === 0,
        ins_last_name: ins_last_name.length === 0,
        ins_street: ins_street.length === 0,
        ins_city: ins_city.length === 0,
        ins_state: ins_state.length === 0,
        ins_zip: ins_zip.length === 0
    };
}
export default class ODE10 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'ins_first_name': '',
            'ins_last_name': '',
            'ins_street': '',
            'ins_city': '',
            'ins_state': '',
            'ins_zip': '',
            'atuoFilledStatus': 'Yes',
            'isSetInsured' : false,
            'focusOut': {
                'ins_first_name': false,
                'ins_last_name': false,
                'email': false,
                'ins_street': false,
                'ins_city': false,
                'ins_state': false,
                'ins_zip': false,
            },
            'image': '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator(validator());
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        this.handleFormFields("Yes");
        const prev = this.props.questionNum - 1;
        this.setState({ prevQuestion: prev });
        //this.props.functionPrev.handleUpdatePrevious(prev);
        Inputmask().mask(document.querySelectorAll("input"));
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2_invoice'));
        

    //    ....................
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url + 'get_quote/get_states_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({ countries: result.data.countries });
                }
            })
            .catch(error => this.setState({ error: error.message }));

        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ event_state: localStorageData[this.props.questionNum].event_state });
            this.setState({ start_time: new Date(localStorageData[this.props.questionNum].start_time) });
        }
       
        if (localStorageData && localStorageData['lead_details']) {
            this.setState({ event_type: localStorageData['lead_details'].event_type });
        }
        if (localStorageData && localStorageData['eventInsurDetailInvoice'] && localStorageData['eventInsurDetailInvoice'].ins_first_name != null) {
            this.setState({ isSetInsured : true });
            this.setState({ ins_first_name: localStorageData['eventInsurDetailInvoice'].ins_first_name });
            this.setState({ ins_last_name: localStorageData['eventInsurDetailInvoice'].ins_last_name });
            this.setState({ ins_street: localStorageData['eventInsurDetailInvoice'].ins_street });
            this.setState({ ins_city: localStorageData['eventInsurDetailInvoice'].ins_city });
            this.setState({ ins_state: localStorageData['eventInsurDetailInvoice'].ins_state });
            this.setState({ ins_zip: localStorageData['eventInsurDetailInvoice'].ins_zip });
            this.setState({atuoFilledStatus: localStorageData['eventInsurDetailInvoice'].atuoFilledStatus})
        }
        if (localStorage.getItem('pending_quote_email')) {
            localStorage.removeItem('pending_quote_email');
        }
    }
 
    handleValidateBlur = field => evt => {
        this.setState({
            focusOut: { ...this.state.focusOut, [field]: true }
        });
        this.validator.showMessageFor(field);
    };
    canBeSubmitted() {
        const errors = validate(this.state.ins_first_name, this.state.ins_last_name, this.state.ins_street, this.state.ins_city, this.state.ins_state, this.state.ins_zip);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.canBeSubmitted()) {
            return;
        }
        
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2_invoice'));
            const forms_zip = {
                'ins_first_name': this.state.ins_first_name,
                'ins_last_name': this.state.ins_last_name,
                'ins_street': this.state.ins_street,
                'ins_city': this.state.ins_city,
                'ins_state': this.state.ins_state,
                'ins_zip': this.state.ins_zip,
                'atuoFilledStatus':this.state.atuoFilledStatus
            };
            localStorageData['eventInsurDetailInvoice'] = forms_zip;
            localStorageData.current_step = parseInt(this.props.questionNum) + 1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2_invoice', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
   
    handleFormFields = (type, event) => {
        this.setState({ atuoFilledStatus: type })
        if (type === 'Yes') {
            const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2_invoice'));
            if (localStorageData && localStorageData['payment_form']) {
                this.setState({ ins_first_name: localStorageData['payment_form'].first_name });
                this.setState({ ins_last_name: localStorageData['payment_form'].last_name });
                this.setState({ ins_street: localStorageData['payment_form'].billing_address });
                this.setState({ ins_city: localStorageData['payment_form'].billing_city });
                this.setState({ ins_state: localStorageData['payment_form'].billing_state });
                this.setState({ ins_zip: localStorageData['payment_form'].billing_zip });
            }
        } else {
            this.setState({ ins_first_name: '' });
            this.setState({ ins_last_name: '' });
            this.setState({ ins_street: '' });
            this.setState({ ins_city: '' });
            this.setState({ ins_state: '' });
            this.setState({ ins_zip: '' });
        }
    }
    render() {

        var countries = [];
        let index_number = 0;
        var length = this.state.countries && this.state.countries.length > 0 ? this.state.countries.length : 0;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index_number]['name']}>{this.state.countries[index_number]['name']}</option>
                </Fragment>
            );
            index_number++;
        }
        const page = this.props.page;
        if (this.state.redirectToReferrer) {
            const next = this.props.questionNum + 1;
            return (<Redirect to={"/multiplePlanInvoice/" + page + "/" + next} />)
        }
        const errors = validate(this.state.ins_first_name, this.state.ins_last_name, this.state.ins_street, this.state.ins_city, this.state.ins_state, this.state.ins_zip);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        const shouldMarkError = field => {
            const hasError = errors[field];
            const shouldShow = this.state.focusOut[field];
            return hasError ? shouldShow : false;
        };

        return (
            <Fragment>
                {/* <BackButton lsKey="onedayeventv2" stepNumber={this.props.questionNum} progressArray={this.props.progressArray} /> */}
                <section  className="quote">
                <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
                    <div className="content">
                        <h2>What is the name & address of the insured?</h2>
                        <div className="inner">
                            {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-guests.png" alt="" /> */}
                            <form onSubmit={this.handleSubmit}>
                                <div hidden={this.state.isSetInsured}>
                                <p style={{ fontSize:'20px' }}> Is this the same as your payment information? </p>
                                    <div className="h-button margin-fix">
                                        <a className={this.state.atuoFilledStatus === 'Yes' ? 'btn btn-maroon2 fix-min-width' : 'btn btn-primary btn-border fix-min-width'} onClick={this.handleFormFields.bind(this, 'Yes')}>Yes</a>
                                        <a className={this.state.atuoFilledStatus === 'No' ? 'btn btn-maroon2 fix-min-width' : 'btn btn-primary btn-border fix-min-width'} onClick={this.handleFormFields.bind(this, 'No')} >No</a>

                                    </div>
                                </div>
                                <div className="small-width-container">
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("ins_first_name") ? "form-control error" : " form-control"} name="ins_first_name" onChange={this.handleChange} onBlur={this.handleValidateBlur("ins_first_name")} value={this.state.ins_first_name} placeholder="First Name" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('ins_first_name', this.state.ins_first_name, 'required|alpha_space_apostrophe_dashes:first name')}
                                       </span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("ins_last_name") ? "form-control error" : " form-control"} name="ins_last_name" onChange={this.handleChange} onBlur={this.handleValidateBlur("ins_last_name")} value={this.state.ins_last_name} placeholder="Last Name" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('ins_last_name', this.state.ins_last_name, 'required|alpha_space_apostrophe_dashes: last name')}
                                       </span>
                                    </div>
                                </div>
                                <div className="small-width-container">
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("ins_street") ? "form-control error" : " form-control"} name="ins_street" onChange={this.handleChange} onBlur={this.handleValidateBlur("ins_street")} value={this.state.ins_street} placeholder="Street Address" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className={shouldMarkError("ins_city") ? "form-control error" : " form-control"} name="ins_city" onChange={this.handleChange} onBlur={this.handleValidateBlur("ins_city")} value={this.state.ins_city} placeholder="City" />
                                        <span style={{ color: 'red' }}>
                                            {this.validator.message('ins_city', this.state.ins_city, 'alpha_space')}
                                       </span>
                                    </div>
                                </div>
                                <div className="small-width-container">
                                    <div className="form-group">
                                        <div className="select-div">
                                            <select className={shouldMarkError("ins_state") ? "form-control error" : " form-control"} name="ins_state" onChange={this.handleChange} onBlur={this.handleValidateBlur("ins_state")} value={this.state.ins_state} >
                                                <option value="0">Select State</option>
                                                {countries}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="ins_zip" className={shouldMarkError("ins_zip") ? "form-control error" : " form-control"} onChange={this.handleChange} onBlur={this.handleValidateBlur("ins_zip")} value={this.state.ins_zip} placeholder="Zip" />
                                        <span style={{ color: 'red' }}> 
                                            {this.validator.message('ins_zip', this.state.ins_zip, 'numeric')}
                                            </span>
                                    </div>
                                </div>
                                <NextButton btntext="Next" disabled={isDisabled} prevQuestion={"/onedayeventv2/" + this.state.prevQuestion} />
                            </form>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
} 