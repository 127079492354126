import React, { Component, Fragment } from "react";
import {
  handleChange,
  handleChangeRadio,
} from "../../../../../common_function/common_function";
import { Redirect } from "react-router-dom";
import QuotePrograssbar from "../progressBar/InvoiceQuotePrograssbar";
import axios from "axios";

export default class Donate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donation_type: "",
      donation_to: "",
      is_donate: "",
      isDisabled: true,
      redirectToReferrer: false,
    };
    this.handleChange = handleChange.bind(this);
    this.handleChangeRadio = handleChangeRadio.bind(this);
  }
  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);

    const localStorageData = JSON.parse(localStorage.getItem("onedayeventv2_invoice"));

    if (localStorageData && localStorageData[this.props.questionNum]) {

      this.setState({ donation_type: localStorageData[this.props.questionNum].donation_to, });
      this.setState({ donation_to: localStorageData[this.props.questionNum].donation_to, });
    
    }
  }

  handleSubmitNew = (event) => {
    let localStorageData = JSON.parse(localStorage.getItem("onedayeventv2_invoice"));
    
    // usman 23-Feb-2021 :
    let pending_quote_id = "";
    if (localStorage.getItem("pending_quote_id")) {
      pending_quote_id = localStorage.getItem("pending_quote_id");
      localStorage.removeItem("pending_quote_id");
    }
    if (localStorage.getItem("venderToODE")) {
      localStorage.removeItem("venderToODE");
    }

    this.props.functionLoader.handleUpdateLoader(true);
    
    const forms_zip = {
      donation_to: this.state.donation_to,
    };

    localStorageData["donate_invoice"] = forms_zip;
    //localStorageData["eventVenueInvoice"].pending_quote_id = pending_quote_id;

    var base_url = process.env.REACT_APP_API_URL;

    axios({
      method: "post",
      url: base_url + "leads/invoice_quote_ajax_update",
      data: { dataArray: localStorageData },
      headers: { "content-type": "application/json" },
    })
      .then((result) => {
        if (result.data.success === true) {
          console.log("donate response is true");
          
          let localStorageData = JSON.parse(localStorage.getItem("onedayeventv2_invoice"));

          if ( localStorageData ) {
            const forms_certificate = {
              event_name: localStorageData["lead_details"].event_name,
              email: localStorageData["lead_details"].email,
              event_date: localStorageData["lead_details"].event_date,

              // dates: localStorageData["eventDateBakit"].dates != undefined ? localStorageData["eventDateBakit"].dates : '' ,
              // EventSingleDate: localStorageData["eventDateBakit"].EventSingleDate != undefined ? localStorageData["eventDateBakit"].EventSingleDate : "",
              
              
              min_limit: localStorageData["invoice_detail"].min_limit != undefined ? localStorageData["invoice_detail"].min_limit : "" ,
              max_limit: localStorageData["invoice_detail"].max_limit != undefined ? localStorageData["invoice_detail"].max_limit : "" ,
            };
            var odeCertificate = JSON.stringify(forms_certificate);
            localStorage.setItem("odeCertificateInvoice", odeCertificate);
          }
          if (localStorage.getItem("pending_quoate_id")) {
            localStorage.removeItem("pending_quoate_id");
          }
          if (localStorage.getItem("pending_quote_email")) {
            localStorage.removeItem("pending_quote_email");
          }
          
          // removing all data
          if(localStorage.getItem("onedayeventv2"))
          {
            localStorage.removeItem("onedayeventv2");
          }
          if(localStorage.getItem("invoice_id"))
          {
            localStorage.removeItem("invoice_id");
          }
          if(localStorage.getItem("onedayeventv2_invoice"))
          {
            localStorage.removeItem("onedayeventv2_invoice");
          }


          this.setState({ redirectToReferrer: true });
        }
        this.props.functionLoader.handleUpdateLoader(false);
      })
      .catch((error) => this.setState({ error: error.message }));
    localStorageData.current_step = parseInt(this.props.questionNum) + 1;
    localStorageData = JSON.stringify(localStorageData);
    localStorage.setItem("onedayeventv2", localStorageData);
  };
  

  handleChangeEvent = (event) => {
    this.setState({ donation_type: event });
    this.setState({ isDisabled: "" });
  };
  handleDoneChange = (event) => {
    this.setState({ is_donate: event });
    if (event === "No") {
      setTimeout(
        function () {
          this.handleSubmitNew();
        }.bind(this),
        5
      );
    }
  };

  handleSubmitEvent = (event) => {
    if (event == "donate") {
      this.setState({ donation_to: this.state.donation_type });
      setTimeout(
        function () {
          this.handleSubmitNew();
        }.bind(this),
        5
      );
    } else if (event == "skip") {
      this.setState({ donation_to: "" });
      this.setState({ donation_type: "" });
      setTimeout(
        function () {
          this.handleSubmitNew();
        }.bind(this),
        5
      );
    }
  };
  render() {
    const page = this.props.page;
    if (this.state.redirectToReferrer) {
      console.log("redirecting from donate")
      return <Redirect to={"/invoice/" + page + "/certificate"} />;
    }
    const isDisabled = this.state.isDisabled;

    return (
      <Fragment>
        <section className="quote donate">
          <QuotePrograssbar
            questionNum={this.props.questionNum}
          ></QuotePrograssbar>
          {/* <!--progess--> */}
          <div className="content">
            <h2>
              Would you like to donate $1 of your premium to one of our favorite
              charities? It is no cost to you, this one's on us{" "}
            </h2>

            <div className="inner">
              <form class="donation-yes-no">
                <div className="small-width-container two-buttons">
                  <div className="form-group">
                    <button
                      type="button"
                      onClick={this.handleDoneChange.bind(this, "Yes")}
                      className={
                        this.state.is_donate === "Yes"
                          ? "btn btn-primary active "
                          : "btn btn-primary"
                      }
                    >
                      Yes
                    </button>
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      onClick={this.handleDoneChange.bind(this, "No")}
                      className={
                        this.state.is_donate === "No"
                          ? "btn btn-primary active "
                          : "btn btn-primary"
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
              </form>

              {this.state.is_donate === "Yes" ? (
                <div className="">
                  <div className="container-fluid">
                    <div className="row">

                      <div className="col-md-6 donate_block_col">
                        <div className="block donate_block" id="donate_block_1">
                        <img src="/assets/images/donate1.png" alt="" />
                          <div className="text">
                            <p> Yours Humanly assists children in need around the world by providing them with whole K-12 scholarships, educational resources, and programs to enrich the learning process for students and school. </p>
                            <a className={ this.state.donation_type === "Yours Humanly" ? "btn btn-brand active " : "btn btn-brand" } onClick={this.handleChangeEvent.bind(this,"Yours Humanly")}>Select</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="block donate_block" id="donate_block_2">
                          <img src="/assets/images/donate2.png" alt="" />
                          <div className="text">
                            <p>
                              Gift of Adoption helps keep children out of foster
                              care and ﬁnalizes the adoption process for
                              children and their new families with adoption
                              grants.{" "}
                            </p>
                            <a
                              className={
                                this.state.donation_type === "Gift of Adoption"
                                  ? "btn btn-brand active"
                                  : "btn btn-brand "
                              }
                              onClick={this.handleChangeEvent.bind(
                                this,
                                "Gift of Adoption"
                              )}
                            >
                              Select
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className="row">
                        
                      <div className="col-md-6 donate_block_col">

                        <div className="block donate_block" id="donate_block_3">
                          <img src="/assets/images/donate3.png" alt="" />
                          <div className="text">
                            <p>San Jose Animal Care Center is One Day Event’s local animal shelter where we volunteer and donate. CEO Zeshan Jeewanjee fostered multiple dogs from here, and we always look to give back locally.</p>
                            <a className={ this.state.donation_type === "Animal Care Center" ? "btn btn-brand active" : "btn btn-brand" }onClick={this.handleChangeEvent.bind( this, "Animal Care Center" )}>Select</a>
                          </div>
                        </div>

                      </div>

                      <div className="col-md-6">
                        <div className="block donate_block" id="donate_block_4">
                            <img src="/assets/images/donate4.png" alt="" />
                            <div className="text">
                              <p>
                                Project Aware creates global change for our oceans by
                                keeping them clean and spearheading policies that help
                                keep our oceans and its wildlife safe.
                              </p>
                              <a
                                className={
                                  this.state.donation_type === "Project Aware"
                                    ? "btn btn-brand active"
                                    : "btn btn-brand"
                                }
                                onClick={this.handleChangeEvent.bind(
                                  this,
                                  "Project Aware"
                                )}
                              >
                                Select
                              </a>
                            </div>
                          </div>
                      </div>          

                    </div>
                  </div>    
                  <br/><br/><br/><br/>              
                  {/* <div className="block myprice">
                    <div className="inner">$1</div>
                  </div> */}

                    <div className="block">

                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-6 donate_btn_div">
                              <a id="donate_btn" className={ this.state.isDisabled ? "btn btn-maroon disabled" : "btn btn-maroon" } onClick={this.handleSubmitEvent.bind(this, "donate")} disabled={isDisabled}>Donate</a>
                          </div>
                          <div className="col-md-6 skip_btn_div">
                            <a className="btn btn-brand" onClick={this.handleSubmitEvent.bind(this, "skip")}> Skip </a>
                          </div>  
                        </div>  
                      </div>  
                  
                    </div>

                  </div>
                ) : ( <div style={{ height: "80px" }}></div>)
            
              }
              {/* <!--donate-flex--> */}
            </div>
            {/* <!--inner--> */}
          </div>
          {/* <!--content--> */}
        </section>
      </Fragment>
    );
  }
}
