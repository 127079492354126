import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange } from '../../../../../common_function/common_function';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';

function validate(event_description) {
    return {
       
        //event_description: event_description.length === 0,
    };
}
export default class ODEventDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'event_description': '',
            'event_name': '',
            'is_validate': false,
            'focusOut': {
                'event_description': false,
            },
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
    }
    componentDidMount() {
        const prev = this.props.questionNum - 1;
        this.setState({ prevQuestion: prev });
        this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        if (localStorageData && localStorageData['eventNameBkit']) {
            this.setState({ event_name: localStorageData['eventNameBkit'].event_name });
            this.setState({ event_description: localStorageData['eventNameBkit'].event_description });
        }
    }

    handleValidateBlur = field => evt => {
        this.setState({
            focusOut: { ...this.state.focusOut, [field]: true }
        });
        this.validator.showMessageFor(field);
    };
    
    canBeSubmitted() {
        const errors = validate(this.state.event_description);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }


     handleSubmit = (event) => {
        event.preventDefault();
        if (!this.canBeSubmitted()) {
            return;
        }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
            const forms_zip = {
                'event_name': this.state.event_name,
                'event_description': this.state.event_description,
            };
            localStorageData['eventNameBkit'] = forms_zip;
            localStorageData.current_step = parseInt(this.props.questionNum) + 1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('onedayeventv2', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    
    render() {

        const page = this.props.page;
        if (this.state.redirectToReferrer) {
            const next = parseInt(this.props.questionNum) + parseInt(1);
            return (<Redirect to={"/quote/"+page+"/" + next} />)
        }
        const errors = validate(this.state.event_description);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        const shouldMarkError = field => {
            const hasError = errors[field];
            const shouldShow = this.state.focusOut[field];
            return hasError ? shouldShow : false;
        };
        return (
            <Fragment>
                <section className="quote event">
                <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
                    <div className="content">
                    <h2>Please provide a description of the event</h2>
                        <div className="inner">
                        <div className="main-img-container" style={{backgroundImage:'url(/assets/images/event-description.png)'}}></div>
                            <form onSubmit={this.handleSubmit} method="post">
                            <div className="form-group">
                                <input type="text" className="form-control" name="event_name"   onChange={this.handleChange}  value={this.state.event_name} placeholder="Event Name (optional)" />
                            </div>
                                <div className="form-group">
                                    <textarea  className={shouldMarkError("event_description") ? "form-control error" : " form-control"} name="event_description" onBlur={this.handleValidateBlur("event_description")} rows="10"  onChange={this.handleChange}  value={this.state.event_description} placeholder="Enter details here"></textarea>
                                        <div className="form-group" style={{ color: 'red' }}>
                                         {this.validator.message('event_description', this.state.event_description, 'required')}
                                        </div>
                                      </div>
                                <NextButton btntext="Next" disabled={isDisabled} prevQuestion={"/onedayevent/" + this.state.prevQuestion} />
                            </form>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
} 