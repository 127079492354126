import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange } from '../../../../../common_function/common_function';
import QuotePrograssbar from '../progressBar/QuotePrograssbar';
import Inputmask from "inputmask";
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from 'react-autocomplete';
import { renderEventTitle } from '../common/EventsData';
import axios from 'axios';

function validate(vendor_activity) {
  return {
    vendor_activity: vendor_activity.length === 0,
  };
}
export default class ODEActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'vendor_activity': '',
      'otherEventName': '',
      'alcoholicDrinks': 'No',
      'focusOut': {
        'vendor_activity': false
      },
      'redirectToReferrer': false,
      'redirectvendorToQuote': false,
      'redirectToReferrerNew': false,
      'event_type': '',
      'event_type_name': '',
      'val': '',
      'redirect_page': '',
      'other_event_code': '',
      'selcted_noha_type': '',
      'event_all_types': '',
      'error': '',
      'reffral_Str': '',
      'isDisabled': true,
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = handleChange.bind(this);
    // this.handleBlur = handleBlur.bind(this);
  }
  componentDidMount() {
    const prev = this.props.questionNum - 1;
    this.setState({ prevQuestion: prev });
    this.props.functionPrev.handleUpdatePrevious(prev);
    Inputmask().mask(document.querySelectorAll("input"));
    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    if (localStorageData && localStorageData['eventActivitybakit']) {
      this.setState({ vendor_activity: localStorageData['eventActivitybakit'].vendor_activity });
      this.setState({ otherEventName: localStorageData['eventActivitybakit'].otherEventName });
    }

    // Usman 22-Feb-2021 : Get Data from states
    // GET STATES
    this.loadStates();
    // SET LOCALSTORAGE
    this.loadLocalStorageData();
  }

  // Usman 22-Feb-2021 : I added bellow functions for buttons ar Vender/3 in order to select Bartender or Band Event.
  findArrayElementById = (array, id) => {
    return array.find((element) => {
      return element.event_type === id;
    })
  }
  loadLocalStorageData = () => {
    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    if (localStorageData && localStorageData['eventTypeBakit']) {

      let event_type_name = localStorageData['eventTypeBakit'].event_type_name;

      this.setState({ event_type: localStorageData['eventTypeBakit'].event_type });
      this.setState({ event_type_name: event_type_name });
      this.setState({ val: localStorageData['eventTypeBakit'].selectedOption });
      // Usman 22-Feb-2021 : Bellow line  commented Because there is no  other_event_code in local storage.
      // this.setState({ other_event_code: localStorageData['eventTypeBakit'].other_event_code });
      this.setState({ isDisabled: '' });
    }
    if (localStorage.getItem('reffral_Str')) {
      this.setState({ reffral_Str: localStorage.getItem('reffral_Str') })
    }
  }
  loadStates = () => {
    var base_url = process.env.REACT_APP_HOME_API_URL;
    //   this.props.functionLoader.handleUpdateLoader(true);
    axios({
      method: "post",
      url: base_url + 'events/get_event_types_ajax',
      headers: { "content-type": "application/json" },
    })
      .then(result => {
        if (result.data.success === true) {
          this.setState({ event_all_types: result.data.event_types });
        }
        // this.setState({handleLoader: false});
      })
      .catch(error => this.setState({ error: error.message }));
  }
  handleEvent = (event) => {

    var events = '';

    var localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));

    if (event == 'Band at the Event') {

      events = this.findArrayElementById(this.state.event_all_types, 'Band at the Event');

      localStorageData['current_type'] = 'ode';

      localStorageData['eventTypeBakit'].event_type = events.uid;
      localStorageData['eventTypeBakit'].event_type_name = "Band at the Event";
      localStorageData['eventTypeBakit'].other_event_code = "";
      localStorageData['eventTypeBakit'].selcted_noha_type = events.noha;
      localStorageData['eventTypeBakit'].selectedOption = "Band at the Event";

      // localStorage.removeItem("onedayeventv2");

      localStorage.setItem('onedayeventv2', JSON.stringify(localStorageData));
      localStorage.setItem('venderToODE', true);

      setTimeout(function () {
        this.handleSubmitNew();
      }.bind(this), 5);


    } else if (event == 'Bartender at the Event') {

      events = this.findArrayElementById(this.state.event_all_types, 'Bartender at the Event');

      localStorageData['current_type'] = 'ode';

      localStorageData['eventTypeBakit'].event_type = events.uid;
      localStorageData['eventTypeBakit'].event_type_name = "Bartender at the Event";
      localStorageData['eventTypeBakit'].other_event_code = "";
      localStorageData['eventTypeBakit'].selcted_noha_type = events.noha;
      localStorageData['eventTypeBakit'].selectedOption = "Bartender at the Event";

      // localStorage.removeItem("onedayeventv2");
      localStorage.setItem('onedayeventv2', JSON.stringify(localStorageData));
      localStorage.setItem('venderToODE', true);

      setTimeout(function () {
        this.handleSubmitNew();
      }.bind(this), 5);

    } else {
      this.setState({ event_type_name: 'Other' });
      this.setState({ event_type: this.state.other_event_code });
    }
  }
  handleSubmitNew = (event) => {
    this.setState({ redirectToReferrerNew: true });
  }
  // ------------------------------------------------------------------------------------------------------------ //

  handleValidateBlur = field => evt => {
    this.setState({
      focusOut: { ...this.state.focusOut, [field]: true }
    });
    this.validator.showMessageFor(field);


  };
  canBeSubmitted() {
    const errors = validate(this.state.vendor_activity);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.canBeSubmitted()) {
      return;
    }
    if (this.validator.allValid()) {
      let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
      let forms_zip = '';
      if (this.state.vendor_activity === 'Other Vendor Type...') {
        forms_zip = {
          'vendor_activity': this.state.otherEventName,
          'otherEventName': this.state.otherEventName
        };
      }
      else {
        forms_zip = {
          'vendor_activity': this.state.vendor_activity,
          'alcoholicDrinks': this.state.alcoholicDrinks
        };
      }

      localStorageData['eventActivitybakit'] = forms_zip;
      localStorageData.current_step = parseInt(this.props.questionNum) + 1;
      localStorageData = JSON.stringify(localStorageData);
      localStorage.setItem('onedayeventv2', localStorageData);
      this.setState({ redirectToReferrer: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handleSelect = (val, event) => {
    this.setState({ val });
    this.setState({ event_type_name: val });
    this.setState({ vendor_activity: val });
    this.setState({ event_type: event.id });
    this.setState({ isDisabled: '' });
    this.setState({ selcted_noha_type: event.rank });
    document.getElementById('errorCheck').style.display = 'none';
    if (val.length == 0) {
      this.setState({ isDisabled: true });
    }
  }

  handleSelectChange = (event, val) => {

    this.setState({ val })
    this.setState({ event_type_name: val });
    this.setState({ vendor_activity: event.target.value });
    this.setState({ isDisabled: '' });
    document.getElementById('errorCheck').style.display = 'none';
    var letterNumber = /^\d+$/;
    if (val.match(letterNumber)) {
      document.getElementById('errorCheck').style.display = 'block';
      this.setState({ isDisabled: true });
    }
    if (val.length == 0) {
      this.setState({ isDisabled: true });
    }
  }
  handleOtherEventName = (event) => {
    this.setState({ otherEventName: event.target.value });
  }
  handleAlcoholicDrink = (type, event) => {
    this.setState({ alcoholicDrinks: type });
  }

  handleVendorAtEvent = (type, event) => {
    let localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
    let forms_zip = {};
    if (type === 'Bartender') {
      if (localStorageData && localStorageData['eventTypeBakit']) {
        forms_zip = {

          'event_type': "153",
          'event_type_name': "Bartender at the Event",
          'other_event_code': "",
          'referral_string': "",
          'selcted_noha_type': "M/Q",
          'selectedOption': "Bartender at the Event",
        };
      }

    }
    else if (type === 'Band') {

      forms_zip = {

        'event_type': "86",
        'event_type_name': "Band at the Event",
        'other_event_code': "",
        'referral_string': "",
        'selcted_noha_type': "N/A",
        'selectedOption': "Band at the Event",
      };

    }
    localStorageData['eventTypeBakit'] = forms_zip;
    localStorageData.current_step = 3;
    localStorageData = JSON.stringify(localStorageData);
    localStorage.setItem('onedayeventv2', localStorageData);
    this.setState({ redirectToReferrerNew: true });
  }



  render() {

    let options2 = [];
    let array = [
      'Antique Collectibles Vendors',
      'Apparel Accessories',
      'Arts Crafts Vendors',
      'Celebrity, Mascot, or Character Appearances/Photo Booths',
      'Cookware',
      'Disc-Jockey (for private events with less than 200 attendees)',
      'DJ at event',
      'Face Painting',
      'Food & Drink(non-alcoholic)',
      'Game Trailers or Booths (inflatables excluded)',
      'Gift Wrap Booths',
      'Micro-Reality Race Tracks',
      'Photo Booth',
      'Photographer/Photography',
      'Photographer/Videographer',
      'Produce Floral Vendors',
      'Product Demos - Static',
      'Product or Service Display Exhibits',
      'Retail Carts',
      'Souvenir Sales',
      'Sports Camping Equipment',
      'Vehicles, Equipment, or Hardware Sales on Display - Static',
      'Videographer',
      'Other Vendor Type...'
    ];
    for (let i = 0; i < array.length; i++) {
      options2.push(

        { "title": array[i], "rank": 'N/A', "id": i }
      );
    }

    const page = this.props.page;

    // Usman 22-Feb-2021 : switch from vender to ode/3
    if (this.state.redirectvendorToQuote) {
      const next = this.props.questionNum;
      return (<Redirect to={"/quote/" + 'ode' + "/" + next} />)
    }
    if (this.state.redirectToReferrerNew) {
      const next = this.props.questionNum;
      return (<Redirect to={"/quote/" + 'ode' + "/" + next} />)
    }

    if (this.state.redirectToReferrer) {
      const next = this.props.questionNum + 1;
      return (<Redirect to={"/quote/" + page + "/" + next} />)
    }
    const errors = validate(this.state.vendor_activity);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.focusOut[field];
      if (this.state.vendor_activity == 0) {
        return shouldShow;
      }
      return hasError ? shouldShow : false;
    };

    const autoCompleteComp = [
      <Fragment key="1">
        <Autocomplete
          value={this.state.vendor_activity}
          items={options2}
          getItemValue={item => item.title}
          shouldItemRender={renderEventTitle}
          renderMenu={item => (
            <div className="dropdown">
              {item}
            </div>
          )}
          renderItem={(item, isHighlighted) =>
            <div className={`item ${isHighlighted ? 'selected-item' : ''}`} key={item.id} >
              {item.title}
            </div>
          }
          onChange={this.handleSelectChange.bind(this)}
          onSelect={this.handleSelect.bind(this)}
          inputProps={{ placeholder: 'Type your vendor in here' }}

          autoHighlight={true}
          ref={(input) => { this.nameInput = input; }} />
      </Fragment>
    ]

    return (
      <Fragment>
        <section className="quote event">
          <QuotePrograssbar page={page} questionNum={this.props.questionNum} ></QuotePrograssbar>
          <div className="content">
            <h2>What kind of vendor are you?</h2>
            <div className="inner">
              <div className="main-img-container large" style={{ backgroundImage: 'url(/assets/images/ICNOS-04.png)' }}></div>

              <form onSubmit={this.handleSubmit} method="post">
                <div className="small-width-container two-buttons">
                  <div className="form-group">
                    <button type="button" className={this.state.bartenderAtEvent === 'Yes' ? 'btn btn-primary active' : 'btn btn-primary '} onClick={this.handleVendorAtEvent.bind(this, 'Bartender')}>Bartender at Event</button>
                  </div>
                  <div className="form-group">
                    <button type="button" className={this.state.bandAtEvent === 'Yes' ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.handleVendorAtEvent.bind(this, 'Band')}>Band at Event</button>
                  </div>
                </div>

                <div className="autocomplete-wrapper  visible-xs" style={{ marginBottom: '25px' }}>
                  {autoCompleteComp}
                </div>
                <div className="autocomplete-wrapper  hidden-xs">
                  {autoCompleteComp}

                </div>
                {this.state.vendor_activity === 'Other Vendor Type...' ?
                  <Fragment>

                    <div className="hidden-qa" style={{ width: '100%' }}>
                      <input type="text" value={this.state.otherEventName} placeholder="Type In Your Event" name="otherEventName" onChange={this.handleOtherEventName} className="autocomplete" style={{ marginTop: '2%' }} />
                    </div>
                    <div className="form-group" style={{ color: 'red' }}>
                      {this.validator.message('Vendor Event Name ', this.state.otherEventName, 'required')}
                    </div>
                  </Fragment>
                  : ''}
                {this.state.vendor_activity === 'Food & Drink' ?
                  <Fragment>
                    <div className="hidden-qa" style={{ marginTop: '20px' }}>
                      <h4>Will there be any alcoholic drinks</h4>
                      <div className="small-width-container two-buttons">
                        <div className="form-group">
                          <button type="button" className={this.state.alcoholicDrinks === 'Yes' ? 'btn btn-primary active' : 'btn btn-primary '} onClick={this.handleAlcoholicDrink.bind(this, 'Yes')}>Yes</button>
                        </div>
                        <div className="form-group">
                          <button type="button" className={this.state.alcoholicDrinks === 'No' ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.handleAlcoholicDrink.bind(this, 'No')}>No</button>
                        </div>
                      </div>
                      <div className="form-group" style={{ color: 'red' }}>
                        {this.validator.message('Is there are any Alcoholic Drinks', this.state.alcoholicDrinks, 'required')}
                      </div>
                    </div>
                  </Fragment>
                  : ''}

                <span id="errorCheck" style={{ color: 'red', display: 'none' }}>
                  The event may only contain letters and only numbers are not allowed.
                </span>
                <p style={{ marginTop: '10px' }}>Please type in your Vendor if you do not see it in the dropdown</p>
                <NextButton btntext="Next" disabled={isDisabled} prevQuestion={"/VendorQuote/" + this.state.prevQuestion} />

              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}