import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ODE2 from '../commonComponent/ODE2';
import ODEVendorOrHost from '../commonComponent/ODEVendorOrHost';
import ODE3 from '../commonComponent/ODE3';
import ODEActivity from './ODEActivity';
import ODEClaim from './ODEClaim';
import ODE4 from '../commonComponent/ODE4';
import ODE5 from '../commonComponent/ODE5';
import ODE6 from '../commonComponent/ODE6';
import ODE7 from '../commonComponent/ODE7';
import ODE8 from './ODE8';
import ODE9 from '../commonComponent/ODE9';
import ODE10 from '../commonComponent/ODE10';
import ODE10A from '../commonComponent/ODE10A';
import ODEPayment from '../commonComponent/ODEPayment';
import ThankQuote from '../commonComponent/ThankQuote';
import Donate from '../commonComponent/Donate';
import ODECertificate from '../commonComponent/ODECertificate';
import ODEConfirmation from '../commonComponent/ODEConfirmation';
import ODEventDescription from '../commonComponent/ODEventDescription';

import Loader from '../../../../../loader/Loader';


function Layout(props) {

    let number = props.questionNum;
    let params = props.page + '/' + number;
    let page = props.page


    let pendingQuoteId = props.genreId;
    const [prev, setPrev] = useState(0);

    const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));

    const handleUpdatePrevious = (page) => {
        setPrev(number);
    }

    // ............
    const [loader, setLoader] = useState(false);
    const handleUpdateLoader = (ajaxState) => {
        setLoader(ajaxState);
    }
    // .........
    var Tstatus = '';
    if (localStorage.getItem('thankx')) {
        Tstatus = 'Yes';
    }

    var isEventInArray = 'No';
    let eventArray = [
        'Antique Collectibles Vendors',
        'Apparel Accessories',
        'Arts Crafts Vendors',
        'Celebrity, Mascot, or Character Appearances/Photo Booths',
        'Cookware',
        'Disc-Jockey (for private events with less than 200 attendees)',
        'DJ at event',
        'Face Painting',
        'Food & Drink(non-alcoholic)',
        'Game Trailers or Booths (inflatables excluded)',
        'Gift Wrap Booths',
        'Micro-Reality Race Tracks',
        'Photo Booth',
        'Photographer/Photography',
        'Photographer/Videographer',
        'Produce Floral Vendors',
        'Product Demos - Static',
        'Product or Service Display Exhibits',
        'Retail Carts',
        'Souvenir Sales',
        'Sports Camping Equipment',
        'Vehicles, Equipment, or Hardware Sales on Display - Static',
        'Videographer',
        'Other Vendor Type...'
    ];
    if (localStorageData && localStorageData['eventActivitybakit']) {
        if (eventArray.includes(localStorageData['eventActivitybakit'].vendor_activity)) {
            isEventInArray = 'Yes';
        }
    }



    if (params !== 'vendor/2' && !localStorageData && params !== 'vendor/certificate' && params !== 'vendor/confirmation')
        return (<Redirect to={"/onedayevent/1"} />)

    if (localStorageData && localStorageData.current_step < 9 && params == 'vendor/certificate')
        return (<Redirect to={"/vendor/" + localStorageData.current_step} />)

    let component = <ODE2 questionNum={number} page={page} pendingQuote={pendingQuoteId} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

    if (params == 'vendor/thanks') {
        component = <ThankQuote questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
    }
    if (params == 'vendor/certificate') {
        component = <ODECertificate questionNum={10} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

    }
    if (params == 'vendor/confirmation') {
        component = <ODEConfirmation questionNum={10} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

    }
    if (params === 'vendor/2') {
        component = <ODEVendorOrHost questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
    }
    else if (params === 'vendor/3') {
        component = <ODE2 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
    }
    else if (params === 'vendor/4') {
        component = <ODEActivity questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
    }
    else if (params === 'vendor/5') {
        component = <ODE3 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
    }
    else if (params === 'vendor/6') {
        component = <ODE5 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
    }
    else if (params === 'vendor/7') {
        component = <ODE7 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
    }
    else if (params === 'vendor/8') {
        component = <ODEClaim questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

    } else if (params === 'vendor/9') {

        component = <ODEventDescription questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

    } else if (params === 'vendor/10') {

        if (localStorageData && Tstatus === 'Yes' || localStorageData['eventStateBakit'].is_consectiveDate === 'No' || isEventInArray === 'No') {
            component = <ODE9 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
        } else {
            component = <ODE8 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
        }


    } else if (params === 'vendor/11') {

        if (localStorageData && Tstatus === 'Yes' || localStorageData['eventStateBakit'].is_consectiveDate === 'No' || isEventInArray === 'No') {
            component = <ODE10 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
        } else {
            component = <ODE9 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
        }


    } else if (params === 'vendor/12') {
        if (localStorageData && Tstatus === 'Yes' || localStorageData['eventStateBakit'].is_consectiveDate === 'No' || isEventInArray === 'No') {
            if (localStorageData && localStorageData['eventAddonBakit'] && localStorageData['eventAddonBakit'].additional_insured !== '0' && localStorageData['eventAddonBakit'].additional_insured !== '') {

                component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

            } else {

                component = <ThankQuote questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

            }
        } else {

            component = <ODE10 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

        }

    } else if (params === 'vendor/13') {

        if (localStorageData && Tstatus === 'Yes' || localStorageData['eventStateBakit'].is_consectiveDate === 'No' || isEventInArray === 'No') {
            component = <ThankQuote questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
        } else {
            if (localStorageData && localStorageData['eventAddonBakit'] && localStorageData['eventAddonBakit'].additional_insured !== '0' && localStorageData['eventAddonBakit'].additional_insured !== '') {

                component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

            } else {

                component = <ODEPayment questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

            }
        }
        // Manual quote will stop here
    } else if (params === 'vendor/14') {

        if (localStorageData && localStorageData['eventAddonBakit'] && localStorageData['eventAddonBakit'].additional_insured !== '0' && localStorageData['eventAddonBakit'].additional_insured !== '') {

            component = <ODEPayment questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

        } else {

            component = <Donate questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

        }

    } else if (params === 'vendor/15') {
        component = <Donate questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

    }

    if (localStorageData && localStorageData.current_step && parseInt(number) > parseInt(localStorageData.current_step)) {
        return (<Redirect to={"/quote/vendor/" + localStorageData.current_step} />)
    }
    return (
        <Fragment>
            <Loader isLoading={loader}></Loader>
            {component}
        </Fragment>
    );
}
export default Layout;
