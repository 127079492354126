import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import NextButton from '../../../ode/component/common/NextButton';
import { handleChange, handleBlur } from '../../../../../common_function/common_function';
import QuotePrograssbar from '../questions/QuotePrograssbar';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import Autocomplete from 'react-autocomplete';
import { renderEventTitle } from './EventsData';
import HeaderQuestion from '../../../../../common/component/HeaderQuestion';
import Loader from '../../../../../loader/Loader';

export default class EventTypes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            'event_type': '',
            'event_type_name': '',
            'event_all_types': '',
            'wedding_code': '',
            'birthday_code': '',
            'vendor_at_event_code': '',
            'other_event_code': '',
            'selectedOption': null,
            'isDisabled': true,
            'linkRedirect': false,
            'linkCurrentStep': '',
            'current_event': '',
            'reffral_Str': '',
            'value': '',
            'val': '',
            'redirect_page': '',
            'suggestions': [],
            'selcted_noha_type':'',
            'handleLoader':true,
            'redUrl':'ode/2',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        // bind require functions.
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        // DELETE LOCALSTORAGE IF EXISTS
        this.deletelocalStorageData();
      //# GET SAVE QUOTE DATA
      this.loadSaveQuoteData();
      //#GET STATES
        this.loadStates();
      //SET LOCALSTORAGE
      this.loadLocalStorageData();        
      // #For LADING PAGE REDIRECTION DATA SETTING
      this.eventLandingPageRander();
    }

    deletelocalStorageData = ()=>{
        if(localStorage.getItem('onedayeventv2'))
        {
            localStorage.removeItem('onedayeventv2');
            localStorage.removeItem('pending_quote_id');
            localStorage.removeItem('pending_quote_email');

        }
    }

    loadLocalStorageData = ()=>{
        const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
        if (localStorageData && localStorageData['eventTypeBakit']) {
            
            let event_type_name = localStorageData['eventTypeBakit'].event_type_name;
            
            this.setState({ event_type: localStorageData['eventTypeBakit'].event_type });
            this.setState({ event_type_name: event_type_name });
            this.setState({ val: localStorageData['eventTypeBakit'].selectedOption });
            this.setState({ other_event_code: localStorageData['eventTypeBakit'].other_event_code });
            this.setState({ isDisabled: '' });

        }

        if (localStorage.getItem('reffral_Str')) {
            this.setState({ reffral_Str: localStorage.getItem('reffral_Str') })
        }
    }

    eventLandingPageRander= (redirect_pageName)=>{
        var redirect_pageName = new URLSearchParams(window.location.search).get("redirect_page");
        var redirect_eventType = new URLSearchParams(window.location.search).get("eventType");
        
        var forms_info = {
                'event_type': redirect_eventType,
                'event_type_name': redirect_pageName,
                'other_event_code': "",
                'selectedOption': redirect_pageName,
                'referral_string': this.state.reffral_Str
            };
            if (redirect_pageName && redirect_pageName!= null && redirect_eventType) {
                if (redirect_pageName === 'Vendor at Event') {
                    this.setState({ redUrl: "vendor/2" });
                }
              var  form_bus = JSON.stringify(forms_info);
                localStorage.setItem('eventTypeArray', form_bus);
            this.setState({ isDisabled: '' });
           this.setState({ redirectToReferrer: true });
        } else if (redirect_pageName == '') {
            this.setState({ redirect_page: "Yes" });
            this.setState({ val: '' });
            this.setState({ event_type: '' });
            this.setState({ event_type_name: '' });
           this.setState({ isDisabled: true });
            this.nameInput.focus();
        }
    }
    findArrayElementById = (array, id) => {
        return array.find((element) => {
          return element.event_type === id;
        })
      }


    loadStates = () =>{
        var base_url = process.env.REACT_APP_HOME_API_URL;
        //   this.props.functionLoader.handleUpdateLoader(true);
        axios({
            method: "post",
            url: base_url + 'events/get_event_types_ajax',
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.data.success === true) {
                    const eventArray = result.data.event_types;
                    // this is new Event added, act like 181, THe key is changed to 1811 to tackle dublicate key issue
                    let Repass = {
                        uid:'1811',
                        event_type:'Repass',
                        client_added:'0',
                        noha:'150',
                    }
                    eventArray.push(Repass);

                    this.setState({ event_all_types: eventArray });
                }
                this.setState({handleLoader: false});
            })
            .catch(error => this.setState({ error: error.message }));

    }
    loadSaveQuoteData= () =>{
    //    const quoateId = this.props.pendingQuote;
        let quoateId = this.props.match.params.genreId;
        if (quoateId) {
            var base_url = process.env.REACT_APP_HOME_API_URL;
            axios({
                method: "post",
                url: base_url + 'leads/get_ode_link_quote_ajax/' + quoateId,
                headers: { "content-type": "application/json" },
            })
                .then(result => {
                   
                    var josan_data = result.data[0].pending_json_form_array;
                    var parse_data = JSON.parse(josan_data);
                    var event_type_name = parse_data['eventTypeBakit'].event_type_name;
                    var selectedOption = parse_data['eventTypeBakit'].selectedOption;
                   
                    var current_step = parse_data.current_step;
                    var current_type = parse_data.current_type;

                    localStorage.setItem('pending_quote_id', result.data[0].id);
                    localStorage.setItem('pending_quote_email', result.data[0].email);
                    localStorage.setItem('onedayeventv2', josan_data);

                    this.setState({ event_type: result.data[0].event_type });
                    this.setState({ event_type_name: event_type_name });
                    this.setState({ selectedOption: selectedOption });
                    this.setState({ linkCurrentStep: current_step });
                    this.setState({ page: current_type });

                    this.setState({ linkRedirect: true });

                })
                .catch(error => this.setState({ error: error.message }));
        }
    } 
    handleEvent = (event) => {

        var events = '';
       
        if (event == 'Wedding Reception') {
            events = this.findArrayElementById(this.state.event_all_types, 'Wedding Reception' );
            this.setState({ event_type:  events.uid });
            this.setState({ event_type_name: 'Wedding Reception' });
            this.setState({ other_event_code: '' });
            this.setState({ val: '' });
            this.setState({selcted_noha_type: events.noha })
            this.setState({ isDisabled: '' });
            setTimeout(function () {
                this.handleSubmit();
            }.bind(this), 7);
        } else if (event == 'Birthday Party') {

            events = this.findArrayElementById(this.state.event_all_types, 'Birthday Party' );
            this.setState({ event_type: events.uid });
            this.setState({ event_type_name: 'Birthday Party' });
            this.setState({ other_event_code: '' });
            this.setState({ val: '' });
            this.setState({selcted_noha_type: events.noha })

            this.setState({ isDisabled: '' });
            setTimeout(function () {
                this.handleSubmit();
            }.bind(this), 5);

        } else if (event == 'Vendor at Event') {

            events = this.findArrayElementById(this.state.event_all_types, 'Vendor at Event' );

            this.setState({ event_type:  events.uid });
            this.setState({ event_type_name: 'Vendor at Event' });
            this.setState({ other_event_code: '' });
            this.setState({ val: '' });
            this.setState({selcted_noha_type: events.noha });
            this.setState({ isDisabled: '' });
            setTimeout(function () {
                this.handleSubmit();
            }.bind(this), 5);
        } else {
            this.setState({ event_type_name: 'Other' });
            this.setState({ event_type: this.state.other_event_code });
        }
    }

    handleQuoteUrl= ()=>{
        if(this.state.event_type == '32'){
            this.setState({redUrl: "vendor/2"});
        }
    }
    
    handleSubmit = (event) => {
        if (this.validator.allValid()) {

            this.handleQuoteUrl();
            var forms_info = {
                'event_type': this.state.event_type,
                'event_type_name': this.state.event_type_name,
                'other_event_code': this.state.other_event_code,
                'selectedOption': this.state.val,
                'selcted_noha_type': this.state.selcted_noha_type,
                'referral_string': this.state.reffral_Str
            };
              var form_bus = JSON.stringify(forms_info);
                // usman 22,Feb,2021 : Why this eventTypeArray is changed in the local storage as onedayeventv2
                // I think Its because this is rendring 2 times.
                localStorage.setItem('eventTypeArray', form_bus);
            
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleSelectChange = (event, val) => {

        this.setState({ val })
        this.setState({ event_type_name: val });
        this.setState({ event_type: val });
        this.setState({ isDisabled: '' });
        document.getElementById('errorCheck').style.display = 'none';
        var letterNumber = /^\d+$/;
        if(val.match(letterNumber)) 
        {
            document.getElementById('errorCheck').style.display = 'block';
            this.setState({ isDisabled: true });
        }
        if(val.length==0){
            this.setState({ isDisabled: true });
        }
    }
    handleSelect = (val, event) => {
        if(event.id == '1811')
        {
            event.id = '181';
        }
        this.setState({ val });
        this.setState({ event_type_name: val });
        this.setState({ event_type: event.id });
        this.setState({ isDisabled: '' });
        this.setState({selcted_noha_type: event.rank });
        document.getElementById('errorCheck').style.display = 'none';
        if(val.length==0){
            this.setState({ isDisabled: true });
        }
    }

    render() {
        var fromHost = '';
        if(localStorage.getItem('isHostSet'))
        {
            fromHost = 1;
        }
        else
        {
            fromHost = 0;
        }
        var options2 = [];
        let index_number = 0;
        var length = this.state.event_all_types && this.state.event_all_types.length > 0 ? this.state.event_all_types.length : 0;
        for (var i = 1; i <= length; i++) {
            if (this.state.event_all_types[index_number]['event_type'] === 'Vendor at Event') {
                // do the Event Activity (In future)
            }
            else {
            options2.push(

                { "title": this.state.event_all_types[index_number]['event_type'], "rank": this.state.event_all_types[index_number]['noha'], "id": this.state.event_all_types[index_number]['uid'] }
            );
            }

            index_number++;
        }
        const isDisabled = this.state.isDisabled;
        //   For email link redirection
        if (this.state.linkRedirect) {
           return (<Redirect to={"/quote/"+this.state.page+"/" + this.state.linkCurrentStep} />)
            // return (<Redirect to={"/quote/ode/" +2} />)
        }

        if (this.state.redirectToReferrer) {
            const next = parseInt(this.props.questionNum) + parseInt(1);
            return (<Redirect to={"/quote/"+this.state.redUrl} />)
        }

        const autoCompleteComp = [
            <Fragment key="1">
                <Autocomplete
                    value={this.state.val}
                    items={options2}
                    getItemValue={item => item.title}
                    shouldItemRender={renderEventTitle}
                    renderMenu={item => (
                        <div className="dropdown">
                            {item}
                        </div>
                    )}
                    renderItem={(item, isHighlighted) =>
                        <div className={`item ${isHighlighted ? 'selected-item' : ''}`}  key={item.id} >
                            {item.title}
                        </div>
                    }
                    onChange={this.handleSelectChange.bind(this)}
                    onSelect={this.handleSelect.bind(this)}
                    inputProps={{ placeholder: 'Type your event in here'}}
                
                    autoHighlight={true}
                    ref={(input) => { this.nameInput = input; }} />
            </Fragment>
        ]

        return (
            <Fragment>
             <Loader isLoading={this.state.handleLoader}></Loader>
			<div className="wrapper quote-pg">
				<HeaderQuestion prevQuestion={1} url="/quote/vendor/" lsKey="" ></HeaderQuestion>
				
                <section className="quote">
                    <QuotePrograssbar questionNum={1} ></QuotePrograssbar>
                    <div className="content">
                        {/* <h2>Hi, what type of event do you need covered ?</h2> */}
                       <h2>Hi, let’s get your quote! What type of event do you need covered? </h2> 
                        <div className="inner">
                            {/* <img className="animate__animated animate__fadeInUp" src="/assets/images/quote-type.png" alt="" /> */}
                            <div className="main-img-container" style={{ backgroundImage: 'url(/assets/images/quote-type.png)' }}></div>
                            <form onSubmit={this.handleSubmit} method="post" >
                                {/* For mobile view autoComplete */}
                                <div className="autocomplete-wrapper  visible-xs" style={{ marginBottom: '25px' }}>
                                    {autoCompleteComp}
                                </div>
                                <ul className={this.state.redirect_page == 'Yes' ? "type buttons-four" : "type"}>
                                    {fromHost === 1 ? '' : <Fragment>
                                        <li><a className={this.state.event_type_name === 'Wedding Reception' ? 'btn btn-primary active btn-border active' : 'btn btn-primary'} onClick={this.handleEvent.bind(this, 'Wedding Reception')}>Wedding</a></li>
                                        <li><a className={this.state.event_type_name === 'Birthday Party' ? 'btn btn-primary active btn-border active' : 'btn btn-primary'} onClick={this.handleEvent.bind(this, 'Birthday Party')} >Birthday</a></li>
                                        <li className="vendor"><a className={this.state.event_type_name === 'Vendor at Event' ? 'btn btn-primary  btn-border active' : 'btn btn-primary'} onClick={this.handleEvent.bind(this, 'Vendor at Event')}>Vendor at Event</a></li>
                                    </Fragment>}
                                    {this.state.redirect_page == 'Yes' ? <li><a className="btn btn-primary  btn-border active" onClick={this.handleEvent.bind(this, 'Other_event')}>Other</a></li>: ''}
                                </ul>
                                {/* For Desktop view autoComplete */}
                                <div className="autocomplete-wrapper  hidden-xs">
                                    {autoCompleteComp}
                                    <span id="errorCheck" style={{ color: 'red', display: 'none' }}>
                                        The event may only contain letters and only numbers are not allowed.
                                    </span>
                                    <p style={{marginTop:'10px'}}>Please type in your event if you do not see it in the dropdown</p>
                                </div>
                               
                                <NextButton btntext="Next" disabled={isDisabled} prevQuestion={"/gerneral/vendors/" + this.state.prevQuestion} />
                            </form>
                        </div>
                        {/* <!--inner--> */}
                    </div>
                    {/* <!--content--> */}
                </section>
                <footer className="quote-footer"></footer>
			</div>
            </Fragment>
        );
    }
}