import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ODE2 from '../commonComponent/ODE2';
import ODE3 from '../commonComponent/ODE3';
import ODE4 from '../commonComponent/ODE4';
import ODE5 from '../commonComponent/ODE5';
import ODE6 from '../commonComponent/ODE6';
import ODE7 from '../commonComponent/ODE7';
import ODE8 from '../commonComponent/ODE8';
import ODE9 from '../commonComponent/ODE9';
import ODE10 from '../commonComponent/ODE10';
import ODE10A from '../commonComponent/ODE10A';
import ODEPayment from '../commonComponent/ODEPayment';
import ThankQuote from '../commonComponent/ThankQuote';
import Donate from '../commonComponent/Donate';
import ODECertificate from '../commonComponent/ODECertificate';
import ODEConfirmation from '../commonComponent/ODEConfirmation';
import ODEventDescription from '../commonComponent/ODEventDescription';
import Loader from '../../../../../loader/Loader';





function QuestionLayout(props) {

    let number = props.questionNum;	
	let params = props.page +'/'+number;
	let page = props.page

	let pendingQuoteId = props.genreId;
	const [prev, setPrev] = useState(0);
	const localStorageData = JSON.parse(localStorage.getItem('onedayeventv2'));
	const handleUpdatePrevious = (number) => {
		setPrev(number);
	}
	const [loader, setLoader] = useState(false);
	const handleUpdateLoader = (ajaxState) => {
		setLoader(ajaxState);
	}
	// to check Event Type
	function checkEventType() {
		if (localStorageData && localStorageData['eventTypeBakit'].event_type !== '72' && localStorageData['eventTypeBakit'].event_type !== '66' && localStorageData['eventTypeBakit'].event_type !== '67') {
			return true;
		}
		return false;
	}
	// to check manual quote
	function toManualQuote(secondCheck = false) {
		if (!secondCheck) {
			if (localStorageData && localStorageData['eventAddonBakit'].hired_status == '1' || localStorageData.current_type === 'vendor' ?  null : localStorageData['alchoalAteventBakit'].is_sold_profitable === 'Yes' || localStorageData['eventSpclActBakit'].special_activities === 'Yes' && localStorageData['eventTypeBakit'].event_type_name !== 'Wedding Reception' || Tstatus === 'Yes' || localStorageData['eventStateBakit'].is_consectiveDate === 'No') {
				return true;
			}
		}
		else {
			if (localStorageData && localStorageData['eventAddonBakit'].hired_status == '1' || localStorageData.current_type === 'vendor' ?  null : localStorageData['alchoalAteventBakit'].is_sold_profitable === 'Yes' || localStorageData['eventSpclActBakit'].special_activities === 'Yes' || Tstatus === 'Yes' || localStorageData['eventStateBakit'].is_consectiveDate === 'No') {
				return true;
			}
		}
		return false;
	}
	// to check additional insured
	function toCheckAdditionalInsured() {
		if (localStorageData && localStorageData['eventAddonBakit'] && localStorageData['eventAddonBakit'].additional_insured !== '0' && localStorageData['eventAddonBakit'].additional_insured !== '') {
			return true;
		}
		return false;
	}
	var Tstatus='';
	if (localStorage.getItem('thankx') || localStorageData && localStorageData['eventTypeBakit'].event_type === localStorageData['eventTypeBakit'].event_type_name || localStorageData && localStorageData['alchoalAteventBakit'] && localStorageData['alchoalAteventBakit'].is_charge_ticket === 'Yes') {
		Tstatus = 'Yes';
	}

	if (params !== 'ode/2' && !localStorageData && params !== 'ode/certificate' &&  params !== 'ode/confirmation')
		return (<Redirect to={"/onedayevent/1"} />)

	if (localStorageData && localStorageData.current_step < 9 && params == 'ode/certificate')
     	return (<Redirect to={"quote/ode/" + localStorageData.current_step} />)

	let component = <ODE2 questionNum={number}  page={page} pendingQuote={pendingQuoteId} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

	if (params == 'ode/thanks') {
		component = <ThankQuote questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
		//number = 9;
	}
	if (params == 'ode/certificate') {
		component = <ODECertificate questionNum={10}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	    //number = 12;
	}
	if (params == 'ode/confirmation') {
		component = <ODEConfirmation questionNum={10}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	    //number = 12;
	}
	 if (params === 'ode/2') {
		component = <ODE2 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	}
	else if (params === 'ode/3') {
		component = <ODE3 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	}
	else if (params === 'ode/4') {
		component = <ODE4 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	}
	else if (params === 'ode/5') {
		component = <ODE5 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	}
	else if (params === 'ode/6') {
		component = <ODE6 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	
	// }else if (number === 'eventAddonBakit') {
	// 	component = <ODE6A questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	} 
	else if (params === 'ode/7') {
		   component = <ODE7 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
	} 
	else if (params === 'ode/8') {
		 // check event type
		 if (checkEventType()) {
				// Usman: I have update the above condition and add one more and in it. (&& localStorageData['eventTypeBakit'].event_type !== '67')
			 component = <ODEventDescription questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			}else{
	
			 if (toManualQuote(true)) {
					component = <ODE9 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				} else {
					component = <ODE8 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				}
			}
			
	} 
	else if (params === 'ode/9') {
		 if (checkEventType()) {
				
			 if (toManualQuote()) {
					// 9
					component = <ODE9 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 } else {
					component = <ODE8 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				}
		 } else {
			 if (toManualQuote(true)) {
				// additional insured
				component = <ODE10 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			} else {
				component = <ODE9 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			}
	
		  }
		} else if (params === 'ode/10') {
		 if (checkEventType()) {
			 if (toManualQuote()) {
					// additional
					component = <ODE10 questionNum={number}  page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 } else {
				 component = <ODE9 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
		 } else {
			 if (toManualQuote(true)) {
				 if (toCheckAdditionalInsured()) {
					 // 10 || 10A
					 component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 }
				 else {
					 component = <ThankQuote questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 }

			 } else {
				 component = <ODE10 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
		}
		} else if (params === 'ode/11') {
		 if (checkEventType()) {
			  // here when it is ode/11
			 if (toManualQuote()) {
				// Thankyou
				 if (toCheckAdditionalInsured()) {
					 component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 }
				 else {
					 component = <ThankQuote questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 }
			 } else {
				 component = <ODE10 questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
		 } else {
			 if (toManualQuote(true)) {
				 component = <ThankQuote questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 //  end Manual quote without Event Description
			 } else {
				 if (toCheckAdditionalInsured()) {
					 component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 }
				 else {
					component = <ODEPayment questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				}
			 }
		 }
		} else if (params === 'ode/12') {
		 if (checkEventType()) {
			 if (toManualQuote()) {
				 // end Manual quote with Event Description
				 component = <ThankQuote questionNum={number} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 } else {
				 if (toCheckAdditionalInsured()) {
					 component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 }
				 else {
					 component = <ODEPayment questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
				 }
			 }
		 } else {
			 // Only Recommended system from here
			 if (toCheckAdditionalInsured()) {
				 component = <ODEPayment questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 } else {
				 component = <Donate questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
		 }
	
		} else if (params === 'ode/13') {
		 if (checkEventType()) {
			 // Only Recommended System From here
			 if (toCheckAdditionalInsured()) {
				 component = <ODEPayment questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 } else {
				 component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
		 } else {
			 if (toCheckAdditionalInsured()) {
				 component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
			 else {
				 component = <Donate questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
		
		   }
	
		} else if (params === 'ode/14') {
		 if (checkEventType()) {
			 if (toCheckAdditionalInsured()) {
				 component = <ODE10A questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
			 else {
				 component = <Donate questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
			 }
		 }
		 else {
			 component = <Donate questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;
		 }

	 } else if (params === 'ode/15') {
		 component = <Donate questionNum={number} page={page} functionPrev={{ handleUpdatePrevious: handleUpdatePrevious }} functionLoader={{ handleUpdateLoader: handleUpdateLoader }} />;

	 }
	   
		if (localStorageData && localStorageData.current_step && parseInt(number) > parseInt(localStorageData.current_step)) {
			return (<Redirect to={"/quote/ode/" + localStorageData.current_step} />)
		}
		if(localStorageData && localStorageData.current_step > 'ode/9'){
			if (localStorageData && localStorageData.current_step && number < 10) {
				return (<Redirect to={"/quote/ode/10"} />)
			}
		}
		
	return (
		<Fragment>
            <Loader isLoading={loader}></Loader>
				{component}
		</Fragment>
	);
}
export default QuestionLayout;